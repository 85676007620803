import AdminRoute from '../adminRoute';
import { Mapping } from '../lazyLoaded';
import { PATH_NAME } from '../pathNames';
import { ALL_PERMISSIONS } from '../../utils/constants';

const MappingRoute = () => (
  <AdminRoute
    exact
    path={PATH_NAME.MAPPING}
    permission={ALL_PERMISSIONS.CAN_ACCESS_MANAGE_CRITERION_PAGE}
    component={(props: JSX.IntrinsicAttributes) => <Mapping {...props} />}
  />
);
export default MappingRoute;
