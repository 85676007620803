import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { customThemeModel } from '../../models';

export const styles = makeStyles((theme: Theme & customThemeModel) =>
  createStyles({
    heading: {
      display: 'flex',
      justifyContent: 'space-between',
      paddingBottom: 7,
      marginBottom: 24,
      color: theme.colors.text,
      borderBottom: `1px solid ${theme.colors.lines}`,
      fontSize: 18,
      fontWeight: 600,
      fontStyle: 'normal',
      fontFamily: 'Open Sans',
      lineHeight: '24px'
    },
    subHeading: {
      alignItems: 'flex-end',
      fontSize: 16,
      lineHeight: '22px'
    },
    thickBorder: {
      borderBottom: `2px solid ${theme.colors.black}`
    },
    addButton: {
      color: theme.colors.primary,
      fontSize: 15,
      fontWeight: 'bold',
      fontStyle: 'normal',
      fontFamily: 'Open Sans',
      lineHeight: '20px',
      cursor: 'pointer'
    },
    text: {
      color: theme.colors.black,
      fontSize: 12,
      fontWeight: 'normal',
      lineHeight: '16px',
      cursor: 'default'
    }
  })
);
