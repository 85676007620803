import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { textAlign } from 'html2canvas/dist/types/css/property-descriptors/text-align';
import { customThemeModel } from '../../models';

export const styles = makeStyles((theme: Theme & customThemeModel) =>
  createStyles({
    formControlContainer: {
      position: 'relative'
    },
    inputLabel: {
      marginBottom: 6,
      color: theme.colors.text,
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: 15,
      lineHeight: '16px'
    },
    distantLabel: {
      marginBottom: 16
    },
    customInput: {
      minWidth: theme.lengths.minInputWidth,
      maxWidth: theme.lengths.maxInputWidth,
      height: theme.lengths.elementsHeight,
      color: theme.colors.text,
      backgroundColor: theme.colors.white,
      borderRadius: 8,
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: 14,
      lineHeight: '16px',
      '& .MuiOutlinedInput-notchedOutline': {
        border: '1px solid rgba(0, 83, 154, 0.5)'
      },
      '& .MuiOutlinedInput-input': {
        padding: '8px'
      }
    },
    fullWidth: {
      width: '100%'
    },
    errorText: {
      position: 'absolute',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      minWidth: 135,
      color: theme.colors.error,
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '16px'
    },
    warningLogo: {
      marginTop: 2,
      marginRight: '4.5px'
    },
    formControlError: {
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: `${theme.colors.error} !important`
        },
        '&:hover fieldset': {
          borderColor: `${theme.colors.error} !important`
        },
        '&.Mui-focused fieldset': {
          borderColor: `${theme.colors.error} !important`
        }
      }
    },
    multilineInput: {
      width: 562,
      maxWidth: 562,
      height: 72,
      '& .MuiOutlinedInput-input': {
        padding: 0
      }
    },
    greyBorder: {
      '& .MuiOutlinedInput-notchedOutline': {
        border: `1px solid ${theme.colors.greyBorder}`
      }
    },
    shortInput: {
      minWidth: '123px !important',
      width: '123px !important'
    },
    longInput: {
      maxWidth: '567px !important',
      width: '567px !important'
    },
    disabled: {
      backgroundColor: theme.colors.background
    }
  })
);
