import { gql } from '@apollo/client';

export const GET_ACTIVITY_SUMMARY_REPORT = gql`
  query getActivitySummaryReport($data: StandardReportInput!) {
    getActivitySummaryReport(data: $data) {
      totalPatients
      contactedPcp
      unresolved
      resolved
      withAlerts
      hasDrugs
      hasPharmacistReviewed
    }
  }
`;
