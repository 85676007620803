import { forwardRef } from 'react';

import classNames from 'classnames';
import { Tooltip, MenuItem } from '@material-ui/core';

import { styles } from './styles';

interface IDropdownItem {
  children: any;
  handleClick?: any;
  childItem?: boolean;
  tooltipTitle?: String;
  value?: string | number | readonly string[] | undefined;
  disabled?: false | boolean;
}

const DropdownItem = forwardRef(
  (
    {
      children,
      childItem,
      handleClick,
      tooltipTitle,
      disabled,
      ...rest
    }: IDropdownItem,
    ref
  ) => {
    const classes = styles();
    return (
      <Tooltip
        title={tooltipTitle && tooltipTitle?.length > 22 ? tooltipTitle : ''}
      >
        <MenuItem
          className={classNames(classes.dropdownItem, {
            [classes.childItem]: childItem
          })}
          onClick={handleClick}
          disabled={disabled}
          {...rest}
        >
          {children}
        </MenuItem>
      </Tooltip>
    );
  }
);

export default DropdownItem;
