import { gql } from '@apollo/client';

export const GET_RACE_TABLE = gql`
  query getRaceTable($data: RaceTableInput!) {
    getRaceTable(data: $data) {
      data {
        patientId
        patientName
        age
        siteName
        primaryStaffName
        race
      }
      count
    }
  }
`;
