import { Route as PublicRoute } from 'react-router-dom';

import { PATH_NAME } from '../pathNames';
import { IncidentsSymptoms } from '../lazyLoaded';

const IncidentsSymptomsRoute = () => (
  <PublicRoute
    exact
    path={PATH_NAME.INCIDENTS_SYMPTOMS}
    component={(props: JSX.IntrinsicAttributes) => (
      <IncidentsSymptoms {...props} />
    )}
  />
);
export default IncidentsSymptomsRoute;
