import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { customThemeModel } from '../../models';

export const styles = makeStyles((theme: Theme & customThemeModel) =>
  createStyles({
    container: {
      display: 'flex',
      justifyContent: 'center',
      alignContent: 'center',
      width: 40,
      height: 40,
      borderRadius: '50%',
      backgroundColor: theme.colors.lines,
      '& > :first-child': {
        marginTop: 12,
        color: theme.colors.text,
        borderRadius: '50%',
        fontSize: 14,
        fontWeight: 600,
        fontStyle: 'normal',
        fontFamily: 'Open Sans',
        lineHeight: '16px'
      }
    },
    photoContainer: {
      backgroundColor: 'inherit',
      '& > :first-child': {
        marginTop: 0
      }
    },
    smallContainer: {
      width: 28,
      height: 28,
      '& > :first-child': {
        marginTop: 6
      }
    },
    smallPhotoContainer: {
      '& > :first-child': {
        marginTop: 0
      }
    },
    photo: {
      width: 40,
      height: 40,
      borderRadius: '50%'
    },
    smallPhoto: {
      width: 28,
      height: 28
    }
  })
);
