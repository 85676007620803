import classNames from 'classnames';
import { Typography } from '@material-ui/core';

import { styles } from './styles';

interface ITab {
  name: String;
  onClick: any;
  active?: boolean;
  sidebar?: boolean;
}

const Stat = ({ name, active, sidebar, onClick }: ITab) => {
  const classes = styles();

  return (
    <Typography
      onClick={onClick}
      className={classNames(
        classes.tab,
        { [classes.active]: active },
        { [classes.sidebar]: sidebar }
      )}
    >
      {name}
    </Typography>
  );
};

export default Stat;
