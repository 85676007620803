import AdminRoute from '../adminRoute';
import { PATH_NAME } from '../pathNames';
import { Reporting } from '../lazyLoaded';
import { ALL_PERMISSIONS } from '../../utils/constants';

const ReportingRoute = () => (
  <AdminRoute
    path={PATH_NAME.REPORTING}
    permission={ALL_PERMISSIONS.CAN_ACCESS_REPORTING_PAGE}
    component={(props: JSX.IntrinsicAttributes) => <Reporting {...props} />}
  />
);
export default ReportingRoute;
