import { gql } from '@apollo/client';

export const GET_MILITARY_STATUS_TABLE = gql`
  query getMilitaryStatusTable($data: MilitaryStatusTableInput!) {
    getMilitaryStatusTable(data: $data) {
      data {
        patientId
        patientName
        age
        siteName
        primaryStaffName
        militaryStatus
      }
      count
    }
  }
`;
