import { makeStyles, createStyles, Theme } from '@material-ui/core';
import { customThemeModel } from '../../../models';

export const styles = makeStyles((theme: Theme & customThemeModel) =>
  createStyles({
    footer: {
      position: 'absolute',
      left: 0,
      right: 0,
      bottom: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      height: theme.lengths.footerHeight,
      backgroundColor: theme.colors.header
    },
    copyrightContainer: {
      display: 'flex'
    },
    brandName: {
      margin: '0 53px 0 25px',
      color: theme.colors.white,
      fontSize: 18,
      fontWeight: 600,
      fontStyle: 'normal',
      fontFamily: 'Open Sans',
      lineHeight: '16px',
      letterSpacing: '-2px',
      textTransform: 'uppercase'
    },
    copyright: {
      color: theme.colors.white,
      opacity: 0.5,
      fontSize: 14,
      fontStyle: 'normal',
      fontFamily: 'Open Sans',
      lineHeight: '16px',
      textTransform: 'capitalize',
      alignSelf: 'center',
      marginLeft: 50,
      paddingTop: 6
    },
    footerNav: {
      display: 'flex',
      marginRight: 140,
      '& > *': {
        marginLeft: 24
      },
      '& > :first-child': {
        marginLeft: 0
      }
    },
    menuItem: {
      width: 'inherit',
      padding: 0,
      color: theme.colors.white,
      opacity: 0.7,
      fontSize: 14,
      fontWeight: 600,
      fontStyle: 'normal',
      fontFamily: 'Open Sans',
      lineHeight: '16px',
      cursor: 'pointer'
    },
    menuLink: {
      color: 'inherit',
      textDecoration: 'none'
    },
    homemedsLogo: {
      marginBottom: -5,
      cursor: 'pointer'
    },
    footerlogo: {
      padding: '0 10px 0 14px'
    }
  })
);
