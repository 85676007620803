import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { customThemeModel } from '../../models';

export const styles = makeStyles((theme: Theme & customThemeModel) =>
  createStyles({
    datePicker: {
      '& .MuiOutlinedInput-input': {
        minWidth: theme.lengths.minInputWidth,
        maxWidth: theme.lengths.maxInputWidth,
        height: theme.lengths.elementsHeight,
        padding: '0 8px',
        fontFamily: 'Open Sans',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: 14,
        lineHeight: '16px'
      },
      '& .MuiOutlinedInput-adornedStart ': {
        backgroundColor: theme.colors.white
      },
      '& .MuiInputAdornment-root': {
        margin: 0
      },
      '& .MuiButtonBase-root': {
        padding: 0
      },

      '& .MuiOutlinedInput-notchedOutline': {
        border: '1px solid rgba(0, 83, 154, 0.5) !important',
        borderRadius: 8
      },
      '& .MuiFormHelperText-root': {
        display: 'none'
      }
    },
    greyBorder: {
      '& .MuiOutlinedInput-notchedOutline': {
        border: `1px solid ${theme.colors.greyBorder} !important`
      }
    },
    shortInput: {
      minWidth: '123px !important',
      width: '123px !important'
    },
    errorText: {
      position: 'absolute',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      color: theme.colors.error,
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '16px'
    },
    warningLogo: {
      marginRight: '4.5px'
    },
    errorBorder: {
      '& .MuiOutlinedInput-notchedOutline': {
        border: `1px solid ${theme.colors.error} !important`
      }
    },
    disabled: {
      '& .MuiOutlinedInput-adornedStart': {
        backgroundColor: `${theme.colors.background} !important`
      },
      '& .MuiOutlinedInput-notchedOutline': {
        border: `1px solid ${theme.colors.greyBorder} !important`
      }
    }
  })
);
