export const customTheme = {
  colors: {
    text: '#333',
    black: '#000',
    white: '#FFF',
    green: '#3CC13B',
    lines: '#C1C9D2',
    error: '#C01722',
    hover: '#EDF3F8',
    active: '#00A984',
    header: '#212324',
    warning: '#FBAD17',
    warningDark: '#e86e30',
    primary: '#00539A',
    greyText: '#727781',
    secondary: '#00ACDC',
    greyBorder: '#C2C9D1',
    inputFocus: '#88AECF',
    background: '#F8F8F8',
    inputBorder: '#D6DBDC',
    greyBackground: '#A5A7AA',
    assessmentTitle: '#242934',
    inActive: 'rgba(255, 255, 255, 0.8)'
  },
  lengths: {
    headerHeight: 56,
    footerHeight: 54,
    labelHeight: 16,
    paperWidth: 189,
    elementsHeight: 40,
    minInputWidth: 214,
    maxInputWidth: 333,
    mainContainerWidth: 1160,
    sidebarWidth: 259
  }
};
