import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { customThemeModel } from '../../models';

export const styles = makeStyles((theme: Theme & customThemeModel) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    paper: {
      width: '922px',
      background: theme.colors.white,
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
      borderRadius: '8px',
      padding: '27px 24px 25px 24px'
    },
    modalHead: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '0 0 26px 0'
    },
    modalHeadTitle: {
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 20,
      color: theme.colors.text
    },
    modalHeadClose: { cursor: 'pointer' },
    modalContent: {
      display: 'flex',
      flexDirection: 'column'
    },
    buttonGroup: {
      '& button:first-of-type': {
        marginRight: '8px',
        color: theme.colors.greyText,
        border: `1px solid ${theme.colors.greyBorder}`
      }
    },
    modalConfirmBody: {
      width: '490px !important'
    }
  })
);
