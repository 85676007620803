import { gql } from '@apollo/client';

export const GET_ETHNICITY_TABLE = gql`
  query getEthnicityTable($data: EthnicityTableInput!) {
    getEthnicityTable(data: $data) {
      data {
        patientId
        patientName
        age
        siteName
        primaryStaffName
        ethnicity
      }
      count
    }
  }
`;
