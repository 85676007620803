import { Route as PublicRoute } from 'react-router-dom';

import { PATH_NAME } from '../pathNames';
import { UnderConstruction } from '../lazyLoaded';

const UnderConstructionRoute = () => (
  <PublicRoute
    exact
    path={PATH_NAME.UNDER_CONSTRUCTION}
    component={(props: JSX.IntrinsicAttributes) => (
      <UnderConstruction {...props} />
    )}
  />
);
export default UnderConstructionRoute;
