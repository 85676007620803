import { GridSortModel } from '@material-ui/data-grid';

export const mapManageCriterionVariables = (
  searchValues,
  sortModel: GridSortModel
) => {
  let newSearchValues = {
    title: searchValues.title,
    disabled: searchValues.disabled === 'Yes'
  };

  if (sortModel.length > 0) {
    const sortState = {
      order: sortModel[0]?.sort?.toUpperCase(),
      orderBy: ''
    };

    switch (sortModel[0]?.field) {
      case 'title':
        sortState.orderBy = sortModel[0]?.field.toUpperCase();
        break;
      default:
        sortState.order = '';
        sortState.orderBy = '';
    }

    newSearchValues = { ...newSearchValues, ...sortState };
  }

  const mappedData = Object.fromEntries(
    Object.entries(newSearchValues).filter(([_, v]) => v != null && v !== '')
  );

  return mappedData;
};
