import { gql } from '@apollo/client';

export const GET_UPLOAD_PATIENT_REPORT_PRE_SIGNED_URL = gql`
  query getUploadPreSignedUrlForPatientAssessmentAttachment(
    $data: UploadAssessmentAttachmentInput!
  ) {
    getUploadPreSignedUrlForPatientAssessmentAttachment(data: $data) {
      url
      key
    }
  }
`;
