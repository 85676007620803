export const getDateIgnoreTimezone = (date, isMapping?) => {
  if (date && !isMapping)
    return (theDate =>
      new Date(theDate.getTime()).toDateString())(
      new Date(date)
    );
  if (date && date?.getUTCHours() !== 0 && isMapping)
    return new Date(new Date(date)?.toDateString());

  return date;
};
