import { Route as PublicRoute } from 'react-router-dom';

import { Faq } from '../lazyLoaded';
import { PATH_NAME } from '../pathNames';

const FaqRoute = () => (
  <PublicRoute
    exact
    path={PATH_NAME.Faq}
    component={(props: JSX.IntrinsicAttributes) => <Faq {...props} />}
  />
);
export default FaqRoute;
