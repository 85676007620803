import { gql } from '@apollo/client';

export const GET_INCIDENTS_SYMPTOMS_TABLE = gql`
  query getIncidentsSymptomsTable($data: IncidentsSymptomsTableInput!) {
    getIncidentsSymptomsTable(data: $data) {
      data {
        patientId
        patientName
        age
        siteName
        primaryStaffName
        reportedFall
        reportedDizziness
        reportedConfusion
        drinksPerWeek
        drinksPerDay
        hospitalizedEr
      }
      count
    }
  }
`;
