import { gql } from '@apollo/client';

export const REASSIGN_STAFF_TO_ASSESSMENT = gql`
  mutation reassignAssessmentToStaff($data: assessmentReassignedToInput!) {
    reassignAssessmentToStaff(data: $data) {
      id
      patientId
    }
  }
`;
