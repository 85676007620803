import { gql } from '@apollo/client';

export const GET_ALL_SECURITY_QUESTIONS = gql`
  query GetAllSecurityQuestions {
    getAllSecurityQuestions {
      id
      question
    }
  }
`;
