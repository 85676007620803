import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { customThemeModel } from '../../../../models';

export const styles = makeStyles((theme: Theme & customThemeModel) =>
  createStyles({
    inputsContainer: {
      width: '75%',
      display: 'grid',
      gridGap: 32,
      gridTemplateColumns: 'repeat(3, 1fr)',
      '& > .MuiFormControl-root:nth-of-type(4), & > .MuiFormControl-root:nth-of-type(5), & > .MuiFormControl-root:last-of-type,':
        {
          marginTop: '10px'
        }
    },
    customInput: {
      minWidth: '260px !important'
    }
  })
);
