import classNames from 'classnames';
import { Fade, Modal, Backdrop } from '@material-ui/core';

import { styles } from './styles';
import closeIcon from '../../assets/icons/close.svg';
import CustomButton from '../customButton';

interface ICustomModal {
  modalBody?: any;
  modalTitle?: string;
  titleComponent?: any;
  isModalOpen: boolean;
  noCloseIcon?: boolean;
  closeButtons?: boolean;
  confirmStyles?: boolean;
  customBodyClass?: string;
  saveButtonProps?: object;
  primaryButtonName?: string;
  cancelButtonProps?: object;
  customBackDropClass?: string;
  handleModalClose: () => void;
}

const CustomModal = ({
  modalBody,
  modalTitle,
  noCloseIcon,
  isModalOpen,
  closeButtons,
  confirmStyles,
  titleComponent,
  saveButtonProps,
  customBodyClass,
  handleModalClose,
  primaryButtonName,
  cancelButtonProps,
  customBackDropClass
}: ICustomModal) => {
  const classes = styles();

  const closeComponent =
    titleComponent && !closeButtons
      ? titleComponent
      : closeButtons && (
          <div className={classes.buttonGroup}>
            <CustomButton {...cancelButtonProps}>Cancel</CustomButton>
            <CustomButton {...saveButtonProps}>
              {primaryButtonName || 'Save'}
            </CustomButton>
          </div>
        );

  const modalContent = (
    <>
      <div
        className={classNames(classes.paper, customBodyClass, {
          [classes.modalConfirmBody]: confirmStyles
        })}
      >
        <div className={classes.modalHead}>
          <h2 className={classes.modalHeadTitle}>{modalTitle}</h2>
          {noCloseIcon ? (
            closeComponent
          ) : (
            <img
              src={closeIcon}
              alt="close"
              onClick={handleModalClose}
              className={classes.modalHeadClose}
            />
          )}
        </div>
        <div className={classes.modalContent}>{modalBody}</div>
      </div>
    </>
  );

  return (
    <Modal
      open={isModalOpen}
      closeAfterTransition
      className={classes.modal}
      onClose={handleModalClose}
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500, classes: { root: customBackDropClass } }}
    >
      <Fade in={isModalOpen}>{modalContent}</Fade>
    </Modal>
  );
};

export default CustomModal;
