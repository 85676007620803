import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { customThemeModel } from '../../models';

export const styles = makeStyles((theme: Theme & customThemeModel) =>
  createStyles({
    root: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
      paddingBottom: theme.lengths.footerHeight,
      backgroundColor: theme.colors.background
    },
    container: {
      flexGrow: 1,
      width: '100%',
      minHeight: `calc(100vh - ${theme.lengths.headerHeight}px - ${theme.lengths.footerHeight}px)`,
      margin: `${theme.lengths.headerHeight}px auto 0 auto`
    }
  })
);
