import { revertDateIgnoreTimezone, getDateIgnoreTimezone } from '../../helpers';

const parseHistoryBoolean = (value: any) => {
  if (!!value !== value) return '';
  return value ? 'yes' : 'no';
};

export const urlRegex =
  /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/;

export const mapAssessmentToForm = (unmappedAssessment, drugs) => {
  const mappedAssessment = {
    fall: parseHistoryBoolean(unmappedAssessment?.fallInPast3Months),
    dizzy: parseHistoryBoolean(unmappedAssessment?.dizzy),
    confused: parseHistoryBoolean(unmappedAssessment?.confusion),
    hospital: parseHistoryBoolean(unmappedAssessment?.visitedHospital),
    fallTimes: unmappedAssessment?.numberOfFalls?.toString() || '',
    fallWay: unmappedAssessment?.fallDescription || '',
    fallInjuries: unmappedAssessment?.fallInjuries || '',
    dizzinessDetails: unmappedAssessment?.dizzinessDetails || '',
    nursingWay: unmappedAssessment?.visitedPlaceType || '',
    nursingDuration: unmappedAssessment?.visitDate || '',
    pain: unmappedAssessment?.amountOfPainExperienced || '',
    drinkDays: unmappedAssessment?.daysOfAlcoholUsagePerWeek?.toString() || '0',
    drinksPerDay: unmappedAssessment?.numberOfDrinksPerDay?.toString() || '',
    bpLyingDownMin: unmappedAssessment?.bpLyingNum?.toString() || '',
    bpLyingDownMax: unmappedAssessment?.bpLyingDenom?.toString() || '',
    bpLyingDownPulse: unmappedAssessment?.lyingPulse?.toString() || '',
    bpLyingDownDateTime: unmappedAssessment?.lyingPressureCapturedAt
      ? revertDateIgnoreTimezone(
          new Date(unmappedAssessment?.lyingPressureCapturedAt)
        )
      : null,
    bpSittingMin: unmappedAssessment?.bpSittingNum?.toString() || '',
    bpSittingMax: unmappedAssessment?.bpSittingDenom?.toString() || '',
    bpSittingPulse: unmappedAssessment?.sittingPulse?.toString() || '',
    bpSittingDateTime: unmappedAssessment?.sittingPressureCapturedAt
      ? revertDateIgnoreTimezone(
          new Date(unmappedAssessment?.sittingPressureCapturedAt)
        )
      : null,
    bpStandingMin: unmappedAssessment?.bpStandingNum?.toString() || '',
    bpStandingMax: unmappedAssessment?.bpStandingDenom?.toString() || '',
    bpStandingPulse: unmappedAssessment?.standingPulse?.toString() || '',
    bpStandingDateTime: unmappedAssessment?.standingPressureCapturedAt
      ? revertDateIgnoreTimezone(
          new Date(unmappedAssessment?.standingPressureCapturedAt)
        )
      : null,
    drugs: drugs?.map(({ __typename, ...rest }) => rest) || [],
    conditions: [],
    allergies: [],
    otherConditions: '',
    otherAllergies: '',
    otherMedications: '',
    attachment: unmappedAssessment?.attachment || []
  };

  if (unmappedAssessment?.conditions)
    mappedAssessment.conditions = unmappedAssessment?.conditions;

  if (unmappedAssessment?.conditionNote)
    mappedAssessment.otherConditions = unmappedAssessment?.conditionNote;

  if (unmappedAssessment?.allergies)
    mappedAssessment.allergies = unmappedAssessment?.allergies;

  if (unmappedAssessment?.allergyNote)
    mappedAssessment.otherAllergies = unmappedAssessment?.allergyNote;
  if (unmappedAssessment?.drugsNote)
    mappedAssessment.otherMedications = unmappedAssessment?.drugsNote;

  return mappedAssessment;
};

export const mapFormToAssessment = form => {
  const formData: any = {
    fallInPast3Months: form?.fall === 'yes',
    numberOfFalls: parseFloat(form?.fallTimes),
    fallDescription: form?.fallWay,
    fallInjuries: form?.fallInjuries,
    dizzy: form?.dizzy === 'yes',
    dizzinessDetails: form?.dizzinessDetails,
    confusion: form?.confused === 'yes',
    visitedHospital: form?.hospital === 'yes',
    visitedPlaceType: form?.nursingWay,
    visitDate: form?.nursingDuration,
    amountOfPainExperienced: form?.pain,
    daysOfAlcoholUsagePerWeek: parseFloat(form?.drinkDays),
    numberOfDrinksPerDay: parseFloat(form?.drinksPerDay),
    bpLyingNum: form?.bpLyingDownMin ? parseFloat(form?.bpLyingDownMin) : null,
    bpLyingDenom: form?.bpLyingDownMax
      ? parseFloat(form?.bpLyingDownMax)
      : null,
    lyingPulse: form?.bpLyingDownPulse
      ? parseFloat(form?.bpLyingDownPulse)
      : null,
    lyingPressureCapturedAt: form?.bpLyingDownDateTime
      ? getDateIgnoreTimezone(form?.bpLyingDownDateTime)
      : null,
    bpSittingNum: form?.bpSittingMin ? parseFloat(form?.bpSittingMin) : null,
    bpSittingDenom: form?.bpSittingMax ? parseFloat(form?.bpSittingMax) : null,
    sittingPulse: form?.bpSittingPulse
      ? parseFloat(form?.bpSittingPulse)
      : null,
    sittingPressureCapturedAt: form?.bpSittingDateTime
      ? getDateIgnoreTimezone(form?.bpSittingDateTime)
      : null,
    bpStandingNum: form?.bpStandingMin ? parseFloat(form?.bpStandingMin) : null,
    bpStandingDenom: form?.bpStandingMax
      ? parseFloat(form?.bpStandingMax)
      : null,
    standingPulse: form?.bpStandingPulse
      ? parseFloat(form?.bpStandingPulse)
      : null,
    standingPressureCapturedAt: form?.bpStandingDateTime
      ? getDateIgnoreTimezone(form?.bpStandingDateTime)
      : null,
    drugs: form?.drugs || []
  };

  let mappedFormData = { ...formData };

  let mappedConditions: { type: string; condition: string }[] = [];
  const conditionNote = form.otherConditions || '';

  if (form.conditions.length > 0) {
    mappedConditions = [
      ...form.conditions.map(condition => {
        if (condition.trim().length > 0) return condition || '';
      })
    ];
  }

  mappedFormData = { ...mappedFormData, conditions: mappedConditions };

  mappedFormData = { ...mappedFormData, conditionNote };
  mappedFormData = { ...mappedFormData, drugsNote: form.otherMedications };

  let mappedAllergies: { type: string; allergy: string }[] = [];
  const allergyNote = form.otherAllergies || '';

  if (form.allergies.length > 0) {
    mappedAllergies = [
      ...form.allergies.map(allergy => {
        if (allergy.trim().length > 0) return allergy || '';
      })
    ];
  }

  mappedFormData = { ...mappedFormData, allergies: mappedAllergies };

  mappedFormData = { ...mappedFormData, allergyNote };

  if (!urlRegex.test(form?.attachment)) {
    mappedFormData = {
      ...mappedFormData,
      attachment: form?.attachment || null
    };
  }

  return mappedFormData;
};

export const parseAttachmentName = attachment => {
  const itemSplitted = attachment.split('?')[0].split('/');
  const name = decodeURIComponent(itemSplitted[itemSplitted.length - 1] || '');
  return name;
};
