import { gql } from '@apollo/client';

export const GET_ALERTS_RESOLVED_TABLE = gql`
  query getAlertsResolvedTable($data: AlertsResolvedTableInput!) {
    getAlertsResolvedTable(data: $data) {
      data {
        patientId
        patientName
      }
      count
    }
  }
`;
