import { Route as PublicRoute } from 'react-router-dom';

import { PATH_NAME } from '../pathNames';
import { UserQuery } from '../lazyLoaded';

const UserQueryRoute = () => (
  <PublicRoute
    exact
    path={PATH_NAME.USER_QUERY}
    component={(props: JSX.IntrinsicAttributes) => <UserQuery {...props} />}
  />
);
export default UserQueryRoute;
