import { gql } from '@apollo/client';

export const GET_GENDER_DISTRIBUTION_TABLE = gql`
  query getGenderDistributionTable($data: GenderDistributionTableInput!) {
    getGenderDistributionTable(data: $data) {
      data {
        patientId
        patientName
        age
        siteName
        primaryStaffName
      }
      count
    }
  }
`;
