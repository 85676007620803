import { makeStyles, createStyles, Theme } from '@material-ui/core';
import { customThemeModel } from '../../../models';

export const styles = makeStyles((theme: Theme & customThemeModel) =>
  createStyles({
    appBar: {
      marginTop: 50,
      color: theme.colors.white,
      backgroundColor: `${theme.colors.primary} !important`,
      height: theme.spacing(5),
      zIndex: 100
    },
    toolbar: {
      minHeight: theme.spacing(5),
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    closeButton: {
      color: 'white'
    },
    announcementText: {
      fontWeight: 'bold',
      zindex: 100,
      marginright: 15,
      color: 'white'
    },
    middleSection: {
      display: 'flex',
      alignItems: 'center',
      color: 'white'
    },
    linkText: {
      color: 'white',
      textDecoration: 'underline',
      fontWeight: 'bold',
      fontSize: 15,
      marginRight: '20px'
    }
  })
);
