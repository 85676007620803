import { gql } from '@apollo/client';

export const CREATE_BULLETIN = gql`
  mutation createBulletin($data: BulletinInput!) {
    createBulletin(data: $data) {
      id
      name
      status
      description
      createdAt
      updatedAt
    }
  }
`;
