import { Route as PublicRoute } from 'react-router-dom';

import { PATH_NAME } from '../pathNames';
import { SecurityQuestions } from '../lazyLoaded';

const SecurityQuestionsRoute = () => (
  <PublicRoute
    exact
    path={PATH_NAME.SECURITY_QUESTIONS}
    component={(props: JSX.IntrinsicAttributes) => (
      <SecurityQuestions {...props} />
    )}
  />
);
export default SecurityQuestionsRoute;
