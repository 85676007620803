import { gql } from '@apollo/client';

export const UPDATE_RECOMMENDATION = gql`
  mutation updateRecommendation($data: UpdateRecommendationInput!) {
    updateRecommendation(data: $data) {
      id
      title
      description
      details
      disabled
    }
  }
`;
