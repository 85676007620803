import { Auth } from 'aws-amplify';
import { Link, useHistory } from 'react-router-dom';
import { useRef, useState } from 'react';
import {
  Paper,
  Popper,
  Button,
  MenuList,
  MenuItem,
  ClickAwayListener
} from '@material-ui/core';

import { styles } from './styles';
import { HEADER_PROFILE_LIST } from '../../../../utils/constants';
import CustomModal from '../../../../sharedComponents/customModal';
import ProfilePhoto from '../../../../sharedComponents/profilePhoto';
import ModalConfirmBody from '../../../../sharedComponents/modalConfirmBody';
import {
  localStates,
  useLocalStates
} from '../../../../graphQL/states/localStates';
import { PATH_NAME } from '../../../../routes/pathNames';

const Profile = () => {
  const classes = styles();
  const history = useHistory();
  const [openProfile, setOpenProfile] = useState(false);
  const [open, setOpen] = useState<boolean>(false);
  const anchorRefProfile = useRef<HTMLButtonElement>(null);
  const { editIsLoggedIn } = useLocalStates(localStates);

  const handleLogout = async () => {
    await Auth.signOut()
      .then(() => {
        editIsLoggedIn(false);
        localStorage.clear();
        history.replace(PATH_NAME.LOGIN);
        setTimeout(() => sessionStorage.clear(), 500);
        // window.location.reload();
      })
      .catch(() => {
        //
      });
  };

  let adminMenuTimeout;

  const handleModalOpen = () => {
    setOpen(true);
  };

  const handleModalClose = () => {
    setOpen(false);
  };

  const handleClickProfileButton = () => {
    setOpenProfile(!openProfile);
  };

  const handleClickProfileItem = (type?: string) => {
    setOpenProfile(false);
    if (type === 'logout') handleModalOpen();
  };

  const handleClickAwayProfile = () => {
    adminMenuTimeout = setTimeout(() => {
      setOpenProfile(false);
    }, 100);
  };

  const handleOpenProfileMenu = () => {
    setOpenProfile(true);
    clearTimeout(adminMenuTimeout);
  };

  return (
    <>
      <div
        className={classes.alignment}
        onMouseEnter={handleOpenProfileMenu}
        onMouseLeave={handleClickAwayProfile}
      >
        <Button
          ref={anchorRefProfile}
          onClick={handleOpenProfileMenu}
          onMouseEnter={handleOpenProfileMenu}
          classes={{ root: classes.ProfileButton }}
        >
          <ProfilePhoto small />
        </Button>
        <Popper
          open={openProfile}
          anchorEl={anchorRefProfile.current}
          className={classes.profilePopper}
        >
          <Paper
            classes={{ root: classes.paper }}
            onFocus={handleOpenProfileMenu}
          >
            <ClickAwayListener onClickAway={handleClickAwayProfile}>
              <MenuList
                autoFocusItem={openProfile}
                classes={{ root: classes.menuList }}
              >
                {HEADER_PROFILE_LIST.map(({ id, url, name }) =>
                  url ? (
                    <Link
                      key={id}
                      className={classes.menuLink}
                      to={{ pathname: url, state: { page: id } }}
                    >
                      <MenuItem
                        classes={{ root: classes.menuItem }}
                        onClick={handleClickProfileItem as never}
                      >
                        {name}
                      </MenuItem>
                    </Link>
                  ) : (
                    <MenuItem
                      key={id}
                      onClick={() => handleClickProfileItem('logout')}
                      classes={{ root: classes.menuItem }}
                    >
                      {name}
                    </MenuItem>
                  )
                )}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Popper>
      </div>
      <CustomModal
        noCloseIcon
        confirmStyles
        isModalOpen={open}
        handleModalClose={handleModalClose}
        modalTitle="Log Out"
        modalBody={
          <ModalConfirmBody
            prompt="Are you sure you want to log out?"
            handleConfirm={handleLogout}
            handleCancel={handleModalClose}
          />
        }
      />
    </>
  );
};

export default Profile;
