import { gql } from '@apollo/client';

export const GET_ALERTS_RESOLVED_REPORT = gql`
  query getAlertsResolvedReport($data: StandardReportInput!) {
    getAlertsResolvedReport(data: $data) {
      totalPatients
      criterionAlerts {
        id
        title
        alias
        count
      }
      medispanAlerts {
        type
        drugs {
          name
        }
        count
      }
    }
  }
`;
