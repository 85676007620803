import { useQuery } from '@apollo/client';
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { styles } from './styles';
import { ROLES } from '../../../../utils/constants';
import { stopRefresh } from '../../../patient/patientForm';
import Dropdown from '../../../../sharedComponents/dropdown';
import CustomModal from '../../../../sharedComponents/customModal';
import DropdownItem from '../../../../sharedComponents/dropdownItem';
import {
  roleModel,
  siteModel,
  sitesModel
} from '../../../../models/rolesPermissions';
import { CheckmarkIcon, ArrowDownSiteIcon } from '../../../../assets/icons';
import ModalConfirmBody from '../../../../sharedComponents/modalConfirmBody';
import { GET_ALL_SITES, GET_LOCAL_STATES } from '../../../../graphQL/queries';
import { localStates, useLocalStates } from '../../../../graphQL/states';
import { PATH_NAME } from '../../../../routes/pathNames';

interface ISitesDropdown {
  fetchSiteId: (siteId: any) => void;
}
const SitesDropdown = ({ fetchSiteId }: ISitesDropdown) => {
  const classes = styles();
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [tempSiteId, setTempSiteId] = useState<any>(null);
  const { data } = useQuery(GET_ALL_SITES, {
    fetchPolicy: 'network-only'
  });
  const [sitesCount, setSitesCount] = useState(0);
  const [selectedSiteId, setSelectedSiteId] = useState('');
  const [sites, setSites] = useState<sitesModel>(
    JSON.parse(localStorage.getItem('sites') as string) as sitesModel
  );
  const { editSites, editUserRole } = useLocalStates(localStates);

  const handleModalOpen = () => setOpen(true);
  const handleModalClose = () => setOpen(false);

  const {
    data: {
      localStates: { userRole }
    }
  } = useQuery(GET_LOCAL_STATES);

  useEffect(() => {
    if (data) {
      updateSites(data);
      setSites(data?.getAllSites);
      if (
        !localStorage.getItem('defaultSiteId') &&
        data?.getAllSites[0].role === ROLES.GLOBAL_ADMIN
      )
        setSelectedSiteId('all_sites');
      else
        setSelectedSiteId(
          localStorage.getItem('defaultSiteId') ||
            (userRole === ROLES.GLOBAL_ADMIN && 'all_sites') ||
            data?.getAllSites[0]?.id ||
            ''
        );

      let totalCount = 0;
      data?.getAllSites?.map(site => {
        totalCount += 1 + (+site?.children?.length || 0);
      });

      setSitesCount(totalCount);
    }
  }, [data]);

  const updateSites = data => {
    const sites = data?.getAllSites;
    if (sites) {
      let siteIdCheck = false;
      let role = localStorage.getItem('role') as roleModel;
      const defaultSiteId = localStorage.getItem('defaultSiteId');

      if (defaultSiteId) {
        if (defaultSiteId === 'all_sites' && userRole === ROLES.GLOBAL_ADMIN) {
          role = ROLES.GLOBAL_ADMIN;
          siteIdCheck = true;
        } else {
          sites.map(({ id, children, role: parentRole }) => {
            if (id === defaultSiteId) {
              role = parentRole;
              siteIdCheck = true;
            }
            if (children) {
              children.map(({ id: childId, role: childRole }) => {
                if (childId === defaultSiteId) {
                  role = childRole;
                  siteIdCheck = true;
                }
              });
            }
          });
        }
      } else {
        role = sites[0]?.role;
        let siteId = sites[0]?.id;
        if (role === ROLES.GLOBAL_ADMIN) siteId = 'all_sites';
        localStorage.setItem('defaultSiteId', siteId);
      }

      if (defaultSiteId && !siteIdCheck) {
        localStorage.removeItem('role');
        localStorage.removeItem('defaultSiteId');
        window.location.reload();
      }

      localStorage.setItem('sites', JSON.stringify(sites));
      editSites(sites);

      localStorage.setItem('role', role);
      const localRole = localStorage.getItem('role') as roleModel;
      const roleCheck = Object.values(ROLES).includes(localRole);

      editUserRole((roleCheck && localRole) || ROLES.GUEST);
    }
  };

  const handleSite = (event: React.ChangeEvent<{ value: unknown }>) => {
    if (!stopRefresh()) {
      setSelectedSiteId(event.target.value as string);
      localStorage.setItem('defaultSiteId', event.target.value as string);

      data?.getAllSites?.map(({ id, children, role }) => {
        if (id === (event.target.value as string)) {
          localStorage.setItem('role', role);
        } else if (children) {
          children.map(({ id: childId, role: childRole }) => {
            if (childId === (event.target.value as string)) {
              localStorage.setItem('role', childRole);
            }
          });
        }
      });
      window.dispatchEvent(new Event('storage'));
      window.location.replace(PATH_NAME.DASHBOARD);
    } else {
      handleModalOpen();
      setTempSiteId(event.target.value as string);
    }
  };

  useEffect(() => {
    if (selectedSiteId) fetchSiteId(selectedSiteId);
  }, [selectedSiteId]);

  const handleUnblock = () => {
    stopRefresh(false);
    handleModalClose();
    setSelectedSiteId(tempSiteId);
    localStorage.setItem('defaultSiteId', tempSiteId);

    data?.getAllSites?.map(({ id, children, role }) => {
      if (id === tempSiteId) {
        localStorage.setItem('role', role);
      } else if (children) {
        children.map(({ id: childId, role: childRole }) => {
          if (childId === tempSiteId) {
            localStorage.setItem('role', childRole);
          }
        });
      }
    });

    history.replace(PATH_NAME.DASHBOARD);
  };

  return (
    <>
      <Dropdown
        styleNoBorder
        handleItems={handleSite}
        Icon={ArrowDownSiteIcon}
        selectedItem={selectedSiteId}
        multiple={
          sites &&
          (sitesCount > 1 ||
            (sitesCount === 1 && userRole === ROLES.GLOBAL_ADMIN))
        }
        customMenuClass={classes.customMenuSite}
        customSelectClass={classes.customDropdownSite}
      >
        {userRole === ROLES.GLOBAL_ADMIN && (
          <DropdownItem value="all_sites">
            All Sites
            {selectedSiteId === 'all_sites' && (
              <CheckmarkIcon className={classes.checkmarkIcon} />
            )}
          </DropdownItem>
        )}
        {sites &&
          sites.map(({ id, title, children }: siteModel) => {
            if (children) {
              const parentChildrenItems: JSX.Element[] = [];
              parentChildrenItems.push(
                <DropdownItem key={id} value={id} tooltipTitle={title}>
                  {title}
                  {id === selectedSiteId && (
                    <CheckmarkIcon className={classes.checkmarkIcon} />
                  )}
                </DropdownItem>
              );
              children.map(({ id: childId, title: childTitle }: siteModel) =>
                parentChildrenItems.push(
                  <DropdownItem
                    childItem
                    key={childId}
                    value={childId}
                    tooltipTitle={childTitle}
                  >
                    {childTitle}
                    {childId === selectedSiteId && (
                      <CheckmarkIcon className={classes.checkmarkIcon} />
                    )}
                  </DropdownItem>
                )
              );
              return parentChildrenItems;
            }
            return (
              <DropdownItem key={id} value={id} tooltipTitle={title}>
                {title}
                {id === selectedSiteId && (
                  <CheckmarkIcon className={classes.checkmarkIcon} />
                )}
              </DropdownItem>
            );
          })}
      </Dropdown>
      <CustomModal
        noCloseIcon
        confirmStyles
        isModalOpen={open}
        modalTitle="Unsaved changes"
        handleModalClose={handleModalClose}
        modalBody={
          <ModalConfirmBody
            prompt="Your changes have not been saved. Are you sure you want to leave
              the page?"
            confirmText="No"
            handleConfirm={handleModalClose}
            cancelText="Yes"
            handleCancel={handleUnblock}
          />
        }
      />
    </>
  );
};

export default SitesDropdown;
