import { Route as PublicRoute } from 'react-router-dom';

import { Login } from '../lazyLoaded';
import { PATH_NAME } from '../pathNames';

const LoginRoute = () => (
  <PublicRoute
    exact
    path={PATH_NAME.LOGIN}
    component={(props: JSX.IntrinsicAttributes) => <Login {...props} />}
  />
);
export default LoginRoute;
