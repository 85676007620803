import { gql } from '@apollo/client';

export const GET_AGE_DISTRIBUTION_REPORT = gql`
  query getAgeDistributionReport($data: StandardReportInput!) {
    getAgeDistributionReport(data: $data) {
      totalPatients
      averageAge
      ageFrom0To29
      ageFrom30To39
      ageFrom40To49
      ageFrom50To59
      ageFrom60To69
      ageFrom70To79
      ageFrom80To89
      ageGreaterThan90
    }
  }
`;
