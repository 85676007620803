export const preparePharmaciesSearchArguments = (filters?: any) => {
  let newSearchValues: any = {
    taxonomy_description: 'Pharmacy',
    limit: 20,
    version: 2.1,
    pretty: true
  };

  if (filters) {
    newSearchValues = {
      ...newSearchValues,
      number: filters.npi,
      state: filters.state,
      organization_name:
        filters.name.length > 1 ? `${filters.name}*` : filters.name,
      city: filters.city.length > 1 ? `${filters.city}*` : filters.city,
      postal_code: filters.zip.length > 1 ? `${filters.zip}*` : filters.zip
    };
  }

  const mappedData = Object.fromEntries(
    Object.entries(newSearchValues).filter(([_, v]) => v != null && v !== '')
  );

  return mappedData;
};

export const mapPharmacyToSubmitHandler = row => ({
  npi: row?.npi?.toString(),
  name: row?.name,
  phone: row?.phone,
  fax: row?.fax,
  address: row?.address,
  city: row?.city,
  state: row?.state,
  zip: row?.zip
});
