import { gql } from '@apollo/client';

export const SEARCH_RECOMMENDATIONS = gql`
  query SearchRecommendations($data: SearchRecommendationsInput!) {
    SearchRecommendations(data: $data) {
      data {
        id
        title
        description
        details
        disabled
      }
      count
      criterion {
        title
      }
    }
  }
`;
