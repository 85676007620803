import { Route as PublicRoute } from 'react-router-dom';

import { PATH_NAME } from '../pathNames';
import { AccountSettings } from '../lazyLoaded';

const AccountSettingsRoute = () => (
  <PublicRoute
    path={PATH_NAME.ACCOUNT_SETTINGS}
    component={(props: JSX.IntrinsicAttributes) => (
      <AccountSettings {...props} />
    )}
  />
);
export default AccountSettingsRoute;
