import { GridColDef, GridValueGetterParams } from '@material-ui/data-grid';
import { ROLES } from './roles-permissions';

export const ALERTS_COLUMNS: GridColDef[] = [
  {
    field: 'id',
    headerName: 'ID',
    minWidth: 120,
    sortable: false,
    hide: true
  },
  {
    field: 'createdAt',
    headerName: 'Date',
    minWidth: 85,
    sortable: false,
    valueGetter: (params: GridValueGetterParams) =>
      new Date(params?.value as string)?.toLocaleDateString() || ''
  },
  {
    field: 'updatedBy',
    headerName: 'Updated By',
    minWidth: 240,
    sortable: false,
    valueGetter: (params: GridValueGetterParams) =>
      (params?.value as string) === 'null null'
        ? 'System'
        : (params?.value as string)
  },
  {
    field: 'lastAction',
    headerName: 'Last Action',
    minWidth: 200,
    sortable: false
  },
  {
    field: 'notes',
    headerName: 'Notes',
    minWidth: 600,
    sortable: false,
    cellClassName: 'notes'
  }
];

export const ASSESSMENT_ALERTS = [
  {
    id: 1,
    title: 'Assessment Alert New',
    body: 'Increatin-based therapies',
    steps: [
      '1. Bydureon Kit 2 mg subcutaneous injection, extended release ',
      '2. Januvia 50 mg oral tablet'
    ],
    status: 'not resolved',
    lastAction: 'no',
    date: new Date().toLocaleDateString('en-US'),
    history: [
      {
        id: 1,
        date: new Date().toLocaleDateString('en-US'),
        updatedBy: 'Update from CM/Nurse',
        lastAction: 'Fax to MD',
        notes:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
      },
      {
        id: 2,
        date: new Date().toLocaleDateString('en-US'),
        updatedBy: 'Update from Pharmacist',
        lastAction: 'Sent to Pharmacist/NP',
        notes:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
      },
      {
        id: 3,
        date: new Date().toLocaleDateString('en-US'),
        updatedBy: 'Update from Pharmacist',
        lastAction: 'Sent to Pharmacist/NP',
        notes:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
      }
    ]
  },
  {
    id: 2,
    title: 'Duplicate Therapy Alert',
    body: 'Increetin-based therapies',
    steps: [
      '1. Bydureon Kit 2 mg subcutaneous injection, extended release ',
      '2. Januvia 50 mg oral tablet'
    ],
    status: 'resolved',
    lastAction: 'none',
    notes: 'NA',
    date: new Date().toLocaleDateString('en-US'),
    history: [
      {
        id: 1,
        date: new Date().toLocaleDateString('en-US'),
        updatedBy: 'Update from CM/Nurse',
        lastAction: 'Fax to MD',
        notes:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
      },
      {
        id: 2,
        date: new Date().toLocaleDateString('en-US'),
        updatedBy: 'Update from Pharmacist',
        lastAction: 'Sent to Pharmacist/NP',
        notes:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
      },
      {
        id: 3,
        date: new Date().toLocaleDateString('en-US'),
        updatedBy: 'Update from Pharmacist',
        lastAction: 'Sent to Pharmacist/NP',
        notes:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
      },
      {
        id: 4,
        date: new Date().toLocaleDateString('en-US'),
        updatedBy: 'Update from Pharmacist',
        lastAction: 'Sent to Pharmacist/NP',
        notes:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
      },
      {
        id: 5,
        date: new Date().toLocaleDateString('en-US'),
        updatedBy: 'Update from Pharmacist',
        lastAction: 'Sent to Pharmacist/NP',
        notes:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
      },
      {
        id: 6,
        date: new Date().toLocaleDateString('en-US'),
        updatedBy: 'Update from Pharmacist',
        lastAction: 'Sent to Pharmacist/NP',
        notes:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
      },
      {
        id: 7,
        date: new Date().toLocaleDateString('en-US'),
        updatedBy: 'Update from CM/Nurse',
        lastAction: 'Fax to MD',
        notes:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
      },
      {
        id: 8,
        date: new Date().toLocaleDateString('en-US'),
        updatedBy: 'Update from Pharmacist',
        lastAction: 'Sent to Pharmacist/NP',
        notes:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
      },
      {
        id: 9,
        date: new Date().toLocaleDateString('en-US'),
        updatedBy: 'Update from Pharmacist',
        lastAction: 'Sent to Pharmacist/NP',
        notes:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
      },
      {
        id: 10,
        date: new Date().toLocaleDateString('en-US'),
        updatedBy: 'Update from Pharmacist',
        lastAction: 'Sent to Pharmacist/NP',
        notes:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
      },
      {
        id: 11,
        date: new Date().toLocaleDateString('en-US'),
        updatedBy: 'Update from Pharmacist',
        lastAction: 'Sent to Pharmacist/NP',
        notes:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
      },
      {
        id: 12,
        date: new Date().toLocaleDateString('en-US'),
        updatedBy: 'Update from Pharmacist',
        lastAction: 'Sent to Pharmacist/NP',
        notes:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
      }
    ]
  },
  {
    id: 3,
    title: 'Duplicate Therapy Alert',
    body: 'Increetin-based therapies',
    steps: [
      '1. Bydureon Kit 2 mg subcutaneous injection, extended release ',
      '2. Januvia 50 mg oral tablet'
    ],
    status: 'resolved',
    lastAction: 'no',
    date: new Date().toLocaleDateString('en-US'),
    history: [
      {
        id: 1,
        date: new Date().toLocaleDateString('en-US'),
        updatedBy: 'Update from CM/Nurse',
        lastAction: 'Fax to MD',
        notes:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
      },
      {
        id: 2,
        date: new Date().toLocaleDateString('en-US'),
        updatedBy: 'Update from Pharmacist',
        lastAction: 'Sent to Pharmacist/NP',
        notes:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
      }
    ]
  },
  {
    id: 4,
    title: 'Assessment Alert',
    body: 'Increetin-based therapies',
    steps: [
      '1. Bydureon Kit 2 mg subcutaneous injection, extended release ',
      '2. Januvia 50 mg oral tablet'
    ],
    status: 'not resolved',
    lastAction: 'no',
    date: new Date().toLocaleDateString('en-US'),
    history: [
      {
        id: 1,
        date: new Date().toLocaleDateString('en-US'),
        updatedBy: 'Update from CM/Nurse',
        lastAction: 'Fax to MD',
        notes:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
      },
      {
        id: 2,
        date: new Date().toLocaleDateString('en-US'),
        updatedBy: 'Update from Pharmacist',
        lastAction: 'Sent to Pharmacist/NP',
        notes:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
      },
      {
        id: 3,
        date: new Date().toLocaleDateString('en-US'),
        updatedBy: 'Update from Pharmacist',
        lastAction: 'Sent to Pharmacist/NP',
        notes:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
      }
    ]
  }
];

const ALERT_LAST_ACTIONS = [
  { id: 1, name: 'Phone Call w/ Client/Family' },
  { id: 2, name: 'Home Visit w/ Client/Family' },
  { id: 3, name: 'Fax to MD' },
  { id: 4, name: 'Call to MD' },
  { id: 5, name: 'Info/Response from MD' },
  { id: 6, name: 'Spoke with CM' },
  { id: 7, name: 'Sent to Client/Family' },
  { id: 8, name: 'Sent to Phamacist/NP' },
  { id: 9, name: 'Sent to MD' },
  { id: 10, name: 'Info from Pharmacist' },
  {
    id: 11,

    name: 'Pharmacist Reviewed: No Intervention Recommended'
  },
  { id: 12, name: 'Pharmacist Reviewed: Combination therapy OK' },
  { id: 13, name: 'Pharmacist Reviewed: False Positive' },
  { id: 14, name: 'Pharmacist Reviewed: PRN+Regular OK' },
  {
    id: 15,

    name: 'Pharmacist Reviewed: Client Education Recommended'
  },
  { id: 16, name: 'Pharmacist Reviewed: Pt education completed' },
  { id: 17, name: 'Physician Reviewed: Approved Use As Is' },
  { id: 18, name: 'Physician Reviewed: CHANGED DOSE' },
  { id: 19, name: 'Physician Reviewed: CHANGED MED' },
  { id: 20, name: 'Physician Reviewed: D/C MED' },
  { id: 21, name: 'Physician Reviewed: APPOINTMENT REQUESTED' },
  { id: 22, name: 'Physician Reviewed: non-response' },
  {
    id: 23,
    name: 'Checked with Client: Event or vital sign recorded was inaccurate or has changed'
  },
  {
    id: 24,
    name: 'Checked with Client: Event unrelated to medications'
  },
  {
    id: 25,
    name: 'Checked with Client: reports discontinued medication'
  },
  { id: 26, name: 'Checked with Client: reports dose change' },
  {
    id: 27,
    name: 'Checked with Client: refused pharmacist recommendation'
  },
  {
    id: 28,
    name: 'Other'
  }
];

export const ALERT_LAST_ACTION = (userRole, status) => {
  if (status === 'UNRESOLVED')
    switch (userRole) {
      case ROLES.STAFF_SUPERVISOR:
      case ROLES.STAFF:
        return ALERT_LAST_ACTIONS?.filter(
          action =>
            action?.id <= 2 ||
            action?.id === 5 ||
            (action?.id > 6 && action?.id <= 10) ||
            action?.id === 28
        );
      case ROLES.PHARMACIST_SUPERVISOR:
      case ROLES.PHARMACIST:
        return ALERT_LAST_ACTIONS?.filter(
          action => action?.id <= 6 || action?.id === 28
        );
      case ROLES.GLOBAL_ADMIN:
      case ROLES.SITE_ADMIN:
      default:
        return ALERT_LAST_ACTIONS?.filter(
          action => action?.id < 11 || action?.id === 28
        );
    }
  else return ALERT_LAST_ACTIONS?.filter(action => action?.id >= 11);
};

export const ALERT_STATUS = [
  { value: 'RESOLVED', name: 'Resolved' },
  { value: 'UNRESOLVED', name: 'Not Resolved' },
  { value: 'CONTACTED_PCP', name: 'Contacted PCP' }
];
