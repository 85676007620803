export const arraysCombiner = (firstArray, secondArray) => {
  const combinedArray =
    firstArray?.length > secondArray?.length
      ? firstArray?.map((item, i) => ({
          id: i,
          ...item,
          ...(i < secondArray?.length ? secondArray[i] : {})
        })) || []
      : secondArray?.map((item, i) => ({
          id: i,
          ...item,
          ...(i < firstArray?.length ? firstArray[i] : {})
        })) || [];

  return combinedArray;
};
