import { gql } from '@apollo/client';

export const GET_ETHNICITY_REPORT = gql`
  query getEthnicityReport($data: StandardReportInput!) {
    getEthnicityReport(data: $data) {
      totalPatients
      totalYes
      totalNo
      totalNotCompleted
      totalUnknown
      totalPreferNotToDisclose
    }
  }
`;
