import { ROLES } from '../../constants';

export const prepareSubmit = (data, role) => {
  const userType = data?.userType.endsWith('s')
    ? data?.userType?.replace(/.$/, '').toUpperCase()?.replace(' ', '_')
    : data?.userType?.toUpperCase()?.replace(' ', '_');
  const newData = {
    ...data,
    phonenumber: data?.phoneNumber,
    extension: data?.phoneExtension,
    role: userType,
    siteId: localStorage.getItem('defaultSiteId'),
    active: data?.activeStatus === 'Active',
    accessAssessmentCompletion: data?.accessAssessmentCompletion === 'Yes',
    mobile: data?.mobile,
    criteriaAccess: Boolean(data?.criteriaAccess)
  };

  delete newData.phoneNumber;
  delete newData.phoneExtension;
  delete newData.userType;
  delete newData.activeStatus;
  delete newData.dateOfBirth;

  if (role === ROLES.GLOBAL_ADMIN && data?.assignSite)
    newData.siteId = data?.assignSite;

  if (data?.id) {
    // delete newData.email;
    delete newData.siteId;
    newData.userId = newData.id;
  }

  delete newData.assignSite;

  delete newData?.id;

  return newData;
};
