import classNames from 'classnames';
import { TextField, FormControl, InputAdornment } from '@material-ui/core';

import { styles } from './styles';
import { WarningIcon, DatePickerIcon } from '../../assets/icons';

interface ICustomInput {
  name?: string;
  type?: string;
  value?: string;
  label?: any;
  inputRef?: any;
  onChange?: any;
  errorMsg?: any;
  readOnly?: boolean;
  disabled?: boolean;
  required?: boolean;
  fullWidth?: boolean;
  multiline?: boolean;
  decimalStep?: string;
  placeholder?: string;
  autoComplete?: string;
  customInputClass?: any;
  styleLongInput?: boolean;
  styleShortInput?: boolean;
  styleGreyBorder?: boolean;
  labelComponent?: JSX.Element;
  styleDistantLabel?: boolean;
  onBlur?:
    | React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>
    | undefined;
  onKeyDown?: React.KeyboardEventHandler<HTMLDivElement> | undefined;
  endAdornment?: JSX.Element;
}

const CustomInput = ({
  name,
  value,
  label,
  onBlur,
  readOnly,
  onChange,
  errorMsg,
  inputRef,
  disabled,
  fullWidth,
  onKeyDown,
  multiline,
  decimalStep,
  placeholder,
  autoComplete,
  endAdornment,
  type = 'text',
  labelComponent,
  styleLongInput,
  styleGreyBorder,
  styleShortInput,
  required = false,
  customInputClass,
  styleDistantLabel
}: ICustomInput) => {
  const classes = styles();

  return (
    <div className={classes.formControlContainer}>
      <FormControl
        classes={{
          root: classNames(
            { [classes.fullWidth]: fullWidth },
            { [classes.formControlError]: errorMsg }
          )
        }}
      >
        {labelComponent ||
          (label && (
            <label
              className={classNames(classes.inputLabel, {
                [classes.distantLabel]: styleDistantLabel
              })}
            >
              {required ? `${label}*` : label}
            </label>
          ))}
        <TextField
          name={name}
          type={type}
          value={value}
          onBlur={onBlur}
          variant="outlined"
          disabled={disabled}
          required={required}
          onChange={onChange}
          inputRef={inputRef}
          onKeyDown={onKeyDown}
          multiline={multiline}
          fullWidth={fullWidth}
          rows={multiline ? 4 : 1}
          placeholder={placeholder}
          autoComplete={autoComplete || 'off'}
          inputProps={{ step: decimalStep }}
          InputProps={{
            readOnly,
            startAdornment: type === 'date' && (
              <InputAdornment position="start">
                <DatePickerIcon />
              </InputAdornment>
            ),
            endAdornment,
            className: classNames(
              classes.customInput,
              customInputClass,
              { [classes.longInput]: styleLongInput },
              { [classes.multilineInput]: multiline },
              { [classes.greyBorder]: styleGreyBorder },
              { [classes.shortInput]: styleShortInput },
              { [classes.disabled]: disabled || readOnly }
            )
          }}
        />
      </FormControl>
      {errorMsg && (
        <label className={classes.errorText}>
          <WarningIcon className={classes.warningLogo} />
          {errorMsg}
        </label>
      )}
    </div>
  );
};

export default CustomInput;
