import { gql } from '@apollo/client';

export const GET_ASSESSMENT_QUERY = gql`
  query GetAssessmentQuery($data: AssessmentQueryInput!) {
    getAssessmentQuery(data: $data) {
      data {
        firstName
        lastName
        staffName
        age
        patientId
        assessmentId
        publishedAt
        createdAt
        email
        gender
        race
        ethnicity
        militaryStatus
        phone
        city
        state
        zip
        optionalPatientSiteId
        siteTitle
        status
        completedAt
      }
      count
    }
  }
`;
