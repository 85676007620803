import { Suspense } from 'react';

import { Switch, Redirect, BrowserRouter } from 'react-router-dom';

import Home from '../pages/home';
import { PATH_NAME } from './pathNames';
import SuspendLoader from '../sharedComponents/Loader';
import {
  Login,
  CreateNewPassword,
  SecurityQuestions
  // Otp
} from './guestRoutes';

interface IRoutes {
  isLoggedIn: boolean;
}

const Routes = ({ isLoggedIn }: IRoutes) => (
  <BrowserRouter>
    <Suspense fallback={<SuspendLoader />}>
      {isLoggedIn ? (
        <Home />
      ) : (
        <Switch>
          {Login()}
          {/* {Otp()} */}
          {CreateNewPassword()}
          {SecurityQuestions()}
          <Redirect
            to={{
              pathname: PATH_NAME.LOGIN,
              state: {
                redirectUrl: window.location.pathname + window.location.search
              }
            }}
          />
        </Switch>
      )}
    </Suspense>
  </BrowserRouter>
);

export default Routes;
