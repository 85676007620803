import { gql } from '@apollo/client';

export const GET_ALL_SITES = gql`
  query getAllSites {
    getAllSites {
      id
      title
      role
      active
      children {
        id
        title
        role
        active
      }
    }
  }
`;
