import { lazy } from 'react';

export const Faq = lazy(() => import('../pages/faq'));
export const Bulletin = lazy(() => import('../pages/bulletin'));
export const Privacy = lazy(() => import('../pages/privacy'));
export const Home = lazy(() => import('../pages/home'));
export const Sites = lazy(() => import('../pages/sites'));
export const Login = lazy(() => import('../pages/login'));
export const Otp = lazy(() => import('../pages/otp'));
export const People = lazy(() => import('../pages/people'));
export const Alerts = lazy(() => import('../pages/alerts'));
export const Patient = lazy(() => import('../pages/patient'));
export const Reporting = lazy(() => import('../pages/reporting'));
export const Configure = lazy(() => import('../pages/configure'));
export const Dashboard = lazy(() => import('../pages/dashboard'));
export const Pharmacies = lazy(() => import('../pages/pharmacies'));
export const Assessment = lazy(() => import('../pages/assessment'));
export const PatientReport = lazy(() => import('../pages/patientReport'));
export const ManageCriterion = lazy(() => import('../pages/manageCriterion'));
export const AccountSettings = lazy(() => import('../pages/accountSettings'));
export const CreateNewPassword = lazy(() => import('../pages/setNewPassword'));
export const ComprehensiveReport = lazy(
  () => import('../pages/comprehensiveReport')
);

export const UnderConstruction = lazy(
  () => import('../pages/underConstruction')
);
export const SecurityQuestions = lazy(
  () => import('../pages/securityQuestions')
);

export const UserQuery = lazy(() => import('../pages/reporting/userQuery'));
export const PatientQuery = lazy(
  () => import('../pages/reporting/patientQuery')
);
export const AssessmentQuery = lazy(
  () => import('../pages/reporting/assessmentQuery')
);
export const UsageQuery = lazy(() => import('../pages/reporting/usageQuery'));
export const ActivitySummary = lazy(
  () => import('../pages/reporting/activitySummary')
);
export const AlertsResolved = lazy(
  () => import('../pages/reporting/alertsResolved')
);
export const AlertsGenerated = lazy(
  () => import('../pages/reporting/alertsGenerated')
);
export const TenMedsOrMore = lazy(
  () => import('../pages/reporting/tenMedsOrMore')
);
export const DuplicateDrugs = lazy(
  () => import('../pages/reporting/duplicateDrugs')
);
export const ReportedFalls = lazy(
  () => import('../pages/reporting/reportedFalls')
);
export const AgeDistribution = lazy(
  () => import('../pages/reporting/ageDistribution')
);
export const GenderDistribution = lazy(
  () => import('../pages/reporting/genderDistribution')
);
export const IncidentsSymptoms = lazy(
  () => import('../pages/reporting/incidentsSymptoms')
);
export const Conditions = lazy(() => import('../pages/reporting/conditions'));
export const Race = lazy(() => import('../pages/reporting/race'));
export const Ethnicity = lazy(() => import('../pages/reporting/ethnicity'));
export const MilitaryStatus = lazy(
  () => import('../pages/reporting/militaryStatus')
);

export const Recommendation = lazy(
  () => import('../pages/manageCriterion/recommendation')
);
export const Mapping = lazy(
  () => import('../pages/manageCriterion/manageFormMapping')
);

export const Account = lazy(() => import('../pages/accountSettings/account'));
export const Security = lazy(
  () => import('../pages/accountSettings/securityQuestions')
);
