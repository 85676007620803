import { PATH_NAME } from '../../routes/pathNames';
import { CorrectGreyIcon } from '../../assets/icons';

export const ACCOUNT_SETTINGS_TABS = [
  { id: 1, name: 'Account', url: PATH_NAME.ACCOUNT }
  // { id: 2, name: 'Security Questions', url: PATH_NAME.SECURITY }
];

export const ACCOUNT_SITES_COLUMNS = [
  { field: 'id', headerName: 'ID', sortable: false, flex: 1, hide: true },
  { field: 'site', headerName: 'Site', sortable: false, flex: 1 },
  { field: 'role', headerName: 'Role', flex: 1, sortable: false }
];

export const ACCOUNT_PASSWORD_CONDITIONS = [
  {
    id: 1,
    state: 'initial',
    label: '8 characters long',
    regex: /^[a-zA-Z0-9><;!:@#\$%\^\&*\)\(+=._\-{}'",±§?`~\/\\\[\]\|]{8,}$/,
    icon: CorrectGreyIcon
  },
  {
    id: 2,
    state: 'initial',
    label: 'Atleast 1 uppercase',
    regex: /^(.*[A-Z].*)$/,
    icon: CorrectGreyIcon
  },
  {
    id: 3,
    state: 'initial',
    label: '1 special character and 1 number.',
    regex: /^(?=\D*\d).*[><;!:@#\$%\^\&*\)\(+=._\-{}'",±§?`~\/\\\[\]\|].*$/,
    icon: CorrectGreyIcon
  },
  {
    id: 4,
    state: 'initial',
    label: 'No more than 2 characters repeated in sequence.',
    regex:
      /^(?!.*([0-9a-zA-Z><;!:@#\$%\^\&*\)\(+=._\-{}'",±§?`~\/\\\[\]\|])\1{2,}).+$/,
    icon: CorrectGreyIcon
  }
];
