export const mapPatientDrugsToViewModel = unMappedDrug => {
  const {
    id,
    name,
    route,
    asNeeded,
    dosage,
    dosageMeasurement,
    quantity,
    quantityMeasurement,
    frequency,
    direction,
    dispenseDate,
    firstUseDate,
    notes,
    providerId,
    pharmacyId
  } = unMappedDrug;

  return {
    id,
    name,
    route: route || '',
    asNeeded:
      asNeeded === undefined || asNeeded === null
        ? ''
        : asNeeded
        ? 'Yes'
        : 'No',
    dosage: [dosage ? dosage?.toString() : '', dosageMeasurement || ''],
    quantity: [quantity ? quantity?.toString() : '', quantityMeasurement || ''],
    frequency: frequency || '',
    directions: direction || '',
    dispenseDate: dispenseDate === undefined ? null : dispenseDate,
    firstUseDate: firstUseDate === undefined ? null : firstUseDate,
    providerId: providerId === '' ? 'N/A' : providerId || '',
    pharmacyId: pharmacyId === '' ? 'N/A' : pharmacyId || '',
    notes: notes || ''
  };
};
