import AdminRoute from '../adminRoute';
import { PATH_NAME } from '../pathNames';
import { Recommendation } from '../lazyLoaded';
import { ALL_PERMISSIONS } from '../../utils/constants';

const RecommendationRoute = () => (
  <AdminRoute
    exact
    path={PATH_NAME.RECOMMENDATION}
    permission={ALL_PERMISSIONS.CAN_ACCESS_MANAGE_CRITERION_PAGE}
    component={(props: JSX.IntrinsicAttributes) => (
      <Recommendation {...props} />
    )}
  />
);
export default RecommendationRoute;
