import { gql } from '@apollo/client';

export const GET_PATIENT_PHARMACIES = gql`
  query getPatientById($data: PatientIdField!) {
    getPatientById(data: $data) {
      pharmacies {
        npi
        name
        active
      }
    }
  }
`;
