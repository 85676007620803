import { Route as PublicRoute } from 'react-router-dom';

import { PATH_NAME } from '../pathNames';
import { ActivitySummary } from '../lazyLoaded';

const ActivitySummaryRoute = () => (
  <PublicRoute
    exact
    path={PATH_NAME.ACTIVITY_SUMMARY}
    component={(props: JSX.IntrinsicAttributes) => (
      <ActivitySummary {...props} />
    )}
  />
);
export default ActivitySummaryRoute;
