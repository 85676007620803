import CustomButton from '../customButton';
import { styles } from './styles';

interface Props {
  prompt: string | any;
  handleCancel: any;
  handleConfirm: any;
  cancelText?: string;
  confirmText?: string;
  isHideCancelText?: boolean;
  disabled?: boolean;
}

const ConfirmAddress = ({
  handleConfirm,
  handleCancel,
  confirmText,
  cancelText,
  prompt,
  isHideCancelText,
  disabled
}: Props) => {
  const classes = styles();

  return (
    <div>
      <div className={classes.confirmText}>{prompt}</div>
      <div className={classes.buttonGroup}>
        <CustomButton
          onClick={handleConfirm}
          customClass={classes.confirmButton}
          disabled={disabled}
        >
          {confirmText || 'Confirm'}
        </CustomButton>
        {!isHideCancelText && (
          <CustomButton secondary onClick={handleCancel}>
            {cancelText || 'Cancel'}
          </CustomButton>
        )}
      </div>
    </div>
  );
};

export default ConfirmAddress;
