import { Route as PublicRoute } from 'react-router-dom';

import { PATH_NAME } from '../pathNames';
import { PatientReport } from '../lazyLoaded';

const PatientReportRoute = () => (
  <PublicRoute
    exact
    path={PATH_NAME.PATIENT_REPORT}
    component={(props: JSX.IntrinsicAttributes) => <PatientReport {...props} />}
  />
);
export default PatientReportRoute;
