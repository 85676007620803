export const prepareProvidersSearch = searchValues => {
  const { npi, firstName, lastName, city, state, zip, nameFilterType } =
    searchValues;

  const searchData = {
    limit: 20,
    version: 2.1,
    use_first_name_alias: false,
    pretty: true,
    number: npi,
    first_name:
      nameFilterType === 'beginsWith' && firstName
        ? `${firstName}*`
        : firstName,
    last_name:
      nameFilterType === 'beginsWith' && lastName ? `${lastName}*` : lastName,
    city,
    state,
    postal_code: zip?.length >= 2 ? `${zip}*` : zip
  };

  const mappedData = Object.fromEntries(
    Object.entries(searchData).filter(([_, v]) => v != null && v !== '')
  );

  return mappedData;
};

export const mapProviderToSubmitHandler = providerRow => ({
  firstName: providerRow?.first_name,
  lastName: providerRow?.last_name,
  phone: providerRow?.phone,
  type: providerRow?.role,
  npi: (providerRow?.npi || providerRow?.id)?.toString(),
  address: providerRow?.address,
  city: providerRow?.city,
  zip: providerRow?.zip,
  state: providerRow?.state,
  fax: providerRow?.fax
});
