import {
  dateSwapper,
  getDateIgnoreTimezone,
  validatePhoneNumber
} from '../../helpers';

export const mapPatientProfileToForm = unmappedData => ({
  id: unmappedData?.id || '',
  firstName: unmappedData?.firstName || '',
  middleName: unmappedData?.middleName || '',
  lastName: unmappedData?.lastName || '',
  optionalPatientSiteId: unmappedData?.optionalPatientSiteId || '',
  date:
    getDateIgnoreTimezone(new Date(dateSwapper(unmappedData?.date)), true) ||
    null,
  gender: unmappedData?.gender || '',
  status: unmappedData?.active ? 'active' : 'inactive',
  email: unmappedData?.email || '',
  primaryPhone: validatePhoneNumber(unmappedData?.primaryPhone || ''),
  secondaryPhone: validatePhoneNumber(unmappedData?.secondaryPhone || ''),
  emergFullName: unmappedData?.emergFullName || '',
  emergPhone: validatePhoneNumber(unmappedData?.emergPhone || ''),
  emergRelation: unmappedData?.emergRelation || '',
  race: unmappedData?.race || '',
  ethnicity: unmappedData?.ethnicity || 'NO',
  militaryStatus: unmappedData?.militaryStatus || ''
});

export const mapFormToPatientProfile = unmappedForm => {
  const formData = {
    ...unmappedForm,
    patientId: unmappedForm?.id,
    date: getDateIgnoreTimezone(unmappedForm?.date),
    active: unmappedForm?.status === 'active',
    ethnicity: unmappedForm?.ethnicity,
    gender: unmappedForm?.gender?.toUpperCase()
  };
  delete formData.id;
  delete formData.status;
  return formData;
};
