import { gql } from '@apollo/client';

export const SEARCH_PATIENTS = gql`
  query searchPatients($data: SearchPatientsInput!) {
    searchPatients(data: $data) {
      data {
        id
        firstName
        lastName
        email
        active
        date
        primaryStaffId
        userDraftId
        siteTitle
        assignedUsers {
          id
        }
        optionalPatientSiteId
      }
      count
    }
  }
`;
