import { Route as PublicRoute } from 'react-router-dom';

import { PATH_NAME } from '../pathNames';
import { Account } from '../lazyLoaded';

const AccountRoute = () => (
  <PublicRoute
    exact
    path={PATH_NAME.ACCOUNT}
    component={(props: JSX.IntrinsicAttributes) => <Account {...props} />}
  />
);
export default AccountRoute;
