import { gql } from '@apollo/client';

export const GET_ACTIVITY_SUMMARY_TABLE = gql`
  query getActivitySummaryTable($data: ActivitySummaryTableInput!) {
    getActivitySummaryTable(data: $data) {
      data {
        patientId
        patientName
        age
        siteName
        primaryStaffName
      }
      count
    }
  }
`;
