import { gql } from '@apollo/client';

export const UPDATE_CRITERION = gql`
  mutation updateCriterion($data: UpdateCriterionInput!) {
    updateCriterion(data: $data) {
      id
      title
      alias
      disabled
      description
    }
  }
`;
