import { gql } from '@apollo/client';

export const GET_PATIENT_BY_ID = gql`
  query getPatientById($data: PatientIdField!) {
    getPatientById(data: $data) {
      id
      firstName
      middleName
      lastName
      optionalPatientSiteId
      date
      gender
      active
      email
      primaryPhone
      secondaryPhone
      emergFullName
      emergRelation
      emergPhone
      race
      ethnicity
      militaryStatus
      statistics {
        alertsCount
        drugs
        conditions
        allergies
      }
      primaryAddressId
      addresses {
        id
        address1
        address2
        city
        state
        zip
        active
        removed
        notes
      }
      primaryStaffId
      primaryStaffSupervisorId
      userDraftId
      siteTitle
      assignedUsers {
        id
        firstName
        lastName
        email
        phonenumber
        photo
        active
        role
      }
      pharmacies {
        npi
        name
        phone
        fax
        address
        state
        city
        zip
        active
      }
      providers {
        npi
        firstName
        lastName
        phone
        type
        fax
        address
        state
        city
        zip
        active
      }
    }
  }
`;
