import { GridColumns } from '@material-ui/data-grid';

export const PHARMACIES_FILTER = [
  { id: 1, name: 'npi', label: 'NPI', type: 'number' },
  { id: 2, name: 'name', label: 'Name' },
  { id: 3, name: 'city', label: 'City' }
];

export const PHARMACIES_COLUMNS: GridColumns = [
  { field: 'id', headerName: 'ID', hide: true },
  { field: 'name', headerName: 'Name', flex: 1.4, sortable: false },
  { field: 'npi', headerName: 'NPI', flex: 0.6, sortable: false },
  { field: 'address', headerName: 'Address', flex: 1.4, sortable: false },
  { field: 'city', headerName: 'City', flex: 0.6, sortable: false },
  { field: 'state', headerName: 'State', flex: 0.4, sortable: false },
  { field: 'zip', headerName: 'Zip Code', flex: 0.5, sortable: false },
  { field: 'phone', headerName: 'Phone', flex: 0.6, sortable: false },
  { field: 'fax', headerName: 'Fax', flex: 0.6, sortable: false }
];
