import { Route as PublicRoute } from 'react-router-dom';

import { Patient } from '../lazyLoaded';
import { PATH_NAME } from '../pathNames';

const PatientProfileRoute = () => (
  <PublicRoute
    exact
    path={PATH_NAME.PATIENT}
    component={(props: JSX.IntrinsicAttributes) => <Patient {...props} />}
  />
);
export default PatientProfileRoute;
