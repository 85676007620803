import HeaderRepresentation from './HeaderRepresentation';

interface IHeader {
    fetchSiteId:( siteId:any) => void;
  }
const Header = ({fetchSiteId}:IHeader) => {


return(<HeaderRepresentation fetchSiteId={fetchSiteId} />)};

export default Header;
