export const ROLES = {
  GUEST: 'GUEST',
  STAFF: 'STAFF',
  ANALYST: 'ANALYST',
  SITE_ADMIN: 'SITE_ADMIN',
  PHARMACIST: 'PHARMACIST',
  GLOBAL_ADMIN: 'GLOBAL_ADMIN',
  STAFF_SUPERVISOR: 'STAFF_SUPERVISOR',
  PHARMACIST_SUPERVISOR: 'PHARMACIST_SUPERVISOR'
} as const;

export const ALL_PERMISSIONS = {
  CREATE_SITE: 'create_site',
  RUN_REPORTS: 'run_reports',
  CREATE_STAFF: 'create_staff',
  VIEW_RECORDS: 'view_records',
  CREATE_ANALYST: 'create_analyst',
  VIEW_ADMIN_TAB: 'view_admin_tab',
  CREATE_SITE_ADMIN: 'create_site_admin',
  CREATE_PHARMACIST: 'create_pharmacist',
  ASSIGN_ASSESSMENTS: 'assign_assessments',
  CAN_ACCESS_DASHBOARD: 'can_access_dashboard',
  CAN_ACCESS_SITES_PAGE: 'can_access_sites_page',
  CREATE_PATIENT_RECORD: 'create_patient_record',
  NAVIGATE_ADMIN_ROUTES: 'navigate_admin_routes',
  CREATE_STAFF_SUPERVISOR: 'create_staff_supervisor',
  CAN_ACCESS_REPORTING_PAGE: 'can_access_reporting_page',
  ASSIGN_PRIMARY_PHARMACIST: 'assign_primary_pharmacist',
  CREATE_SITE_ADMIN_CURRENT_SITE: 'create_site_admin_current_site',
  CAN_ACCESS_MANAGE_FAQ_PAGE: 'can_access_manage_faq_page',
  CAN_ACCESS_MANAGE_BULLETIN_PAGE: 'can_access_manage_bulletin_page',
  CAN_ACCESS_MANAGE_CRITERION_PAGE: 'can_access_manage_criterion_page',
  CAN_VIEW_DASHBOARD_STAFF_MEMBERS: 'can_view_dashboard_staff_members',
  CAN_VIEW_DASHBOARD_ASSESSMENTS_TO_DO: 'can_view_dashboard_assessments_to_do',
  CAN_VIEW_DASHBOARD_PATIENTS_UNASSIGNED:
    'can_view_dashboard_patients_unassigned',
  CAN_VIEW_DASHBOARD_ASSESSMENT_INCOMPLETE:
    'can_view_dashboard_assessment_incomplete',
  CAN_ACCESS_MANAGE_CONFIGURE_PAGE: 'can_access_manage_configure_page',
  CAN_ACCESS_MANAGE_PHARMACIES_PAGE: 'can_access_manage_pharmacies_page'
} as const;

export const USER_PERMISSIONS = {
  [ROLES.GLOBAL_ADMIN]: [
    ALL_PERMISSIONS.CREATE_SITE,
    ALL_PERMISSIONS.CREATE_STAFF,
    ALL_PERMISSIONS.VIEW_ADMIN_TAB,
    ALL_PERMISSIONS.CREATE_ANALYST,
    ALL_PERMISSIONS.CREATE_SITE_ADMIN,
    ALL_PERMISSIONS.CREATE_PHARMACIST,
    ALL_PERMISSIONS.CAN_ACCESS_DASHBOARD,
    ALL_PERMISSIONS.CAN_ACCESS_SITES_PAGE,
    ALL_PERMISSIONS.CREATE_PATIENT_RECORD,
    ALL_PERMISSIONS.NAVIGATE_ADMIN_ROUTES,
    ALL_PERMISSIONS.CREATE_STAFF_SUPERVISOR,
    ALL_PERMISSIONS.CAN_ACCESS_REPORTING_PAGE,
    ALL_PERMISSIONS.CAN_VIEW_DASHBOARD_STAFF_MEMBERS,
    ALL_PERMISSIONS.CAN_ACCESS_MANAGE_FAQ_PAGE,
    ALL_PERMISSIONS.CAN_ACCESS_MANAGE_BULLETIN_PAGE,
    ALL_PERMISSIONS.CAN_ACCESS_MANAGE_CRITERION_PAGE,
    ALL_PERMISSIONS.CAN_VIEW_DASHBOARD_PATIENTS_UNASSIGNED,
    ALL_PERMISSIONS.CAN_VIEW_DASHBOARD_ASSESSMENT_INCOMPLETE,
    ALL_PERMISSIONS.CAN_ACCESS_MANAGE_CONFIGURE_PAGE,
    ALL_PERMISSIONS.CAN_ACCESS_MANAGE_PHARMACIES_PAGE
  ],
  [ROLES.SITE_ADMIN]: [
    ALL_PERMISSIONS.CREATE_STAFF,
    ALL_PERMISSIONS.CREATE_ANALYST,
    ALL_PERMISSIONS.VIEW_ADMIN_TAB,
    ALL_PERMISSIONS.CREATE_PHARMACIST,
    ALL_PERMISSIONS.CAN_ACCESS_DASHBOARD,
    ALL_PERMISSIONS.CREATE_PATIENT_RECORD,
    ALL_PERMISSIONS.NAVIGATE_ADMIN_ROUTES,
    ALL_PERMISSIONS.CREATE_STAFF_SUPERVISOR,
    ALL_PERMISSIONS.CAN_ACCESS_REPORTING_PAGE,
    ALL_PERMISSIONS.CREATE_SITE_ADMIN_CURRENT_SITE,
    ALL_PERMISSIONS.CAN_VIEW_DASHBOARD_STAFF_MEMBERS,
    ALL_PERMISSIONS.CAN_VIEW_DASHBOARD_PATIENTS_UNASSIGNED,
    ALL_PERMISSIONS.CAN_VIEW_DASHBOARD_ASSESSMENT_INCOMPLETE,
    ALL_PERMISSIONS.CAN_ACCESS_MANAGE_CONFIGURE_PAGE,
    ALL_PERMISSIONS.CAN_ACCESS_MANAGE_PHARMACIES_PAGE
  ],
  [ROLES.STAFF_SUPERVISOR]: [
    ALL_PERMISSIONS.CREATE_STAFF,
    ALL_PERMISSIONS.CAN_ACCESS_DASHBOARD,
    ALL_PERMISSIONS.CREATE_PATIENT_RECORD,
    ALL_PERMISSIONS.CAN_ACCESS_REPORTING_PAGE,
    ALL_PERMISSIONS.CAN_VIEW_DASHBOARD_STAFF_MEMBERS,
    ALL_PERMISSIONS.CAN_VIEW_DASHBOARD_PATIENTS_UNASSIGNED,
    ALL_PERMISSIONS.CAN_VIEW_DASHBOARD_ASSESSMENT_INCOMPLETE
  ],
  [ROLES.STAFF]: [
    ALL_PERMISSIONS.CAN_ACCESS_DASHBOARD,
    ALL_PERMISSIONS.CREATE_PATIENT_RECORD,
    ALL_PERMISSIONS.CAN_VIEW_DASHBOARD_ASSESSMENTS_TO_DO
  ],
  [ROLES.PHARMACIST_SUPERVISOR]: [
    ALL_PERMISSIONS.CAN_ACCESS_DASHBOARD,
    ALL_PERMISSIONS.CREATE_PATIENT_RECORD,
    ALL_PERMISSIONS.CAN_ACCESS_REPORTING_PAGE,
    ALL_PERMISSIONS.CAN_VIEW_DASHBOARD_PATIENTS_UNASSIGNED,
    ALL_PERMISSIONS.CAN_ACCESS_MANAGE_CRITERION_PAGE,
    ALL_PERMISSIONS.VIEW_ADMIN_TAB
  ],
  [ROLES.PHARMACIST]: [
    ALL_PERMISSIONS.ASSIGN_ASSESSMENTS,
    ALL_PERMISSIONS.CAN_ACCESS_DASHBOARD,
    ALL_PERMISSIONS.CREATE_PATIENT_RECORD,
    ALL_PERMISSIONS.ASSIGN_PRIMARY_PHARMACIST
  ],
  [ROLES.ANALYST]: [
    ALL_PERMISSIONS.RUN_REPORTS,
    ALL_PERMISSIONS.VIEW_RECORDS,
    ALL_PERMISSIONS.CAN_ACCESS_REPORTING_PAGE
  ],
  [ROLES.GUEST]: []
} as const;
