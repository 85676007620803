import { GridColDef, GridValueGetterParams } from '@material-ui/data-grid';

export const PATIENTS_STAT = [
  { id: 1, name: 'Alerts', field: 'alertsCount' },
  { id: 2, name: 'Medications', field: 'drugs' },
  { id: 3, name: 'Conditions', field: 'conditions' },
  { id: 4, name: 'Allergies', field: 'allergies' }
];

export const PATIENTS_TABS = [
  { id: 1, name: 'Personal Information' },
  { id: 2, name: 'Assessments' }
];

export const ALERTS_TABS = [
  { id: 'ALL', name: 'All' },
  { id: 'PUBLISHED', name: 'Published' },
  { id: 'DRAFT', name: 'Drafts' }
];

export const PERSONAL_INFO = [
  {
    id: 1,
    name: 'id',
    readOnly: true,
    label: 'Homemeds system ID (Read only)'
  },
  { id: 2, name: 'firstName', label: 'First Name*' },
  { id: 3, name: 'middleName', label: 'Middle Name' },
  { id: 4, name: 'lastName', label: 'Last Name*' }
];

export const CONTACT_INFO = [
  { id: 1, name: 'email', label: 'Email Address' },
  { id: 2, name: 'primaryPhone', label: 'Primary Phone*', type: 'phoneNumber' },
  {
    id: 3,
    name: 'secondaryPhone',
    label: 'Secondary Phone',
    type: 'phoneNumber'
  },
  { id: 4, name: 'emergFullName', label: 'Emergency Contact Full Name' }
];

export const EMERGENCY_CONTACT_OPTIONS = [
  'Husband',
  'Wife',
  'Daughter',
  'Son',
  'Partner',
  'Friend',
  'Neighbor',
  'Caregiver',
  'Other'
];

export const ETHNICITY_OPTIONS = [
  { id: 'YES', name: 'Yes' },
  { id: 'NO', name: 'No' },
  { id: 'UNKNOWN', name: 'Unknown' },
  { id: 'PREFER_NOT_TO_DISCLOSE', name: 'Prefer not to disclose' }
];

export const RACE_OPTIONS = [
  { id: 'AMERICAN_OR_ALASKA', name: 'American Indian or Alaska Native' },
  { id: 'ASIAN', name: 'Asian' },
  { id: 'BLACK_OR_AFRICAN', name: 'Black or African American' },
  { id: 'WHITE', name: 'White' },
  {
    id: 'HAWAIIAN_OR_PACIFIC',
    name: 'Native Hawaiian or other Pacific Islander'
  },
  { id: 'TWO_OR_MORE', name: 'Two or more races' },
  { id: 'PREFER_NOT_TO_DISCLOSE', name: 'Prefer not to disclose' }
];

export const MILITARY_OPTIONS = [
  { id: 'PROTECTED_US_VETERAN', name: 'Protected U.S. Veteran' },
  { id: 'ACTIVE_RESERVE_MILITARY_DUTY', name: 'Active/Reserve Military Duty' },
  { id: 'OTHER', name: 'Other' },
  { id: 'NOT_APPLICABLE', name: 'Not Applicable' },
  { id: 'PREFER_NOT_TO_DISCLOSE', name: 'Prefer not to disclose' }
];

export const STAFF_COLUMNS: GridColDef[] = [
  { field: 'id', headerName: 'ID', hide: true },
  { field: 'status', headerName: 'Profile', sortable: false, flex: 1 },
  { field: 'name', headerName: 'Name', sortable: false, flex: 1.2 },
  { field: 'role', headerName: 'Role', sortable: false, flex: 1.2 },
  { field: 'linkedStatus', headerName: 'Status', sortable: false, flex: 1 }
];

export const STAFF_FORM_COLUMNS: GridColDef[] = [
  { field: 'id', headerName: 'ID', hide: true },
  { field: 'name', headerName: 'Name', sortable: false, flex: 1 },
  { field: 'email', headerName: 'Email', sortable: false, flex: 1 },
  { field: 'role', headerName: 'Role', sortable: false, flex: 1 },
  { field: 'linkedStatus', headerName: 'Action', sortable: false, flex: 1 }
];

export const PHARMACIST_FORM_COLUMNS: GridColDef[] = [
  { field: 'id', headerName: 'ID', hide: true },
  { field: 'name', headerName: 'Name', sortable: false, flex: 1 },
  { field: 'email', headerName: 'Email', sortable: false, flex: 1 },
  { field: 'linkedStatus', headerName: 'Action', sortable: false, flex: 1 }
];

export const PHARMACIES_COLUMNS_PATIENT: GridColDef[] = [
  { field: 'id', headerName: 'ID', hide: true },
  { field: 'name', headerName: 'Name', sortable: false, flex: 1 },
  { field: 'npi', headerName: 'NPI', sortable: false, flex: 1 },
  { field: 'address', headerName: 'Address', sortable: false, flex: 1.4 },
  { field: 'city', headerName: 'City', sortable: false, flex: 1 },
  { field: 'state', headerName: 'State', sortable: false, flex: 0.6 },
  { field: 'zip', headerName: 'Zip Code', sortable: false, flex: 0.8 },
  { field: 'phone', headerName: 'Phone', sortable: false, flex: 0.9 },
  { field: 'fax', headerName: 'Fax', sortable: false, flex: 0.9 },
  { field: 'linkedStatus', headerName: 'Action', flex: 0.7, sortable: false }
];

export const PROVIDERS_COLUMNS: GridColDef[] = [
  { field: 'id', headerName: 'ID', hide: true },
  { field: 'type', headerName: 'Role', sortable: false, flex: 1 },
  { field: 'name', headerName: 'Name', sortable: false, flex: 1 },
  { field: 'npi', headerName: 'NPI', sortable: false, flex: 0.6 },
  { field: 'address', headerName: 'Address', sortable: false, flex: 1.7 },
  { field: 'city', headerName: 'City', sortable: false, flex: 0.6 },
  { field: 'state', headerName: 'State', sortable: false, flex: 0.4 },
  { field: 'zip', headerName: 'Zip Code', sortable: false, flex: 0.6 },
  { field: 'phone', headerName: 'Phone', sortable: false, flex: 0.7 },
  { field: 'fax', headerName: 'Fax', sortable: false, flex: 0.7 }
];

export const PROVIDERS_FORM_COLUMNS: GridColDef[] = [
  { field: 'id', headerName: 'ID', hide: true },
  { field: 'role', headerName: 'Role', sortable: false, flex: 0.7 },
  {
    field: 'name',
    headerName: 'Name',
    sortable: false,
    flex: 0.8,
    valueGetter: (params: GridValueGetterParams) =>
      `${params.getValue(params.id, 'first_name') || ''}
    ${params.getValue(params.id, 'last_name') || ''}`
  },
  { field: 'npi', headerName: 'NPI', sortable: false, flex: 0.6 },
  { field: 'address', headerName: 'Address', sortable: false, flex: 0.8 },
  { field: 'city', headerName: 'City', sortable: false, flex: 0.5 },
  { field: 'state', headerName: 'State', sortable: false, flex: 0.4 },
  { field: 'zip', headerName: 'Zip Code', sortable: false, flex: 0.4 },
  { field: 'phone', headerName: 'Phone', sortable: false, flex: 0.7 },
  { field: 'fax', headerName: 'Fax', sortable: false, flex: 0.5 },
  { field: 'linkedStatus', headerName: 'Action', flex: 0.7, sortable: false }
];

export const ASSESSMENT_COLUMNS: GridColDef[] = [
  { field: 'id', headerName: 'ID', hide: true },
  {
    field: 'contributors',
    headerName: 'Contributor(s)',
    sortable: false,
    flex: 0.9
  },
  {
    field: 'assignedStaff',
    valueGetter: ({ row }) =>
      `${row?.assignedStaff?.firstName} ${row?.assignedStaff?.lastName}`,
    headerName: 'Assigned To',
    sortable: false,
    flex: 0.9
  },
  {
    field: 'pharmacists',
    headerName: 'Pharmacist(s)',
    sortable: false,
    flex: 0.9
  },
  { field: 'status', headerName: 'Status', flex: 0.7 },
  { field: 'report', headerName: 'Report', sortable: false, flex: 1.5 },
  { field: 'alertsCount', headerName: 'Alerts', sortable: false, flex: 0.9 },
  {
    field: 'completedAt',
    headerName: 'Completion Date',
    sortable: false,
    flex: 0.9
  },
  {
    field: 'processing',
    headerName: ' ',
    sortable: false,
    flex: 1.1
  },
  {
    field: 'action',
    headerName: ' ',
    cellClassName: 'action',
    sortable: false,
    flex: 0.3
  }
];

export const OPTIONS = [
  { id: 1, value: 'Yes' },
  { id: 2, value: 'No' }
];

export const ADDRESS_COLUMNS: GridColDef[] = [
  { field: 'id', headerName: 'ID', hide: true },
  { field: 'status', headerName: 'Status', sortable: false, flex: 0.5 },
  { field: 'address', headerName: 'Address', sortable: false, flex: 1.2 },
  { field: 'city', headerName: 'City', sortable: false, flex: 1 },
  { field: 'state', headerName: 'State', sortable: false, flex: 0.4 },
  { field: 'zip', headerName: 'Zip Code', sortable: false, flex: 0.4 },
  { field: 'notes', headerName: 'Note', sortable: false, flex: 1 }
];
