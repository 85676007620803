import { gql } from '@apollo/client';

export const GET_CONDITIONS_TABLE = gql`
  query getConditionsTable($data: ConditionsTableInput!) {
    getConditionsTable(data: $data) {
      data {
        patientId
        patientName
        age
        siteName
        primaryStaffName
        conditions
      }
      count
    }
  }
`;
