import { useQuery } from '@apollo/client';

import { permissionModel } from '../../models';
import { GET_LOCAL_STATES } from '../../graphQL/queries';

interface Props {
  children: any;
  permission: permissionModel;
}

const PrivateComponent = ({ children, permission }: Props) => {
  const {
    data: {
      localStates: { userPermissions }
    }
  } = useQuery(GET_LOCAL_STATES);

  const couldShow: boolean = userPermissions.includes(permission);

  if (couldShow) return children;
  return null;
};

export default PrivateComponent;
