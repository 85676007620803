import React, { createContext, useEffect, useState } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import { differenceInDays } from 'date-fns';
import Header from './header';
import Footer from './footer';
import { styles } from './styles';
import PlainNavBar from './bulletin';
import { GET_ALL_BULLETIN } from '../../graphQL/queries';
import { PATH_NAME } from '../../routes/pathNames';
import {
  Faq,
  Bulletin,
  Privacy,
  Sites,
  Alerts,
  People,
  Patient,
  Mapping,
  Configure,
  Reporting,
  Dashboard,
  Assessment,
  Pharmacies,
  PatientReport,
  Recommendation,
  AccountSettings,
  ManageCriterion,
  UnderConstruction,
  ComprehensiveReport
} from '../../routes/userRoutes';

export const Context = createContext({});

const HomeRepresentation = () => {
  const [id, setId] = useState('');
  const [isPlainNavBarVisible, setPlainNavBarVisible] = useState(false);
  const classes = styles();

  const fetchSiteId = (siteId: any) => {
    const id = siteId;
    setId(siteId);
  };

  const [getAllBulletin] = useLazyQuery(GET_ALL_BULLETIN, {
    fetchPolicy: 'cache-and-network',
    onCompleted: data => {
      const dataForOtherRole = data.getAllBulletin.data.filter(
        bulletin => bulletin.status === true
      );
      const updatedAtList = data.getAllBulletin.data.map(
        bulletin => bulletin.updatedAt
      );
      const currentDate = new Date();
      const updatedAtWithin15Days = updatedAtList.map(
        updatedby => differenceInDays(currentDate, new Date(updatedby)) < 15
      );

      const shouldDisplayNavBar =
        dataForOtherRole.length > 0 || !updatedAtWithin15Days;
      setPlainNavBarVisible(shouldDisplayNavBar);
    },
    onError: error => {
      console.error('Error fetching bulletins:', error);
    }
  });

  useEffect(() => {
    if (id) {
      getAllBulletin();
    }
  }, [id, getAllBulletin]);

  const handleNavBarClose = () => {
    setPlainNavBarVisible(false);
  };
  return (
    <main className={classes.root}>
      <Header fetchSiteId={fetchSiteId} />
      {isPlainNavBarVisible && (
        <PlainNavBar
          onClose={handleNavBarClose}
          onLinkClick={handleNavBarClose}
        />
      )}
      <main className={classes.container}>
        <Context.Provider value={id}>
          <MemoizedRoutes />
        </Context.Provider>
        <Footer />
      </main>
    </main>
  );
};

// Memoized version of routes to avoid re-renders
const MemoizedRoutes = React.memo(() => (
  <Switch>
    {Faq()}
    {Bulletin()}
    {Privacy()}
    {Sites()}
    {People()}
    {Alerts()}
    {Patient()}
    {Mapping()}
    {Configure()}
    {Reporting()}
    {Dashboard()}
    {Assessment()}
    {Pharmacies()}
    {PatientReport()}
    {Recommendation()}
    {ManageCriterion()}
    {AccountSettings()}
    {UnderConstruction()}
    {ComprehensiveReport()}

    <Redirect to={PATH_NAME.DASHBOARD} />
  </Switch>
));

export default HomeRepresentation;
