import { Route as PublicRoute } from 'react-router-dom';

import { PATH_NAME } from '../pathNames';
import { Conditions } from '../lazyLoaded';

const ConditionsRoute = () => (
  <PublicRoute
    exact
    path={PATH_NAME.CONDITIONS}
    component={(props: JSX.IntrinsicAttributes) => <Conditions {...props} />}
  />
);
export default ConditionsRoute;
