import { useState, useEffect } from 'react';

import { makeVar, useMutation, useQuery } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, SubmitHandler } from 'react-hook-form';

import { patientProfileFormModel } from '../../../models';
import { UPDATE_PATIENT } from '../../../graphQL/mutations';
import {
  getDateIgnoreTimezone,
  validatePhoneNumber
} from '../../../utils/helpers';
import PatientFormRepresentation from './PatientFormRepresentation';
import { GET_PATIENT_BY_ID, GET_LOCAL_STATES } from '../../../graphQL/queries';
import { patientProfileValidationSchema } from '../../../utils/validationSchema';
import {
  mapFormToPatientProfile,
  mapPatientProfileToForm
} from '../../../utils/mappings';

export const stopRefresh = makeVar(false);

interface IPatientForm {
  patientData: any;
  activeTab: number;
  handleActiveTab: (id: number) => void;
}

const PatientForm = ({
  activeTab,
  patientData,
  handleActiveTab
}: IPatientForm) => {
  const [open, setOpen] = useState(false);
  const [isBlocked, setIsBlocked] = useState(true);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [isErrorToastModalOpen, setIsErrorToastModalOpen] =
    useState<boolean>(false);
  const initialValues = { ...mapPatientProfileToForm(patientData) };

  const handleModalOpen = () => setOpen(true);
  const handleModalClose = () => setOpen(false);
  const handleErrorToastClose = () => setIsErrorToastModalOpen(false);
  const handleToastClose = () => setIsConfirmed(false);

  const [updatePatient, { loading: updatePatientLoading }] = useMutation(
    UPDATE_PATIENT,
    {
      onCompleted: () => {
        setIsConfirmed(true);
        reset({ ...getValues() });
      },
      refetchQueries: [GET_PATIENT_BY_ID]
    }
  );

  const {
    data: {
      localStates: { userRole }
    }
  } = useQuery(GET_LOCAL_STATES);

  const {
    watch,
    reset,
    register,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors, isDirty, isSubmitted }
  } = useForm<patientProfileFormModel>({
    mode: 'onChange',
    defaultValues: initialValues,
    resolver: yupResolver(patientProfileValidationSchema)
  });

  const handleDateChange = (name: string, date: any) => {
    if (!Number.isNaN(date?.getTime()))
      setValue(name as 'date', getDateIgnoreTimezone(date, true) as string, {
        shouldDirty: true,
        shouldValidate: true
      });
  };

  useEffect(() => {
    if (isDirty && !isSubmitted) {
      stopRefresh(true);
    } else {
      stopRefresh(false);
    }
  }, [isDirty, isSubmitted]);

  const handleFormSubmit: SubmitHandler<patientProfileFormModel> = async (
    data,
    e
  ) => {
    e?.preventDefault();
    if (patientData?.addresses?.length < 1) {
      setIsErrorToastModalOpen(true);
    } else
      updatePatient({ variables: { data: mapFormToPatientProfile(data) } });
  };

  const handlePhoneNumber = (e: any, fieldName: any) => {
    const inputValue = e.target.value as string;
    if (/^[\d() -]*$/.test(inputValue)) {
      const phoneNumber = validatePhoneNumber(inputValue);
      setValue(fieldName, phoneNumber, {
        shouldDirty: true,
        shouldValidate: true
      });
    }
  };

  const handleRegister = (fieldName: any) => {
    const { ref: inputRef, ...rest } = register(fieldName);
    return { inputRef, ...rest };
  };

  const handleDropdown = (e: any, dropdownName: any) => {
    setValue(dropdownName, e.target.value as string, {
      shouldDirty: true,
      shouldValidate: true
    });
  };

  const changeActiveTab = (id: number) => {
    if (isBlocked && isDirty && !isSubmitted) handleModalOpen();
    else handleActiveTab(id);
  };

  const handleUnblock = () => {
    setIsBlocked(false);
    handleModalClose();
    handleActiveTab(2);
  };

  return (
    <PatientFormRepresentation
      open={open}
      watch={watch}
      errors={errors}
      isDirty={isDirty}
      userRole={userRole}
      activeTab={activeTab}
      isConfirmed={isConfirmed}
      isSubmitted={isSubmitted}
      handleSubmit={handleSubmit}
      handleUnblock={handleUnblock}
      handleDropdown={handleDropdown}
      handleRegister={handleRegister}
      handleActiveTab={changeActiveTab}
      handleToastClose={handleToastClose}
      handleModalClose={handleModalClose}
      handleDateChange={handleDateChange}
      handleFormSubmit={handleFormSubmit}
      handlePhoneNumber={handlePhoneNumber}
      isErrorToastOpen={isErrorToastModalOpen}
      handleErrorToastClose={handleErrorToastClose}
      updatePatientLoading={updatePatientLoading}
    />
  );
};

export default PatientForm;
