import { gql } from '@apollo/client';

export const GET_ALL_SECONDARY_PHARMACISTS = gql`
  query GetAllSecondaryPharmacists {
    getAllSecondaryPharmacists {
      data {
        id
        firstName
        lastName
      }
      count
    }
  }
`;
