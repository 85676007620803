import { gql } from '@apollo/client';

export const SEARCH_DASHBOARD_PRIMARY_PHARMACIST = gql`
  query searchDashboardPrimaryPharmacist($data: PatientDashboardInput!) {
    patientDashboard(data: $data) {
      data {
        patientId
        patientName
        email
        phonenumber
        alertsCount
        assessmentId
        staffName
        secondaryPharmacistName
        primaryPharmacistName
        processingAssessmentId
        assessmentStatus
        publishedAt
        staffEmail
        staffPhonenumber
        pharmacistPhonenumber
        pharmacistEmail
      }
      count
      patientsWithAlertsUnresolved
    }
  }
`;
