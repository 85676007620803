import { makeStyles, createStyles, Theme } from '@material-ui/core';
import { customThemeModel } from '../../../../models';

export const styles = makeStyles((theme: Theme & customThemeModel) =>
  createStyles({
    headerNav: {
      display: 'flex',
      marginLeft: 32,
      '& > :first-child': {
        marginLeft: 0
      }
    },
    menuItem: {
      marginLeft: 48,
      padding: 0,
      color: theme.colors.inActive,
      fontSize: 14,
      fontFamily: 'Open Sans',
      fontWeight: 600,
      fontStyle: 'normal',
      lineHeight: '16px',
      cursor: 'pointer'
    },
    selectedMenuItem: {
      color: theme.colors.white,
      fontWeight: 'bold'
    },
    menuLink: {
      color: 'inherit',
      textDecoration: 'none'
    },
    adminArrowButton: {
      marginLeft: 5
    },
    adminButton: {
      margin: 0,
      textTransform: 'none'
    },
    adminPopper: {
      marginTop: 14,
      zIndex: 101
    },
    paper: {
      width: theme.lengths.paperWidth,
      padding: '2px 0',
      backgroundColor: theme.colors.white,
      border: `1px solid ${theme.colors.primary}`,
      borderRadius: 8
    },
    adminMenuList: {
      padding: 0
    },
    menuI: {
      marginLeft: 48,
      padding: 0,
      color: theme.colors.inActive,
      fontSize: 14,
      fontFamily: 'Open Sans',
      fontWeight: 600,
      fontStyle: 'normal',
      lineHeight: '16px',
      cursor: 'pointer'
    },
    menuL: {
      whiteSpace: 'nowrap',
      color: 'inherit',
      textDecoration: 'none'
    },
    adminMenuItem: {
      padding: 12,
      color: theme.colors.text,
      fontSize: 14,
      fontFamily: 'Open Sans',
      fontWeight: 600,
      fontStyle: 'normal',
      lineHeight: '16px'
    }
  })
);
