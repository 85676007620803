import { gql } from '@apollo/client';

export const GET_USER_ROLES = gql`
  query getUserRoles($data: GetUserRolesInput!) {
    getUserRoles(data: $data) {
      email
      siteName
      userName
      roleName
      accessAssessmentCompletion
      siteId
      siteAccess
    }
  }
`;
