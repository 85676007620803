import { Route as PublicRoute } from 'react-router-dom';

import { PATH_NAME } from '../pathNames';
import { TenMedsOrMore } from '../lazyLoaded';

const TenMedsOrMoreRoute = () => (
  <PublicRoute
    exact
    path={PATH_NAME.TEN_MEDS_OR_MORE}
    component={(props: JSX.IntrinsicAttributes) => <TenMedsOrMore {...props} />}
  />
);
export default TenMedsOrMoreRoute;
