import { makeStyles, createStyles, Theme } from '@material-ui/core';
import { customThemeModel } from '../../../../models';

export const styles = makeStyles((theme: Theme & customThemeModel) =>
  createStyles({
    ProfileButton: {
      minWidth: 'auto',
      height: '100%',
      padding: 0
    },
    alignment: {
      height: '90%'
    },
    ProfileImg: {
      borderRadius: '50%'
    },
    profilePopper: {
      marginTop: -6,
      zIndex: 101
    },
    menuList: {
      padding: 0
    },
    paper: {
      width: theme.lengths.paperWidth,
      padding: '2px 0',
      backgroundColor: theme.colors.white,
      border: `1px solid ${theme.colors.primary}`,
      borderRadius: 8
    },
    menuLink: {
      color: 'inherit',
      textDecoration: 'none'
    },
    menuItem: {
      padding: 12,
      color: theme.colors.text,
      fontSize: 14,
      fontFamily: 'Open Sans',
      fontWeight: 600,
      fontStyle: 'normal',
      lineHeight: '16px'
    }
  })
);
