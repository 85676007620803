import { gql } from '@apollo/client';

export const GET_USER_SECURITY_QUESTIONS_ANSWERS = gql`
  query GetUserSecurityQuestionsAnswers {
    getUserSecurityQuestionsAnswers {
      id
      question
      answer
    }
  }
`;
