export const dateReverseFormatter = (date: string) => {
  const dateObject = new Date(date);

  const year = dateObject.getFullYear();

  let month = (1 + dateObject.getMonth()).toString();
  month = month.length > 1 ? month : `0${month}`;

  let day = dateObject.getDate().toString();
  day = day.length > 1 ? day : `0${day}`;

  return `${month}/${day}/${year}`;
};
