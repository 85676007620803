import { Route as PublicRoute } from 'react-router-dom';

import { PATH_NAME } from '../pathNames';
import { PatientQuery } from '../lazyLoaded';

const PatientQueryRoute = () => (
  <PublicRoute
    exact
    path={PATH_NAME.PATIENT_QUERY}
    component={(props: JSX.IntrinsicAttributes) => <PatientQuery {...props} />}
  />
);
export default PatientQueryRoute;
