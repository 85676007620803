import { Sites } from '../lazyLoaded';
import AdminRoute from '../adminRoute';
import { PATH_NAME } from '../pathNames';
import { ALL_PERMISSIONS } from '../../utils/constants';

const SitesRoute = () => (
  <AdminRoute
    exact
    path={PATH_NAME.SITES}
    permission={ALL_PERMISSIONS.CAN_ACCESS_SITES_PAGE}
    component={(props: JSX.IntrinsicAttributes) => <Sites {...props} />}
  />
);
export default SitesRoute;
