import { gql } from '@apollo/client';

export const CREATE_RECOMMENDATION = gql`
  mutation createRecommendation($data: CreateRecommendationInput!) {
    createRecommendation(data: $data) {
      id
      title
      description
      details
      disabled
    }
  }
`;
