import { useRef, useState, useEffect, useCallback } from 'react';

import classNames from 'classnames';
import { useHistory } from 'react-router';
import { useLazyQuery } from '@apollo/client';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Paper, Typography, TextField } from '@material-ui/core';

import { styles } from './styles';
import Loader from '../../../../sharedComponents/Loader';
import { PATH_NAME } from '../../../../routes/pathNames';
import { ClearIcon, SearchIcon } from '../../../../assets/icons';
import { GLOBAL_NAVIGATION_PATIENTS_SEARCH } from '../../../../graphQL/queries';

const HeaderSearchInput = () => {
  const classes = styles();
  const history = useHistory();
  const searchRef = useRef<HTMLElement>(null);
  const [searchValue, setSearchValue] = useState('');
  const [searchResults, setSearchResults] = useState<any[]>([]);

  const [searchPatients, { data, called, loading }] = useLazyQuery(
    GLOBAL_NAVIGATION_PATIENTS_SEARCH,
    {
      fetchPolicy: 'no-cache',
      onCompleted: () => {
        setSearchResults(data?.globalNavigationPatientsSearch?.data);
      },
      onError: () => {
        setSearchResults([]);
      }
    }
  );

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchValue?.length > 1) {
        searchPatients({
          variables: {
            data: {
              searchString: searchValue,
              limit: 10
            }
          }
        });
      }
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [searchValue]);

  const handleClearTextValue = useCallback(() => {
    setSearchValue('');
  }, []);

  const handleClickSearchResult = ({ id, type, assessmentId, status }) => {
    if (type === 'seeMore') {
      history.push(PATH_NAME.PEOPLE, {
        searchValue,
        searchResults,
        count: data?.globalNavigationPatientsSearch?.count
      });
    } else if (searchValue === assessmentId) {
      if (status === 'DRAFT') {
        history.push(
          PATH_NAME.ASSESSMENT.replace(':id', id).replace(
            ':assessmentId',
            assessmentId
          )
        );
      } else {
        history.push(`${PATH_NAME.PATIENT.replace(':id', id)}?tabIndex=2`);
      }
    } else {
      history.push(PATH_NAME.PATIENT.replace(':id', id));
    }
  };

  useEffect(() => {
    const clearRef = searchRef?.current;
    if (clearRef !== null) {
      const close = clearRef.getElementsByClassName(
        'MuiAutocomplete-clearIndicator'
      )[0];
      if (close !== null) {
        close.addEventListener('click', () => handleClearTextValue());
      }
      return () => {
        if (close !== null) {
          close.removeEventListener('click', () => handleClearTextValue());
        }
      };
    }
  }, [searchRef, handleClearTextValue]);

  const navigateToPeoplePage = (e, searchButton?: boolean) => {
    if (called && !loading)
      if (e.keyCode === 13 || searchButton) {
        history.push({
          pathname: PATH_NAME.PEOPLE,
          state: {
            searchValue,
            searchResults,
            count: data?.globalNavigationPatientsSearch?.count
          }
        });
      }
  };

  return (
    <>
      <SearchIcon
        className={classes.searchIcon}
        onClick={e => navigateToPeoplePage(e, true)}
      />
      <Autocomplete
        freeSolo
        ref={searchRef}
        inputValue={searchValue}
        getOptionLabel={option => `${option?.firstName} ${option?.lastName}`}
        classes={{
          popper: classes.searchPopper,
          inputRoot: classes.searchFilter,
          endAdornment: classes.searchEndAdornment
        }}
        closeIcon={
          searchValue?.length > 0 ? (
            <ClearIcon className={classes.clearIcon} />
          ) : null
        }
        options={
          searchValue?.length > 1 && searchResults?.length > 0 && !loading
            ? searchResults
            : []
        }
        PaperComponent={({ children }: any) => (
          <Paper
            className={classNames(classes.optionsPaper, {
              [classes.seeMoreOptionsPaper]:
                searchValue?.length > 1 &&
                data?.globalNavigationPatientsSearch?.count > 10
            })}
          >
            {loading ? (
              <div className={classes.loadingPaper}>
                <Loader />
              </div>
            ) : (
              children
            )}
          </Paper>
        )}
        filterOptions={options => {
          const result = options.reduce((acc, currentOption) => {
            const existingOption = acc.find(
              option =>
                option.firstName === currentOption.firstName &&
                option.id === currentOption.id
            );

            if (!existingOption) {
              acc.push(currentOption);
            }

            return acc;
          }, []);

          if (
            searchValue?.length > 1 &&
            data?.globalNavigationPatientsSearch?.count > 10
          ) {
            result.push({
              firstName: 'See',
              lastName: 'More',
              type: 'seeMore'
            } as unknown as string);
          }

          return result;
        }}
        renderOption={({
          id,
          firstName,
          lastName,
          assessmentId,
          type,
          status
        }) => {
          const displayText =
            type === 'seeMore' ? (
              'See More'
            ) : (
              <>
                <div>{`${firstName} ${lastName}`}</div>
                {searchValue === assessmentId && (
                  <div
                    className={classes.newlineassessment}
                  >{`${assessmentId}`}</div>
                )}
              </>
            );

          return (
            <Typography
              className={classNames(classes.searchOptions, {
                [classes.seeMoreOption]: type === 'seeMore'
              })}
              onClick={() =>
                handleClickSearchResult({ id, type, assessmentId, status })
              }
            >
              {displayText}
            </Typography>
          );
        }}
        renderInput={(params: any) => (
          <TextField
            {...params}
            variant="filled"
            placeholder="Search Patients"
            className={classes.searchText}
            onKeyDown={navigateToPeoplePage}
            onChange={e => setSearchValue(e.target.value)}
            InputProps={{
              ...params.InputProps,
              disableUnderline: true
            }}
          />
        )}
      />
    </>
  );
};

export default HeaderSearchInput;
