export const mapAlertsToViewModel = alerts => [
  ...alerts?.criterionAlerts?.map(alert => ({
    ...alert,
    isDuplicate: false,
    id: alert?.alertId,
    history: alert.history.map(history => ({
      id: history?.historyId,
      ...history
    }))
  })),
  ...alerts?.medispanAlerts?.map(alert => ({
    ...alert,
    isDuplicate: true,
    id: alert?.medispanAlertId,
    criterion: {
      title: `${alert?.drugs?.map(drug => drug?.name)?.join('-')} Alert`,
      description: alert?.description
    },
    history: alert.history.map(history => ({
      id: history?.historyId,
      ...history
    })),
    recommendations: alert?.messages?.map((message, index) => ({
      id: index,
      description: message?.description
    }))
  }))
];
