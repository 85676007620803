import { Route as PublicRoute } from 'react-router-dom';

import { PATH_NAME } from '../pathNames';
import { CreateNewPassword } from '../lazyLoaded';

const CreateNewPasswordRoute = () => (
  <PublicRoute
    exact
    path={PATH_NAME.CREATE_NEW_PASSWORD}
    component={(props: JSX.IntrinsicAttributes) => (
      <CreateNewPassword {...props} />
    )}
  />
);
export default CreateNewPasswordRoute;
