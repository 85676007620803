import { makeStyles, createStyles, Theme } from '@material-ui/core';
import { customThemeModel } from '../../../models';

export const styles = makeStyles((theme: Theme & customThemeModel) =>
  createStyles({
    appBar: {
      height: theme.lengths.headerHeight,
      backgroundColor: theme.colors.header,
      zIndex: 100
    },
    toolbar: {
      minHeight: theme.lengths.headerHeight,
      margin: 0,
      padding: '0 10px 0 14px',
      backgroundColor: theme.colors.header
    },
    homemedsLogo: {
      marginBottom: -5,
      cursor: 'pointer'
    },
    checkmarkIcon: {
      marginLeft: 19
    },
    profileWrapper: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      height: '100%',
      marginLeft: 'auto'
    },
    notificationsIcon: {
      marginRight: 20,
      cursor: 'pointer'
    }
  })
);
