import { Link } from 'react-router-dom';
import { AppBar, Toolbar } from '@material-ui/core';

import Profile from './profile';
import { styles } from './styles';
import HeaderNav from './headerNav';
import SitesDropdown from './sitesDropdown';
import HeaderSearchInput from './headerSearchInput';
import { PATH_NAME } from '../../../routes/pathNames';
import { headerLogoSm } from '../../../assets/images';
import { NotificationsIcon } from '../../../assets/icons';


interface IHeaderRepresentation {
  fetchSiteId:( siteId:any) => void;
}
const HeaderRepresentation = ({fetchSiteId}:IHeaderRepresentation) => {
  const classes = styles();

  return (
    <AppBar position="fixed" className={classes.appBar} elevation={0}>
      <Toolbar className={classes.toolbar}>
        <Link to={PATH_NAME.DASHBOARD}>
          <img
            src={headerLogoSm}
            alt=""
            style={{ width: 99, height: 38 }}
            className={classes.homemedsLogo}
          />
        </Link>

        <SitesDropdown fetchSiteId={fetchSiteId}/>

        <HeaderNav />

        <div className={classes.profileWrapper}>
          <HeaderSearchInput />

          <NotificationsIcon className={classes.notificationsIcon} />

          <Profile />
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default HeaderRepresentation;
