import { createTheme } from '@material-ui/core';

export default createTheme({
  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: { overflow: 'hidden' },
        '*::-ms-scrollbar': {
          width: 8
        },
        '*::-webkit-scrollbar': {
          width: 8
        },
        '*::-ms-scrollbar-track': {
          margin: '8px 0'
        },
        '*::-webkit-scrollbar-track': {
          margin: '8px 0'
        },
        '*::-ms-scrollbar-thumb': {
          borderRadius: 12,
          background: '#C1C9D2'
        },
        '*::-webkit-scrollbar-thumb': {
          borderRadius: 12,
          background: '#C1C9D2'
        },
        '*::-ms-scrollbar-thumb:hover': {
          background: '#AAA'
        },
        '*::-webkit-scrollbar-thumb:hover': {
          background: '#AAA'
        }
      }
    }
  }
});
