import { GridSortModel } from '@material-ui/data-grid';

export const prepareDashboardSearch = (
  searchValues,
  sortModel: GridSortModel
) => {
  const { staffOwner, pharmacistOwner, assessmentStatus } = searchValues;
  let newSearchValues = {
    limit: 10,
    staffId: staffOwner === 'All' ? '' : staffOwner,
    pharmacistId: pharmacistOwner === 'All' ? '' : pharmacistOwner,
    assessmentStatus: assessmentStatus === 'All' ? '' : assessmentStatus
  };

  if (sortModel.length > 0) {
    const sortState = {
      order: sortModel[0]?.sort?.toUpperCase(),
      orderBy: ''
    };

    switch (sortModel[0]?.field) {
      case 'name':
        sortState.orderBy = 'PATIENT_NAME';
        break;
      case 'assessmentStatus':
        sortState.orderBy = 'ASSESSMENT_STATUS';
        break;
      case 'alertStatus':
        sortState.orderBy = 'ALERTS_COUNT';
        break;
      case 'pharmacist':
        sortState.orderBy = 'PRIMARY_PHARMACIST';
        break;
      case 'secondaryPharmacist':
        sortState.orderBy = 'SECONDARY_PHARMACIST';
        break;
      case 'publishedAt':
        sortState.orderBy = 'PUBLISHED_AT';
        break;
      case 'staff':
      case 'phoneNumber':
      case 'address':
      case 'email':
        sortState.orderBy = sortModel[0]?.field?.toUpperCase();
        break;
      default:
        sortState.order = '';
        sortState.orderBy = '';
    }

    newSearchValues = { ...newSearchValues, ...sortState };
  }

  const mappedData = Object.fromEntries(
    Object.entries(newSearchValues).filter(([_, v]) => v != null && v !== '')
  );

  return mappedData;
};
