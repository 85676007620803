import { Route as PublicRoute } from 'react-router-dom';

import { PATH_NAME } from '../pathNames';
import { Race } from '../lazyLoaded';

const RaceRoute = () => (
  <PublicRoute
    exact
    path={PATH_NAME.RACE}
    component={(props: JSX.IntrinsicAttributes) => <Race {...props} />}
  />
);
export default RaceRoute;
