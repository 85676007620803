import { Route as PublicRoute } from 'react-router-dom';

import { People } from '../lazyLoaded';
import { PATH_NAME } from '../pathNames';

const PeopleRoute = () => (
  <PublicRoute
    exact
    path={PATH_NAME.PEOPLE}
    component={(props: JSX.IntrinsicAttributes) => <People {...props} />}
  />
);
export default PeopleRoute;
