import { init } from '@sentry/react';
import { Integrations } from '@sentry/tracing';

export const initSentry = () => {
  init({
    tracesSampleRate: 1.0,
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()]
  });
};
