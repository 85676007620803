import classNames from 'classnames';
import { Tooltip } from '@material-ui/core';

import { styles } from './styles';
import Heading from '../../../../sharedComponents/heading';
import Dropdown from '../../../../sharedComponents/dropdown';
import DatePicker from '../../../../sharedComponents/datePicker';
import CustomInput from '../../../../sharedComponents/customInput';
import { CheckmarkIcon, InfoIcon } from '../../../../assets/icons';
import { GENDER, PERSONAL_INFO, ROLES } from '../../../../utils/constants';
import DropdownItem from '../../../../sharedComponents/dropdownItem';

interface IPersonalInfo {
  userRole: string;
  watch: any;
  errors: any;
  handleRegister: any;
  handleDateChange: (name: string, date: any) => void;
  handleDropdown: (e: React.ChangeEvent, dropdownName: any) => void;
}

const PersonalInfo = ({
  userRole,
  watch,
  errors,
  handleRegister,
  handleDropdown,
  handleDateChange
}: IPersonalInfo) => {
  const classes = styles();

  return (
    <div>
      <Heading name="Personal Info" />
      <div className={classes.inputsContainer}>
        {PERSONAL_INFO.map(({ id, name, label, readOnly }) => (
          <CustomInput
            disabled={userRole === ROLES.ANALYST}
            key={id}
            name={name}
            label={label}
            value={watch(name)}
            readOnly={readOnly}
            {...handleRegister(name)}
            styleGreyBorder={readOnly}
            errorMsg={errors[name]?.message}
            customInputClass={classes.customInput}
          />
        ))}
        <CustomInput
          disabled={userRole === ROLES.ANALYST}
          labelComponent={
            <div className={classNames(classes.label, classes.customLabel)}>
              Optional Patient Site ID
              <Tooltip title="Optional Patient Site ID">
                <InfoIcon />
              </Tooltip>
            </div>
          }
          name="optionalPatientSiteId"
          value={watch('optionalPatientSiteId')}
          customInputClass={classes.customInput}
          {...handleRegister('optionalPatientSiteId')}
          errorMsg={errors.optionalPatientSiteId?.message}
        />
        <div className={classes.datePickerContainer}>
          <span className={classes.label}>Date of Birth*</span>
          <DatePicker
            disabled={userRole === ROLES.ANALYST}
            name="date"
            value={watch('date') || null}
            errorMsg={errors?.date?.message}
            handleDateChange={handleDateChange}
          />
        </div>
        <Dropdown
          disabled={userRole === ROLES.ANALYST}
          multiple
          label="Gender*"
          selectedItem={watch('gender')}
          errorMsg={errors?.gender?.message}
          customSelectClass={classes.customInput}
          handleItems={e => handleDropdown(e, 'gender')}
        >
          {GENDER.map(option => (
            <DropdownItem key={option?.id} value={option?.value}>
              {option?.name}
              {watch('gender') === option?.value && (
                <CheckmarkIcon className={classes.checkmarkIcon} />
              )}
            </DropdownItem>
          ))}
        </Dropdown>
        <Dropdown
          disabled={userRole === ROLES.ANALYST}
          multiple
          label="Status"
          selectedItem={watch('status')}
          errorMsg={errors?.status?.message}
          customSelectClass={classes.customInput}
          handleItems={e => handleDropdown(e, 'status')}
        >
          <DropdownItem value="active">Active</DropdownItem>
          <DropdownItem value="inactive">Inactive</DropdownItem>
        </Dropdown>
      </div>
    </div>
  );
};

export default PersonalInfo;
