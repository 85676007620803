import { gql } from '@apollo/client';

export const GET_ALERTS_GENERATED_REPORT = gql`
  query getAlertsGeneratedReport($data: StandardReportInput!) {
    getAlertsGeneratedReport(data: $data) {
      totalPatients
      criterion {
        id
        title
        alias
        count
      }
    }
  }
`;
