import { Utils as QbUtils } from 'react-awesome-query-builder';
import { revertDateIgnoreTimezone } from '../../helpers';

// Value type is used to determine the type of the value field (Required by the react-awesome-query-builder)
const getValueType = fieldName => {
  switch (fieldName) {
    case 'name':
      return 'text';
    case 'age':
    case 'howManyFalls':
    case 'drinksPerDay':
    case 'bpLyingDownMin':
    case 'bpLyingDownMax':
    case 'bpLyingDownPulse':
    case 'bpSittingMin':
    case 'bpSittingMax':
    case 'bpSittingPulse':
    case 'bpStandingMin':
    case 'bpStandingMax':
    case 'bpStandingPulse':
      return 'number';
    case 'bpLyingDownDateTime':
    case 'bpSittingDateTime':
    case 'bpStandingDateTime':
      return 'datetime';
    case 'fallInPastThreeMonths':
    case 'dizzyGettingUp':
    case 'isConfused':
    case 'isHospitalized':
      return 'boolean';
    case 'drinkDays':
    case 'gender':
    case 'pain':
    case 'allConditions':
    case 'allAllergies':
      return 'select';
    case 'dateOfBirth':
      return 'date';
    default:
      return '';
  }
};

const getOperator = (operator, fieldName) => {
  switch (operator) {
    case 'EQUAL':
      if (
        fieldName === 'gender' ||
        fieldName === 'pain' ||
        fieldName === 'allConditions' ||
        fieldName === 'allAllergies' ||
        fieldName === 'drinkDays'
      )
        return 'select_equals';
      return 'equal';
    case 'NOT_EQUAL':
      if (
        fieldName === 'gender' ||
        fieldName === 'pain' ||
        fieldName === 'allConditions' ||
        fieldName === 'allAllergies' ||
        fieldName === 'drinkDays'
      )
        return 'select_not_equals';
      return 'not_equal';
    case 'GREATER_THAN':
      return 'greater';
    case 'GREATER_THAN_OR_EQUAL':
      return 'greater_or_equal';
    case 'LESS_THAN':
      return 'less';
    case 'LESS_THAN_OR_EQUAL':
      return 'less_or_equal';
    default:
      return 'equal';
  }
};

const getField = field => {
  switch (field) {
    case 'AGE':
      return 'age';
    case 'GENDER':
      return 'gender';
    case 'DATE_OF_BIRTH':
      return 'dateOfBirth';
    case 'FALL_IN_PAST3_MONTHS':
      return 'fallInPastThreeMonths';
    case 'NUMBER_OF_FALLS':
      return 'howManyFalls';
    case 'DIZZY':
      return 'dizzyGettingUp';
    case 'CONFUSION':
      return 'isConfused';
    case 'HOSPITALIZED':
      return 'isHospitalized';
    case 'AMOUNT_OF_PAIN_EXPERIENCED':
      return 'pain';
    case 'DAYS_OF_ALCOHOL_USAGE_PER_WEEK':
      return 'drinkDays';
    case 'NUMBER_OF_DRINKS_PER_DAY':
      return 'drinksPerDay';
    case 'BP_LYING_NUM':
      return 'bpLyingDownMin';
    case 'BP_LYING_DENOM':
      return 'bpLyingDownMax';
    case 'LYING_PULSE':
      return 'bpLyingDownPulse';
    case 'LYING_PRESSURE_CAPTURED_AT':
      return 'bpLyingDownDateTime';
    case 'BP_SITTING_NUM':
      return 'bpSittingMin';
    case 'BP_SITTING_DENOM':
      return 'bpSittingMax';
    case 'SITTING_PULSE':
      return 'bpSittingPulse';
    case 'SITTING_PRESSURE_CAPTURED_AT':
      return 'bpSittingDateTime';
    case 'BP_STANDING_NUM':
      return 'bpStandingMin';
    case 'BP_STANDING_DENOM':
      return 'bpStandingMax';
    case 'STANDING_PULSE':
      return 'bpStandingPulse';
    case 'STANDING_PRESSURE_CAPTURED_AT':
      return 'bpStandingDateTime';
    default:
      return '';
  }
};

// Return the child rule object based on its type (FORMULA, DRUG_NAME or DRUG_CLASS)
const getSingleRule = rule => {
  // Case of conditions
  if (rule.type === 'CONDITIONS') {
    return {
      type: 'rule',
      properties: {
        field: 'allConditions',
        operator: rule?.includes ? 'select_equals' : 'select_not_equals',
        value: rule.value,
        valueSrc: ['value'],
        valueType: ['select']
      }
    };
  }
  // Case of allergies
  if (rule.type === 'ALLERGIES') {
    return {
      type: 'rule',
      properties: {
        field: 'allAllergies',
        operator: rule?.includes ? 'select_equals' : 'select_not_equals',
        value: rule.value,
        valueSrc: ['value'],
        valueType: ['select']
      }
    };
  }
  // Case of drug class
  if (rule.type === 'DRUG_CLASS') {
    return {
      type: 'rule',
      properties: {
        field: 'drugClass',
        operator: rule?.includes ? 'select_equals' : 'select_not_equals',
        value: [rule.drugValue[0]?.name || ''],
        valueSrc: ['value'],
        valueType: ['select']
      }
    };
  }
  // Case of GPI
  if (rule.type === 'GPI') {
    return {
      type: 'rule',
      properties: {
        field: 'gpi',
        operator: rule?.includes ? 'select_equals' : 'select_not_equals',
        value: [
          `${rule.drugValue[0]?.id.replace(/-/g, '')} - ${
            rule.drugValue[0]?.name
          }` || ''
        ],
        valueSrc: ['value'],
        valueType: ['select']
      }
    };
  }
  // Case of drug name
  if (rule.type === 'DRUG_NAME') {
    localStorage.setItem(
      'drugNames',
      JSON.stringify([
        ...JSON.parse(localStorage.getItem('drugNames') || '[]'),
        ...rule?.drugValue?.map(drug => ({
          name: drug?.name,
          dispensableGenericProduct: { value: drug?.id }
        }))
      ])
    );
    return {
      type: 'rule',
      properties: {
        field: 'drugName',
        operator: rule?.includes
          ? 'multiselect_equals'
          : 'multiselect_not_equals',
        value: [rule?.drugValue?.map(drug => drug?.name) || null],
        valueSrc: ['value'],
        valueType: ['multiselect'],
        asyncListValues: rule.drugValue.map(item => ({
          title: item?.name,
          value: item?.name
        }))
      }
    };
  }
  // Case of normal rule
  return {
    type: 'rule',
    properties: {
      field: getField(rule.field),
      operator: getOperator(rule.comparator, getField(rule.field)),
      value: [
        rule.field?.endsWith('_CAPTURED_AT')
          ? revertDateIgnoreTimezone(new Date(rule?.fieldValue))?.toString()
          : rule?.fieldValue
      ],
      valueSrc: ['value'],
      valueType: [getValueType(getField(rule.field))]
    }
  };
};

const getSingleGroup = groupArray => {
  const group: any = { type: 'group', children1: {} };
  group.properties = {
    conjunction: groupArray[1]?.operator,
    not: false
  };
  groupArray
    // Rules are in the even indices of the array
    ?.filter((_, index) => index % 2 === 0)
    ?.map(item => {
      group.children1 = {
        ...group.children1,
        [QbUtils.uuid()]: Array.isArray(item)
          ? getSingleGroup(item)
          : getSingleRule(item)
      };
    });

  return group;
};

const convertGroupToArrayOfRules = group => {
  let groupArray: any = [];
  let bracketsCounter = 0;
  let hasNestedGroups = false;
  let startIndex = 0;
  const parenthesesIndices: any = [];

  // Detect groups indices
  // Check if array has nested groups
  group.map((item, index) => {
    if (item?.operator === 'OPENED_BRACKET') {
      bracketsCounter += 1;
      if (bracketsCounter === 1) startIndex = index;
      else if (bracketsCounter === 2) hasNestedGroups = true;
      if (bracketsCounter === 2) hasNestedGroups = true;
    } else if (item?.operator === 'CLOSED_BRACKET') {
      bracketsCounter -= 1;
      if (bracketsCounter === 0)
        parenthesesIndices.push({ startIndex, endIndex: index });
    }
  });

  parenthesesIndices.map(({ startIndex, endIndex }, index) => {
    groupArray.push(
      ...group.slice(
        parenthesesIndices[index - 1]?.endIndex + 1 || 0,
        startIndex
      )
    );
    groupArray.push(group.slice(startIndex + 1, endIndex));
    if (index === parenthesesIndices.length - 1)
      groupArray.push(...group.slice(endIndex + 1));
  });

  // Case of nested groups
  if (hasNestedGroups) {
    groupArray = groupArray.map(item => {
      if (Array.isArray(item)) return convertGroupToArrayOfRules(item);
      return item;
    });
  }

  return groupArray;
};

/* Cases to handle:
      Array has no rules
      Array has one rule
      Array has multiple rules (Without groups) >
          - mainOperator is in the second index of the array
          - rules are in the even indices of the array
      Array has groups (Not nested) > Convert group to an array of rules
      Array has nested groups > Recursion
  */

export const prepareGetFormula = formula => {
  let array: any = [...formula];

  // Initialize tree
  const tree: any = { id: QbUtils.uuid(), type: 'group' };

  // Initialize the tree children object as array is not empty
  if (formula.length > 0) tree.children1 = {};

  // Case of array has only one value (FORMULA, DRUG_NAME or DRUG_CLASS)
  if (formula.length === 1)
    tree.children1 = { [QbUtils.uuid()]: getSingleRule(formula[0]) };
  // Case of array has multiple rules
  else if (formula.length > 1) {
    // Check if the formula array has groups
    const hasGroups = formula.some(item => item?.operator === 'OPENED_BRACKET');

    // Case of array has groups
    if (hasGroups) array = convertGroupToArrayOfRules(array);

    // Case of array has/hasn't groups (List of rules with one parent operator (AND/OR))
    // Operator is in the odd indices of the array, (They are repeated between rules, so we need to skip every other odd index)
    // Array rules are considered goupgs
    tree.properties = { conjunction: array[1]?.operator, not: false };
    array
      // Rules are in the even indices of the array
      ?.filter((_, index) => index % 2 === 0)
      ?.map(item => {
        tree.children1 = {
          ...tree.children1,
          [QbUtils.uuid()]: Array.isArray(item)
            ? getSingleGroup(item)
            : getSingleRule(item)
        };
      });
  }

  return tree;
};
