import { gql } from '@apollo/client';

export const GET_ALERTS_GENERATED_TABLE = gql`
  query getAlertsGeneratedTable($data: AlertsGeneratedTableInput!) {
    getAlertsGeneratedTable(data: $data) {
      data {
        patientId
        patientName
        age
        lastGeneratedDate
      }
      count
    }
  }
`;
