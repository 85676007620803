import { Route as PublicRoute } from 'react-router-dom';

import { PATH_NAME } from '../pathNames';
import { Ethnicity } from '../lazyLoaded';

const EthnicityRoute = () => (
  <PublicRoute
    exact
    path={PATH_NAME.ETHNICITY}
    component={(props: JSX.IntrinsicAttributes) => <Ethnicity {...props} />}
  />
);
export default EthnicityRoute;
