import { generateHexString } from '../../helpers';

export const mapDuplicateDrugsChartInputs = (startDate, endDate) => ({
  createdFrom: startDate,
  createdTo: endDate
});

export const mapDuplicateDrugsTableInputs = (
  createdFrom,
  createdTo,
  drugsJSON,
  sortModel
) => {
  let searchFields = {
    limit: 10,
    createdTo,
    createdFrom,
    drugsJSON
  };

  if (sortModel.length > 0) {
    const sortModelMapper: { [key: string]: string } = {
      patientName: 'PATIENT_NAME'
    };

    const sortState = {
      order: sortModel[0]?.sort?.toUpperCase() || '',
      orderBy: sortModelMapper[sortModel[0]?.field] || ''
    };

    searchFields = { ...searchFields, ...sortState };
  }

  const mappedData = Object.fromEntries(
    Object.entries(searchFields).filter(([_, v]) => v != null && v !== '')
  );

  return mappedData;
};

export const mapDuplicateDrugsOutputsToChartData = (siteId, results?) =>
  results?.drugs?.map(drug => ({
    name: drug.drugs,
    drugsCount: drug?.count ?? 0,
    color: `#${generateHexString(6)}`
  }));

export const mapDuplicateDrugsOutputsToTable = (patients, drugs) =>
  patients.map(patient => ({
    ...patient,
    id: patient?.patientId,
    drugs: parsingDrugsData(drugs)
  }));

export const parsingDrugsData = drugs => {
  const parsedValue =
    JSON.parse(drugs).reduce(
      (acc, curr) => `${acc}${acc ? ', ' : ''}${curr.name}`,
      ''
    ) ?? '';
  return parsedValue;
};
