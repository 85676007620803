import { gql } from '@apollo/client';

export const GET_INCIDENTS_SYMPTOMS_REPORT = gql`
  query getIncidentsSymptomsReport($data: StandardReportInput!) {
    getIncidentsSymptomsReport(data: $data) {
      totalPatients
      reportedFall
      reportedDizziness
      reportedConfusion
      drinksPerWeek
      drinksPerDay
      hospitalizedEr
    }
  }
`;
