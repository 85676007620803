export const mapPharmaciesToTable = (
  unmappedPharmacies,
  filters,
  linkedPharmacies?
) =>
  unmappedPharmacies
    ?.map(pharmacy => {
      const pharmacyName =
        pharmacy?.basic?.organization_name ||
        pharmacy?.basic?.name ||
        pharmacy.other_names?.find(pharm =>
          pharm.organization_name
            ?.toLowerCase()
            ?.includes(filters.name?.toLowerCase()?.trim())
        )?.organization_name ||
        pharmacy.other_names?.find(pharm =>
          pharm.name
            ?.toLowerCase()
            ?.includes(filters.name?.toLowerCase()?.trim())
        )?.name;

      const pharmacyAddress =
        pharmacy?.addresses?.find(
          pharm =>
            pharm.city
              ?.toLowerCase()
              ?.includes(filters.city?.toLowerCase()?.trim()) &&
            pharm.state
              ?.toLowerCase()
              ?.includes(filters.state?.toLowerCase()?.trim()) &&
            pharm.postal_code
              ?.toLowerCase()
              ?.includes(filters.zip?.toLowerCase()?.trim())
        ) ||
        pharmacy?.practiceLocations?.find(
          pharm =>
            pharm.city
              ?.toLowerCase()
              ?.includes(filters.city?.toLowerCase()?.trim()) &&
            pharm.state
              ?.toLowerCase()
              ?.includes(filters.state?.toLowerCase()?.trim()) &&
            pharm.postal_code
              ?.toLowerCase()
              ?.includes(filters.zip?.toLowerCase()?.trim())
        ) ||
        pharmacy?.endpoints?.find(
          pharm =>
            pharm.city
              ?.toLowerCase()
              ?.includes(filters.city?.toLowerCase()?.trim()) &&
            pharm.state
              ?.toLowerCase()
              ?.includes(filters.state?.toLowerCase()?.trim()) &&
            pharm.postal_code
              ?.toLowerCase()
              ?.includes(filters.zip?.toLowerCase()?.trim())
        );

      return {
        id: pharmacy?.number,
        name: pharmacyName,
        npi: pharmacy?.number,
        address: pharmacyAddress?.address_1 ?? '',
        phone: pharmacyAddress?.telephone_number ?? '',
        fax: pharmacyAddress?.fax_number ?? '',
        zip: pharmacyAddress?.postal_code ?? '',
        state: pharmacyAddress?.state ?? '',
        city: Number.isNaN(parseInt(pharmacyAddress?.city, 10))
          ? pharmacyAddress?.city
          : '',
        linkedStatus: linkedPharmacies?.includes(pharmacy?.number?.toString())
      };
    })
    ?.filter(pharmacy => pharmacy?.address);

export const mapPatientPharmaciesToTable = unmappedPharmacies =>
  unmappedPharmacies?.map(pharmacy => ({
    ...pharmacy,
    id: pharmacy?.npi,
    name: pharmacy?.name
  }));
