import { gql } from '@apollo/client';

export const GET_PATIENT_STAFF = gql`
  query getPatientById($data: PatientIdField!) {
    getPatientById(data: $data) {
      primaryStaffId
      assignedUsers {
        id
      }
    }
  }
`;
