import { useState } from 'react';

import { useHistory } from 'react-router';

import CustomModal from '../customModal';
import ModalConfirmBody from '../modalConfirmBody';

interface IBlockRouting {
  children: any;
  isDirty: boolean;
  isSubmitted: boolean;
}

const BlockRouting = ({ isDirty, children, isSubmitted }: IBlockRouting) => {
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [route, setRoute] = useState<any>(null);
  const [isBlocked, setIsBlocked] = useState(true);

  const handleModalOpen = () => setOpen(true);
  const handleModalClose = () => setOpen(false);

  const handleUnblock = () => {
    setIsBlocked(false);
    handleModalClose();
    unblock();
    if (route) history.push(route);
  };

  const unblock = history.block(prompt => {
    if (route !== prompt.pathname) setRoute(prompt.pathname);
    if (isBlocked && isDirty && !isSubmitted) {
      handleModalOpen();
      return false;
    }
    unblock();
  });

  return (
    <>
      {children}
      <CustomModal
        noCloseIcon
        confirmStyles
        isModalOpen={open}
        modalTitle="Unsaved changes"
        handleModalClose={handleModalClose}
        modalBody={
          <ModalConfirmBody
            prompt="Your changes have not been saved. Are you sure you want to leave
              the page?"
            confirmText="No"
            handleConfirm={handleModalClose}
            cancelText="Yes"
            handleCancel={handleUnblock}
          />
        }
      />
    </>
  );
};

export default BlockRouting;
