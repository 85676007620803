import classNames from 'classnames';
import { useQuery } from '@apollo/client';

import { useEffect, useState } from 'react';
import { styles } from './styles';
import { GET_USER } from '../../graphQL/queries';

interface IProfilePhoto {
  small?: boolean;
  localPhoto?: string;
}

const ProfilePhoto = ({ small, localPhoto }: IProfilePhoto) => {
  const classes = styles();
  const nullPhoto = 'new update photo url';
  const [hasFetched, setHasFetched] = useState(false);
  const [userPhoto, setUserPhoto] = useState<any>();

  const { data: userInfo, stopPolling } = useQuery(GET_USER, {
    fetchPolicy: 'network-only',
    skip: hasFetched, // Skip the query if it has already fetched once
    onCompleted: data => {
      localStorage.setItem('userId', data.getUser.id);
      if (data.getUser.photo && data.getUser.photo !== nullPhoto) {
        setUserPhoto(data.getUser.photo);
        setHasFetched(true); // Prevent further fetching
      }
    }
  });

  const isPhoto = userPhoto && userPhoto !== nullPhoto;

  useEffect(() => {
    if (hasFetched) {
      stopPolling();
    }
  }, [hasFetched, stopPolling]);

  return (
    <div
      className={classNames(
        classes.container,
        { [classes.smallContainer]: small },
        {
          [classes.photoContainer]: isPhoto || localPhoto
        },
        {
          [classes.smallPhotoContainer]: small && (isPhoto || localPhoto)
        }
      )}
    >
      {(localPhoto || isPhoto) && (
        <img
          src={localPhoto || userPhoto}
          className={classNames(classes.photo, { [classes.smallPhoto]: small })}
        />
      )}

      {!localPhoto && !isPhoto && (
        <span>
          {(userInfo?.getUser?.firstName ?? '')?.charAt(0)?.toUpperCase()}
          {(userInfo?.getUser?.lastName ?? '')?.charAt(0)?.toUpperCase()}
        </span>
      )}
    </div>
  );
};

export default ProfilePhoto;
