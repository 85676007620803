import { Route as PublicRoute } from 'react-router-dom';

import { PATH_NAME } from '../pathNames';
import { GenderDistribution } from '../lazyLoaded';

const GenderDistributionRoute = () => (
  <PublicRoute
    exact
    path={PATH_NAME.GENDER_DISTRIBUTION}
    component={(props: JSX.IntrinsicAttributes) => (
      <GenderDistribution {...props} />
    )}
  />
);
export default GenderDistributionRoute;
