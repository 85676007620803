import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { customThemeModel } from '../../models';

export const styles = makeStyles((theme: Theme & customThemeModel) =>
  createStyles({
    dropdownItem: {
      padding: '12px 16px',
      color: theme.colors.text,
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: 14,
      lineHeight: '16px'
    },
    childItem: {
      paddingLeft: 40
    }
  })
);
