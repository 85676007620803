import { Link } from 'react-router-dom';
import { List, ListItem, Typography } from '@material-ui/core';

import { styles } from './styles';
import { FOOTER_NAV_LIST } from '../../../utils/constants';
import { PATH_NAME } from '../../../routes/pathNames';
import { headerLogoSm } from '../../../assets/images';

const FooterRepresentation = () => {
  const classes = styles();

  return (
    <footer className={classes.footer}>
      <div className={classes.copyrightContainer}>
        {/* <Typography className={classes.brandName}>homemeds</Typography> */}
        <div className={classes.footerlogo}>
          <Link to={PATH_NAME.DASHBOARD}>
            <img
              src={headerLogoSm}
              alt=""
              style={{ width: 99, height: 38 }}
              className={classes.homemedsLogo}
            />
          </Link>
        </div>
        <Typography className={classes.copyright}>
          Copyright {new Date().getFullYear()}, Partners in Care Foundation
        </Typography>
      </div>

      <List component="nav" className={classes.footerNav}>
        {FOOTER_NAV_LIST.map(({ id, url, name, target }) => (
          <ListItem key={id} classes={{ root: classes.menuItem }}>
            {target ? (
              <a
                href={url}
                target={target}
                rel="noreferrer"
                className={classes.menuLink}
              >
                {name}
              </a>
            ) : (
              <Link key={id} to={url} className={classes.menuLink}>
                {name}
              </Link>
            )}
          </ListItem>
        ))}
      </List>
    </footer>
  );
};

export default FooterRepresentation;
