import { AppBar, IconButton, Toolbar, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Route as PublicRoute, Link } from 'react-router-dom';
import { ArrowLeftSharp } from '@material-ui/icons';
import { ArrowDownDropdownIcon } from '../../../assets/icons';
import { PATH_NAME } from '../../../routes/pathNames';
import { styles } from './styles';

interface PlainNavBarProps {
  onClose: () => void;
  onLinkClick: () => void;
}

const PlainNavBar: React.FC<PlainNavBarProps> = ({ onClose, onLinkClick }) => {
  const classes = styles();
  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <Typography className={classes.announcementText}></Typography>
        <div className={classes.middleSection}>
          <ArrowLeftSharp />
          <Link
            to={PATH_NAME.BULLETIN}
            className={classes.linkText}
            onClick={onLinkClick}
          >
            Click to view for new bulletin
          </Link>
        </div>
        <IconButton
          edge="end"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

export default PlainNavBar;
