import { validatePhoneNumber } from '../../helpers';

export const mapSiteToViewModel = (site, parentSites) => ({
  ...site,
  siteName: site?.title,
  activeStatus: site?.active ? 'Active' : 'Inactive',
  phoneNumber: validatePhoneNumber(site?.primaryPhone || ''),
  fax: site?.fax || '',
  parentSite:
    parentSites?.find(
      siteObject => siteObject?.title === site?.parentSite?.title
    )?.id || 'N/A'
});
