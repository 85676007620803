import { gql } from '@apollo/client';

export const GET_USER = gql`
  query getUser {
    getUser {
      id
      firstName
      lastName
      email
      photo
      mailSubscription
      mobile
      criteriaAccess
      createdAt
      createdBy
      updatedAt
      updatedBy
      lastLoginTime
    }
  }
`;
