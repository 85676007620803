import AdminRoute from '../adminRoute';
import { PATH_NAME } from '../pathNames';
import { Pharmacies } from '../lazyLoaded';
import { ALL_PERMISSIONS } from '../../utils/constants';

const PharmaciesRoute = () => (
  <AdminRoute
    exact
    path={PATH_NAME.PHARMACIES}
    permission={ALL_PERMISSIONS.NAVIGATE_ADMIN_ROUTES}
    component={(props: JSX.IntrinsicAttributes) => <Pharmacies {...props} />}
  />
);
export default PharmaciesRoute;
