export const mapFormToCriterionData = unmappedData => {
  const mappedData = {
    ...unmappedData,
    disabled: unmappedData.disabled === 'Yes'
  };

  delete mappedData?.__typename;

  return mappedData;
};

export const mapCriterionDataToForm = unmappedData => ({
  ...unmappedData,
  disabled: unmappedData?.disabled ? 'Yes' : 'No'
});
