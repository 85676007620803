import { gql } from '@apollo/client';

export const CREATE_CRITERION = gql`
  mutation createCriterion($data: CriterionInput!) {
    createCriterion(data: $data) {
      id
      title
      alias
      disabled
      description
    }
  }
`;
