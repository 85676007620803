import { gql } from '@apollo/client';

export const GET_ASSESSMENT = gql`
  query getAssessment($data: GetAssessmentInput!) {
    getAssessment(data: $data) {
      id
      patientId
      processingAssessmentId
      assignedTo
      createdBy
      fallInPast3Months
      numberOfFalls
      fallDescription
      fallInjuries
      dizzy
      dizzinessDetails
      confusion
      visitedHospital
      visitedPlaceType
      visitDate
      amountOfPainExperienced
      daysOfAlcoholUsagePerWeek
      numberOfDrinksPerDay
      bpLyingNum
      bpLyingDenom
      lyingPulse
      lyingPressureCapturedAt
      bpSittingNum
      bpSittingDenom
      sittingPulse
      sittingPressureCapturedAt
      bpStandingNum
      bpStandingDenom
      standingPulse
      standingPressureCapturedAt
      patient {
        firstName
        lastName
      }
      allergies
      allergyNote
      conditions
      conditionNote
      status
      publisher {
        firstName
        lastName
        updatedAt
      }
      drugsNote
      drugs {
        id
        name
        route
        asNeeded
        dosage
        dosageMeasurement
        quantity
        quantityMeasurement
        frequency
        direction
        dispenseDate
        firstUseDate
        notes
        providerId
        pharmacyId
        providerName
        pharmacyName
      }
      attachment
    }
  }
`;
