import { gql } from '@apollo/client';

export const GET_PATIENT_PROVIDERS = gql`
  query getPatientById($data: PatientIdField!) {
    getPatientById(data: $data) {
      providers {
        npi
        firstName
        lastName
        active
      }
    }
  }
`;
