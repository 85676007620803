import axios from '../../axios';
import { getDateIgnoreTimezone } from '../../helpers';

const getValue = (fieldName, fieldValue) => {
  switch (fieldName) {
    case 'dateOfBirth':
    case 'bpLyingDownDateTime':
    case 'bpSittingDateTime':
    case 'bpStandingDateTime':
      return getDateIgnoreTimezone(fieldValue);
    default:
      return fieldValue;
  }
};

const getComparator = mongoDBComparator => {
  switch (mongoDBComparator) {
    case '$ne':
      return 'NOT_EQUAL';
    case '$gt':
      return 'GREATER_THAN';
    case '$gte':
      return 'GREATER_THAN_OR_EQUAL';
    case '$lt':
      return 'LESS_THAN';
    case '$lte':
      return 'LESS_THAN_OR_EQUAL';
    case '$eq':
    default:
      return 'EQUAL';
  }
};

const getInverseComparator = mongoDBComparator => {
  switch (mongoDBComparator) {
    case '$eq':
      return 'NOT_EQUAL';
    case '$lt':
      return 'GREATER_THAN';
    case '$lte':
      return 'GREATER_THAN_OR_EQUAL';
    case '$gt':
      return 'LESS_THAN';
    case '$gte':
      return 'LESS_THAN_OR_EQUAL';
    case '$ne':
    default:
      return 'EQUAL';
  }
};

const getField = field => {
  switch (field) {
    case 'age':
      return 'AGE';
    case 'gender':
      return 'GENDER';
    case 'dateOfBirth':
      return 'DATE_OF_BIRTH';
    case 'fallInPastThreeMonths':
      return 'FALL_IN_PAST3_MONTHS';
    case 'howManyFalls':
      return 'NUMBER_OF_FALLS';
    case 'dizzyGettingUp':
      return 'DIZZY';
    case 'isConfused':
      return 'CONFUSION';
    case 'isHospitalized':
      return 'HOSPITALIZED';
    case 'pain':
      return 'AMOUNT_OF_PAIN_EXPERIENCED';
    case 'drinkDays':
      return 'DAYS_OF_ALCOHOL_USAGE_PER_WEEK';
    case 'drinksPerDay':
      return 'NUMBER_OF_DRINKS_PER_DAY';
    case 'bpLyingDownMin':
      return 'BP_LYING_NUM';
    case 'bpLyingDownMax':
      return 'BP_LYING_DENOM';
    case 'bpLyingDownPulse':
      return 'LYING_PULSE';
    case 'bpLyingDownDateTime':
      return 'LYING_PRESSURE_CAPTURED_AT';
    case 'bpSittingMin':
      return 'BP_SITTING_NUM';
    case 'bpSittingMax':
      return 'BP_SITTING_DENOM';
    case 'bpSittingPulse':
      return 'SITTING_PULSE';
    case 'bpSittingDateTime':
      return 'SITTING_PRESSURE_CAPTURED_AT';
    case 'bpStandingMin':
      return 'BP_STANDING_NUM';
    case 'bpStandingMax':
      return 'BP_STANDING_DENOM';
    case 'bpStandingPulse':
      return 'STANDING_PULSE';
    case 'bpStandingDateTime':
      return 'STANDING_PRESSURE_CAPTURED_AT';
    default:
      return '';
  }
};

const getDrugClass = async (drugClassName: string, isIncluded: boolean) => {
  let drugClassId = '';
  await axios
    .post('medispan/medispan/drugclassifications', {
      count: 1,
      criteria: [
        {
          field: 'name',
          operator: 'isEqualTo',
          value: drugClassName
        }
      ]
    })
    .then(res => {
      drugClassId = res?.data?.data?.results[0]?.tc4;
    });

  return {
    type: 'DRUG_CLASS',
    drugValue: [{ name: drugClassName, id: drugClassId }],
    includes: isIncluded
  };
};

const getDrugName = (
  drugs: { id: string; name: string }[],
  isIncluded: boolean
) => ({
  type: 'DRUG_NAME',

  drugValue: drugs?.map(drug => ({
    name: drug?.name,
    id: JSON.parse(localStorage.getItem('drugNames') || '')?.find(
      storedDrug => storedDrug?.name === drug?.name
    )?.dispensableGenericProduct?.value
  })),

  includes: isIncluded
});

const getGpi = (value: String) => {
  const gpi = value.split('-');
  return {
    id: gpi[0]
      .trim()
      .match(/.{1,2}/g)
      ?.join('-'),
    name: gpi.slice(1).join('-').trim()
  };
};

const checkChildrenTree = (
  childrenArray,
  resultArray,
  operator,
  isNoOperator
) => {
  childrenArray?.map((rule, index) => {
    // Check if this is an AND tree -> has no operator
    let ruleArray: any[] = isNoOperator ? rule : Object.entries(rule)[0];

    // Check if this is a nested AND tree -> a child has no operator but multiple rules
    ruleArray =
      Object.keys(rule)?.length > 1 && !isNoOperator
        ? Object.entries(rule)
        : ruleArray;

    // Check if this is a nested value for the rule -> the value would be in an object with the operator
    // Check if this a nested group -> has an operator and a rule array
    // Check if this a nested AND group -> has no operators
    if (
      ruleArray[1] instanceof Object ||
      ruleArray[0] === '$or' ||
      ruleArray[0] === '$and' ||
      Array.isArray(ruleArray[0])
    ) {
      // Fetch the nested value from the value object with its operator
      const nestedValue = Object.entries(ruleArray[1]);

      // Check if this is nested group or a nested AND group
      if (Array.isArray(ruleArray[1]) || Array.isArray(ruleArray[0])) {
        // Check if this a nested AND group
        if (Array.isArray(ruleArray[0])) {
          resultArray.push({ type: 'OPERATION', operator: 'OPENED_BRACKET' });
          checkChildrenTree(ruleArray, resultArray, 'AND', true);
          resultArray.push({ type: 'OPERATION', operator: 'CLOSED_BRACKET' });
          // Check if it's a drug name
        } else if (ruleArray[0] === 'drugName') {
          const drugNameEntries: { id: string; name: string }[] = [];

          ruleArray[1]?.map(drugName => {
            drugNameEntries.push({ id: '', name: drugName });
          });

          resultArray.push({
            type: 'DRUG_NAME',
            drugValue: drugNameEntries,
            includes: true
          });
        } else {
          // Check if this a nested group
          resultArray.push({ type: 'OPERATION', operator: 'OPENED_BRACKET' });
          const nestedOperator = ruleArray[0] === '$or' ? 'OR' : 'AND';
          checkChildrenTree(ruleArray[1], resultArray, nestedOperator, false);
          resultArray.push({ type: 'OPERATION', operator: 'CLOSED_BRACKET' });
        }
      } else if (ruleArray[0] !== 'drugName') {
        if (nestedValue.length > 0) {
          if ((nestedValue[0][0] as unknown as string) === '$not') {
            const embeddedValue = Object.entries(nestedValue[0][1] as object);
            embeddedValue.map((value, i) => {
              resultArray.push({
                type: 'FORMULA',
                comparator: getInverseComparator(value[0]),
                field: getField(ruleArray[0]),
                fieldValue: getValue(ruleArray[0], value[1])
              });
              if (i !== embeddedValue.length - 1)
                resultArray.push({ type: 'OPERATION', operator: 'AND' });
            });
          } else
            nestedValue.map((value, i) => {
              if (ruleArray[0] === 'drugClass') {
                resultArray.push({
                  type: 'DRUG_CLASS',
                  drugValue: [{ id: '', name: value[1] }],
                  includes: value[0] !== '$ne'
                });
              } else if (ruleArray[0] === 'gpi') {
                resultArray.push({
                  type: 'GPI',
                  drugValue: [{ ...getGpi(value[1] as string) }],
                  includes: value[0] !== '$ne'
                });
              } else if (ruleArray[0] === 'allConditions')
                resultArray.push({
                  type: 'CONDITIONS',
                  value: [value[1]],
                  includes: value[0] !== '$ne'
                });
              else if (ruleArray[0] === 'allAllergies')
                resultArray.push({
                  type: 'ALLERGIES',
                  value: [value[1]],
                  includes: value[0] !== '$ne'
                });
              else {
                resultArray.push({
                  type: 'FORMULA',
                  comparator: getComparator(value[0]),
                  field: getField(ruleArray[0]),
                  fieldValue: getValue(ruleArray[0], value[1])
                });
              }
              if (i !== nestedValue.length - 1)
                resultArray.push({ type: 'OPERATION', operator: 'AND' });
            });
        } else {
          resultArray.push({
            type: 'FORMULA',
            comparator: getComparator(ruleArray[1]),
            field: getField(ruleArray[0]),
            fieldValue: getValue(ruleArray[0], ruleArray[1])
          });
        }
        // Case of dateTime -> value is stored in a moment object
      } else {
        const values: any = Object.values(ruleArray[1])[0];
        const drugNameEntries: { id: string; name: string }[] = [];
        let includedOperator = '';
        let drugNames = values;
        if (values instanceof Object) {
          [[includedOperator]] = nestedValue;
          drugNames = Array.isArray(Object.values(values))
            ? Object.values(values)
            : [Object.values(values)];
        }

        drugNames?.map(drugName => {
          drugNameEntries.push({ id: '', name: drugName });
        });

        resultArray.push({
          type: 'DRUG_NAME',
          drugValue: drugNameEntries,
          includes: includedOperator !== '$ne'
        });
      }
      if (index !== childrenArray?.length - 1)
        resultArray.push({ type: 'OPERATION', operator });
    } else {
      // The normal rule array which has a field name and a field value
      if (ruleArray[0] === 'drugClass') {
        resultArray.push({
          type: 'DRUG_CLASS',
          drugValue: [{ id: '', name: ruleArray[1] }],
          includes: true
        });
      } else if (ruleArray[0] === 'gpi') {
        resultArray.push({
          type: 'GPI',
          drugValue: [{ ...getGpi(ruleArray[1] as string) }],
          includes: true
        });
      } else if (ruleArray[0] === 'allConditions')
        resultArray.push({
          type: 'CONDITIONS',
          value: [ruleArray[1]],
          includes: true
        });
      else if (ruleArray[0] === 'allAllergies')
        resultArray.push({
          type: 'ALLERGIES',
          value: [ruleArray[1]],
          includes: true
        });
      else {
        resultArray.push({
          type: 'FORMULA',
          comparator: 'EQUAL',
          field: getField(ruleArray[0]),
          fieldValue: getValue(ruleArray[0], ruleArray[1])
        });
      }
      if (index !== childrenArray?.length - 1)
        resultArray.push({ type: 'OPERATION', operator });
    }
  });
};

export const prepareFormulaSubmit = async mongoDBTree => {
  const formulaArray: any[] = [];
  let parent: any[];

  // Check if it's all AND condition with no operator
  if (Object.keys(mongoDBTree).length !== 1) {
    // No operator --> AND only --> tree is object of AND rules
    parent = Object.entries(mongoDBTree);
    checkChildrenTree(parent, formulaArray, 'AND', true);
  } else {
    [parent] = Object.entries(mongoDBTree);
    if (!Array.isArray(parent[1])) {
      // Case of only one condition
      if (parent[0] === 'drugClass') {
        let includedOperator = '';
        let drugClassName = parent[1];

        if (parent[1] instanceof Object) {
          [includedOperator] = Object.keys(parent[1]);
          [drugClassName] = Object.values(parent[1]);
        }

        formulaArray.push({
          type: 'DRUG_CLASS',
          drugValue: [{ id: '', name: drugClassName }],
          includes: includedOperator !== '$ne'
        });
      } else if (parent[0] === 'gpi') {
        let includedOperator = '';
        let value = parent[1];

        if (parent[1] instanceof Object) {
          [includedOperator] = Object.keys(parent[1]);
          [value] = Object.values(parent[1]);
        }

        formulaArray.push({
          type: 'GPI',
          drugValue: [{ ...getGpi(value as string) }],
          includes: includedOperator !== '$ne'
        });
      } else if (parent[0] !== 'drugName') {
        // Check if comparator is anything rather than EQUAL
        const isNotEqual = parent[1] instanceof Object;
        if (isNotEqual) {
          const nestedValue = Object.entries(parent[1]);
          if (nestedValue.length > 0) {
            if ((nestedValue[0][0] as unknown as string) === '$not') {
              const embeddedValue = Object.entries(nestedValue[0][1] as object);
              embeddedValue.map((value, i) => {
                formulaArray.push({
                  type: 'FORMULA',
                  comparator: getInverseComparator(value[0]),
                  field: getField(parent[0]),
                  fieldValue: getValue(parent[0], value[1])
                });
                if (i !== embeddedValue.length - 1)
                  formulaArray.push({ type: 'OPERATION', operator: 'AND' });
              });
            } else
              nestedValue.map((value, i) => {
                if (parent[0] === 'drugClass')
                  formulaArray.push({
                    type: 'DRUG_CLASS',
                    drugValue: [{ id: '', name: value[1] }],
                    includes: value[0] !== '$ne'
                  });
                else if (parent[0] === 'gpi') {
                  formulaArray.push({
                    type: 'GPI',
                    drugValue: [{ ...getGpi(value[1] as string) }],
                    includes: value[0] !== '$ne'
                  });
                } else if (parent[0] === 'allConditions')
                  formulaArray.push({
                    type: 'CONDITIONS',
                    value: [value[1]],
                    includes: value[0] !== '$ne'
                  });
                else if (parent[0] === 'allAllergies')
                  formulaArray.push({
                    type: 'ALLERGIES',
                    value: [value[1]],
                    includes: value[0] !== '$ne'
                  });
                else {
                  formulaArray.push({
                    type: 'FORMULA',
                    comparator: getComparator(value[0]),
                    field: getField(parent[0]),
                    fieldValue: getValue(parent[0], value[1])
                  });
                }
                if (i !== nestedValue.length - 1)
                  formulaArray.push({ type: 'OPERATION', operator: 'AND' });
              });
          } else {
            formulaArray.push({
              type: 'FORMULA',
              comparator: getComparator(parent[1]),
              field: getField(parent[0]),
              fieldValue: getValue(parent[0], parent[1])
            });
          }
        } else if (parent[0] === 'allConditions')
          formulaArray.push({
            type: 'CONDITIONS',
            value: [parent[1]],
            includes: true
          });
        else if (parent[0] === 'allAllergies')
          formulaArray.push({
            type: 'ALLERGIES',
            value: [parent[1]],
            includes: true
          });
        else
          formulaArray.push({
            type: 'FORMULA',
            field: getField(parent[0]),
            comparator: 'EQUAL',
            fieldValue: getValue(parent[0], parent[1])
          });
      } else if (parent[0] === 'drugName') {
        const drugNameEntries: { id: string; name: string }[] = [];
        let includedOperator = '';
        let drugNames = parent[1];
        if (parent[1] instanceof Object) {
          [includedOperator] = Object.keys(parent[1]);
          [drugNames] = Array.isArray(Object.values(parent[1]))
            ? Object.values(parent[1])
            : [Object.values(parent[1])];
        }

        drugNames?.map(drugName => {
          drugNameEntries.push({ id: '', name: drugName });
        });

        formulaArray.push({
          type: 'DRUG_NAME',
          drugValue: drugNameEntries,
          includes: includedOperator !== '$ne'
        });
      }
    } else if (parent[0] === 'drugName') {
      const drugNameEntries: { id: string; name: string }[] = [];
      let drugNames = parent[1];
      if (parent[1] instanceof Object) {
        drugNames = Array.isArray(Object.values(parent[1]))
          ? Object.values(parent[1])
          : [Object.values(parent[1])];
      }

      drugNames?.map(drugName => {
        drugNameEntries.push({ id: '', name: drugName });
      });

      formulaArray.push({
        type: 'DRUG_NAME',
        drugValue: drugNameEntries,
        includes: true
      });
    } else {
      // Case of OR group or AND with repeated field with same comparator
      const parentOperator = parent[0] === '$or' ? 'OR' : 'AND';
      const children: any = parent[1];
      checkChildrenTree(children, formulaArray, parentOperator, false);
    }
  }

  let refinedFormulaArray: any[] = [];
  await checkForDrugs(formulaArray).then(res => {
    if (Array.isArray(res))
      refinedFormulaArray = res?.map(promise => {
        if (promise?.status === 'fulfilled') return promise?.value;
      });
  });
  return refinedFormulaArray;
};

const checkForDrugs = formulaArray =>
  Promise.allSettled(
    formulaArray?.map(async mappingEntity => {
      if (mappingEntity?.type === 'DRUG_CLASS')
        return getDrugClass(
          mappingEntity?.drugValue[0]?.name,
          mappingEntity?.includes
        );
      if (mappingEntity?.type === 'DRUG_NAME') {
        return getDrugName(mappingEntity?.drugValue, mappingEntity?.includes);
      }

      return mappingEntity;
    })
  );
