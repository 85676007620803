import { Route as PublicRoute } from 'react-router-dom';

import { PATH_NAME } from '../pathNames';
import { MilitaryStatus } from '../lazyLoaded';

const MilitaryStatusRoute = () => (
  <PublicRoute
    exact
    path={PATH_NAME.MILITARY_STATUS}
    component={(props: JSX.IntrinsicAttributes) => (
      <MilitaryStatus {...props} />
    )}
  />
);
export default MilitaryStatusRoute;
