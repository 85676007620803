import { gql } from '@apollo/client';

export const GET_UPLOAD_PRE_SIGNED_URL = gql`
  query getUploadPreSignedUrlForUserAttachment {
    getUploadPreSignedUrlForUserAttachment {
      url
      key
    }
  }
`;
