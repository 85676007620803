import { gql } from '@apollo/client';

export const UNLINK_PHARMACIST_FROM_PATIENT = gql`
  mutation assignPatientToSecondaryPharmacist(
    $data: AssignSecondaryPharmacistInput!
  ) {
    assignPatientToSecondaryPharmacist(data: $data) {
      id
    }
  }
`;
