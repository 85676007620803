import { Dashboard } from '../lazyLoaded';
import AdminRoute from '../adminRoute';
import { PATH_NAME } from '../pathNames';
import { ALL_PERMISSIONS } from '../../utils/constants';

const DashboardRoute = () => (
  <AdminRoute
    exact
    path={PATH_NAME.DASHBOARD}
    permission={ALL_PERMISSIONS.CAN_ACCESS_DASHBOARD}
    component={(props: JSX.IntrinsicAttributes) => <Dashboard {...props} />}
  />
);
export default DashboardRoute;
