export const PATH_NAME = {
  HOME: '/',
  LOGIN: '/',
  OTP: '/otp',
  Faq: '/faq',
  BULLETIN: '/bulletin',
  PRIVACY: '/privacy-policy',
  SITES: '/sites',
  PEOPLE: '/people',
  PATIENT: '/patient/:id',
  CONFIGURE: '/configure',
  DASHBOARD: '/dashboard',
  REPORTING: '/reporting',
  PHARMACIES: '/pharmacies',
  ALERTS: '/alerts/:assessmentId',
  FORGOT_PASSWORD: '/forgot-password',
  MANAGE_CRITERION: '/manage-criterion',
  ACCOUNT_SETTINGS: '/account-settings',
  SECURITY_QUESTIONS: '/security-questions',
  UNDER_CONSTRUCTION: '/under-construction',
  CREATE_NEW_PASSWORD: '/create-new-password',
  PATIENT_REPORT: '/patient/:id/report/:assessmentId',
  ASSESSMENT: '/patient/:id/assessment/:assessmentId',
  COMPREHENSIVE_REPORT: '/patient/:id/comprehensive/:assessmentId',

  USER_QUERY: '/reporting/user-query',
  PATIENT_QUERY: '/reporting/patient-query',
  ASSESSMENT_QUERY: '/reporting/assessment-query',
  USAGE_QUERY: '/reporting/usage-query',
  ACTIVITY_SUMMARY: '/reporting/activity-summary',
  ALERTS_RESOLVED: '/reporting/alerts-resolved',
  ALERTS_GENERATED: '/reporting/alerts-generated',
  TEN_MEDS_OR_MORE: '/reporting/ten-meds-or-more',
  DUPLICATE_DRUGS: '/reporting/duplicate-drugs',
  REPORTED_FALLS: '/reporting/reported-falls',
  AGE_DISTRIBUTION: '/reporting/age-distribution',
  GENDER_DISTRIBUTION: '/reporting/gender-distribution',
  INCIDENTS_SYMPTOMS: '/reporting/incidents-symptoms',
  CONDITIONS: '/reporting/conditions',
  RACE: '/reporting/race',
  ETHNICITY: '/reporting/ethnicity',
  MILITARY_STATUS: '/reporting/military-status',

  MAPPING: '/manage-criterion/:id/mapping',
  RECOMMENDATION: '/manage-criterion/:id/recommendation',

  ACCOUNT: '/account-settings/account',
  SECURITY: '/account-settings/security'
};
