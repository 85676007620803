import { gql } from '@apollo/client';

export const UPDATE_BULLETIN = gql`
  mutation updateBulletin($data: UpdateBulletinInput!) {
    updateBulletin(data: $data) {
      id
      name
      status
      description
      createdAt
      updatedAt
    }
  }
`;
