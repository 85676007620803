import { gql } from '@apollo/client';

export const GET_MILITARY_STATUS_REPORT = gql`
  query getMilitaryStatusReport($data: StandardReportInput!) {
    getMilitaryStatusReport(data: $data) {
      totalPatients
      totalProtectedUsVeteran
      totalActiveReserveMilitaryDuty
      totalOther
      totalNotApplicable
      totalPreferNotToDisclose
    }
  }
`;
