import { gql } from '@apollo/client';

export const GET_DUPLICATE_DRUGS_REPORT = gql`
  query getDuplicateDrugsReport($data: StandardReportInput!) {
    getDuplicateDrugsReport(data: $data) {
      totalPatients
      drugs {
        drugs
        count
      }
    }
  }
`;
