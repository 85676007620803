import { gql } from '@apollo/client';

export const LINK_STAFF_WITH_ASSESSMENT = gql`
  mutation assignAssessmentToStaff($data: assessmentAssignedToInput!) {
    assignAssessmentToStaff(data: $data) {
      id
      patientId
    }
  }
`;
