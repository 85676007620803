import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { customThemeModel } from '../../models';

export const styles = makeStyles((theme: Theme & customThemeModel) =>
  createStyles({
    confirmText: {
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '19px',
      color: `${theme.colors.text}`
    },
    buttonGroup: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      marginTop: '24px',
      fontWeight: 600,
      fontSize: '14px',
      lineHeight: '20px'
    },
    confirmButton: {
      marginRight: '10px'
    }
  })
);
