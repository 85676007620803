export const OPTION = [
  { id: 1, value: 'Active' },
  { id: 2, value: 'Inactive' }
];

export const FAQ_TABS = [
  {
    id: 23,
    name: 'Account | Update Password',
    videoUrl:
      'https://homemeds-faq-videos.s3.amazonaws.com/Homemeds+_+Update+Password+.mp4'
  },
  {
    id: 2,
    name: 'Site Navigation',
    videoUrl:
      'https://homemeds-faq-videos.s3.amazonaws.com/Homemeds+_+Site+Navigation+.mp4'
  },
  {
    id: 4,
    name: 'People Table Overview',
    videoUrl:
      'https://homemeds-faq-videos.s3.amazonaws.com/Homemeds+_+People+Table.mp4'
  },
  {
    id: 7,
    name: 'Patient Profile Overview',
    videoUrl:
      'https://homemeds-faq-videos.s3.amazonaws.com/Homemeds+_+Patient+Profile+Overview.mp4'
  },
  {
    id: 1,
    name: 'Logging In',
    videoUrl:
      'https://homemeds-faq-videos.s3.amazonaws.com/Homemeds+_+Logging+In.mp4'
  },
  {
    id: 6,
    name: 'People Table| Edit User',
    videoUrl:
      'https://homemeds-faq-videos.s3.amazonaws.com/Homemeds+_+Edit+User.mp4'
  },
  {
    id: 3,
    name: 'Dashboard',
    videoUrl:
      'https://homemeds-faq-videos.s3.amazonaws.com/Homemeds+_+Dashboard.mp4'
  },
  {
    id: 13,
    name: 'Assessments | Create Assessment',
    videoUrl:
      'https://homemeds-faq-videos.s3.amazonaws.com/Homemeds+_+Create+Assessment.mp4'
  },
  {
    id: 5,
    name: 'People Table| Assign user to multiple sites',
    videoUrl:
      'https://homemeds-faq-videos.s3.amazonaws.com/Homemeds+_+Assign+user+to+multiple+sites.mp4'
  },
  {
    id: 16,
    name: 'Assessments | Complete Assessment',
    videoUrl:
      'https://homemeds-faq-videos.s3.amazonaws.com/Homemeds+_+Assessments+_+Complete+Assessment+.mp4'
  },
  {
    id: 17,
    name: 'Assessments | Add Drugs and medications',
    videoUrl:
      'https://homemeds-faq-videos.s3.amazonaws.com/Homemeds+_+Assessments+_+Add+Drugs+%2B+Medication+.mp4'
  },
  {
    id: 18,
    name: 'Assessments | Add Conditions',
    videoUrl:
      'https://homemeds-faq-videos.s3.amazonaws.com/Homemeds+_+Assessments+_+Add+Conditions.mp4'
  },
  {
    id: 19,
    name: 'Assessments | Add Allergies',
    videoUrl:
      'https://homemeds-faq-videos.s3.amazonaws.com/Homemeds+_+Assessments+_+Add+Allergies+.mp4'
  },
  {
    id: 20,
    name: 'Assessment Table',
    videoUrl:
      'https://homemeds-faq-videos.s3.amazonaws.com/Homemeds+_+Assessment+Table.mp4'
  },
  {
    id: 15,
    name: 'Assessments | Save as Draft',
    videoUrl:
      'https://homemeds-faq-videos.s3.amazonaws.com/Homemeds+_+Assessment+_+Save+as+draft.mp4'
  },
  {
    id: 14,
    name: 'Assessment | Publish Assessment',
    videoUrl:
      'https://homemeds-faq-videos.s3.amazonaws.com/Homemeds+_+Assessment+_+Publish+Assessment.mp4'
  },
  {
    id: 8,
    name: 'Patient Profile | Add Patient/Add User',
    videoUrl:
      'https://homemeds-faq-videos.s3.amazonaws.com/Homemeds+_+Add+User.mp4'
  },
  {
    id: 9,
    name: 'Patient Profile | Add Staff',
    videoUrl:
      'https://homemeds-faq-videos.s3.amazonaws.com/Homemeds+_+Add+Staff.mp4'
  },
  {
    id: 10,
    name: 'Patient Profile | Add Provider',
    videoUrl:
      'https://homemeds-faq-videos.s3.amazonaws.com/Homemeds+_+Add+Provider.mp4'
  },
  {
    id: 11,
    name: 'Patient Profile | Add Pharmacy',
    videoUrl:
      'https://homemeds-faq-videos.s3.amazonaws.com/Homemeds+_+Add+Pharmacy.mp4'
  },
  {
    id: 22,
    name: 'Account Overview',
    videoUrl:
      'https://homemeds-faq-videos.s3.amazonaws.com/Homemeds+_+Account+Overview.mp4'
  },
  {
    id: 21,
    name: 'Alerts',
    videoUrl:
      'https://homemeds-faq-videos.s3.amazonaws.com/Homemeds+_+Alerts.mp4'
  },
  {
    id: 24,
    name: 'Reporting Overview',
    videoUrl:
      'https://homemeds-faq-videos.s3.amazonaws.com/Homemeds+_+Reporting+Overview.mp4'
  },
  {
    id: 12,
    name: 'Patient Profile | Add Address',
    videoUrl:
      'https://homemeds-faq-videos.s3.amazonaws.com/Homemeds+_+Add+Address.mp4'
  }
];
