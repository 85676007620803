import ReactDOM from 'react-dom';

import DateFnsUtils from '@date-io/date-fns';
import { ApolloProvider } from '@apollo/client';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ThemeProvider, CssBaseline } from '@material-ui/core';

import App from './App';
import './styles/globalStyle.css';
import { initSentry } from './utils/sentry';
import { initAmplify } from './utils/amplify';
import { client } from './utils/apolloClient';
import reportWebVitals from './reportWebVitals';
import defaultTheme from './styles/defaultTheme';
import { customTheme } from './styles/customTheme';

initSentry();
initAmplify();

ReactDOM.render(
  <ThemeProvider theme={{ ...defaultTheme, ...customTheme }}>
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <ApolloProvider client={client}>
        <App />
        <CssBaseline />
      </ApolloProvider>
    </MuiPickersUtilsProvider>
  </ThemeProvider>,
  document.getElementById('root')
);

reportWebVitals();
