import classNames from 'classnames';
import { Button } from '@material-ui/core';

import { styles } from './styles';

interface ICustomButton {
  type?: any;
  children: any;
  onClick?: any;
  form?: string;
  grey?: boolean;
  loading?: boolean;
  component?: any;
  disabled?: boolean;
  secondary?: boolean;
  fullWidth?: boolean;
  customClass?: string;
}

const CustomButton = ({
  form,
  type,
  grey,
  loading,
  onClick,
  children,
  disabled,
  component,
  fullWidth,
  secondary,
  customClass
}: ICustomButton) => {
  const classes = styles();

  return (
    <Button
      type={type}
      loading={loading}
      onClick={onClick}
      disabled={disabled}
      component={component}
      fullWidth={fullWidth}
      form={type === 'submit' ? form : ''}
      className={classNames(
        classes.customButton,
        customClass,
        { [classes.greyButton]: grey },
        { [classes.disabledButton]: disabled },
        { [classes.secondaryButton]: secondary },
        { [classes.disabledSecondaryButton]: disabled && secondary }
      )}
    >
      {children}
    </Button>
  );
};

export default CustomButton;
