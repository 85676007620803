import { generateHexString } from '../../helpers';

const getActivity = activity => {
  switch (activity) {
    case 'Contact PCP':
      return 'CONTACTED_PCP';
    case 'Unresolved alerts':
      return 'UNRESOLVED';
    case 'Have one resolution':
      return 'RESOLVED';
    case 'Alerts':
      return 'WITH_ALERTS';
    case 'Medication entered':
      return 'HAS_DRUGS';
    case 'Reviewed by Pharmacist':
      return 'HAS_PHARMACIST_REVIEWED';
    default:
      return '';
  }
};

export const mapActivitySummaryChartInputs = (startDate, endDate) => ({
  createdFrom: startDate,
  createdTo: endDate
});

export const mapActivitySummaryTableInputs = (
  createdFrom,
  createdTo,
  activity,
  sortModel
) => {
  let searchFields = {
    limit: 10,
    createdTo,
    createdFrom,
    activitySummaryType: getActivity(activity)
  };

  if (sortModel.length > 0) {
    const sortModelMapper: { [key: string]: string } = {
      patientName: 'PATIENT_NAME',
      age: 'AGE'
    };

    const sortState = {
      order: sortModel[0]?.sort?.toUpperCase() || '',
      orderBy: sortModelMapper[sortModel[0]?.field] || ''
    };

    searchFields = { ...searchFields, ...sortState };
  }

  const mappedData = Object.fromEntries(
    Object.entries(searchFields).filter(([_, v]) => v != null && v !== '')
  );

  return mappedData;
};

export const mapActivitySummaryOutputsToChartData = (siteId, results?) => {
  const contactedPcpColor = `#${generateHexString(6)}`;
  const unresolvedColor = `#${generateHexString(6)}`;
  const resolvedColor = `#${generateHexString(6)}`;
  const withAlertsColor = `#${generateHexString(6)}`;
  const hasDrugsColor = `#${generateHexString(6)}`;
  const hasPharmacistReviewedColor = `#${generateHexString(6)}`;

  let chartData = [
    {
      name: 'Contact PCP',
      activitiesCount: 0,
      color: contactedPcpColor
    },
    {
      name: 'Unresolved alerts',
      activitiesCount: 0,
      color: unresolvedColor
    },
    {
      name: 'Have one resolution',
      activitiesCount: 0,
      color: resolvedColor
    },
    {
      name: 'Alerts',
      activitiesCount: 0,
      color: withAlertsColor
    },
    {
      name: 'Medication entered',
      activitiesCount: 0,
      color: hasDrugsColor
    },
    {
      name: 'Reviewed by Pharmacist',
      activitiesCount: 0,
      color: hasPharmacistReviewedColor
    }
  ];

  if (results) {
    chartData = [
      {
        name: 'Contact PCP',
        activitiesCount: results?.contactedPcp || 0,
        color: contactedPcpColor
      },
      {
        name: 'Unresolved alerts',
        activitiesCount: results?.unresolved || 0,
        color: unresolvedColor
      },
      {
        name: 'Have one resolution',
        activitiesCount: results?.resolved || 0,
        color: resolvedColor
      },
      {
        name: 'Alerts',
        activitiesCount: results?.withAlerts || 0,
        color: withAlertsColor
      },
      {
        name: 'Medication entered',
        activitiesCount: results?.hasDrugs || 0,
        color: hasDrugsColor
      },
      {
        name: 'Reviewed by Pharmacist',
        activitiesCount: results?.hasPharmacistReviewed || 0,
        color: hasPharmacistReviewedColor
      }
    ];
  }

  return chartData;
};

export const mapActivitySummaryOutputsToTable = patients =>
  patients.map(patient => ({
    ...patient,
    id: patient?.patientId,
    age: patient?.age < 1 ? 'Less than a year' : patient?.age,
  }));

export const mapActivitySummaryOutputsToExportedTable = data => [
  {
    id: 1,
    activityName: 'Assessments with Contact PCP',
    count: data?.contactedPcp || 0
  },
  {
    id: 2,
    activityName: 'Assessments with unresolved alerts',
    count: data?.unresolved || 0
  },
  {
    id: 3,
    activityName: 'Assessments with at least one resolution',
    count: data?.resolved || 0
  },
  {
    id: 4,
    activityName: 'Assessments with alerts',
    count: data?.withAlerts || 0
  },
  {
    id: 5,
    activityName: 'Assessments with medication entered',
    count: data?.hasDrugs || 0
  },
  {
    id: 6,
    activityName: 'Assessments reviewed by Pharmacist',
    count: data?.hasPharmacistReviewed || 0
  }
];
