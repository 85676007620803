import { gql } from '@apollo/client';

export const SEARCH_STAFF = gql`
  query searchStaff($data: SearchStaffInput!) {
    searchStaff(data: $data) {
      data {
        id
        firstName
        lastName
        email
        phonenumber
        photo
        active
        role
        createdBy
      }
      count
    }
  }
`;
