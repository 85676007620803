import { GridValueGetterParams } from '@material-ui/data-grid';

export const HISTORY_INPUTS = [
  {
    id: 1,
    dropdownName: 'fall',
    label: 'Have you had a fall in the past three months?'
  },
  {
    id: 2,
    dropdownName: 'dizzy',
    label:
      'Do you often feel dizzy or light-headed when you get up from a chair or your bed?'
  },
  {
    id: 3,
    dropdownName: 'confused',
    label:
      "Have you felt unusually confused at any time in the past three months (i.e. couldn't think straight)?"
  },
  {
    id: 4,
    dropdownName: 'hospital',
    label:
      'Have you been in a hospital or nursing home or visited the ER in the past 3 months?'
  }
];

export const FALL_INPUTS = [
  {
    id: 1,
    name: 'fallTimes',
    inlineInput: true,
    numericField: true,
    label: 'How many times?'
  },
  {
    id: 2,
    name: 'fallWay',
    fullWidth: true,
    label:
      'How did the fall happen (if more than one, record most serious fall)?'
  },
  {
    id: 3,
    name: 'fallInjuries',
    fullWidth: true,
    label: 'Did you have any injuries other than bruises?'
  }
];

export const ROUTES = [
  { id: 1, name: 'By Mouth' },
  { id: 2, name: 'Rectally' },
  { id: 3, name: 'Vaginally' },
  { id: 4, name: 'In Eye(s)' },
  { id: 5, name: 'In Ear(s)' },
  { id: 6, name: 'Nasally' },
  { id: 7, name: 'Intravenously (IV)' },
  { id: 8, name: 'Intramuscularly (IM)' },
  { id: 9, name: 'Intrathecally (IT)' },
  { id: 10, name: 'Subcutaneously (SC)' },
  { id: 11, name: 'Sublingually' },
  { id: 12, name: 'Buccally' },
  { id: 13, name: 'By Inhalation' },
  { id: 14, name: 'Topically' },
  { id: 15, name: 'Transdermally' }
];

export const DOSAGES = [
  { id: 1, name: 'mg' },
  { id: 2, name: 'gm' },
  { id: 3, name: 'mcg' },
  { id: 4, name: 'mL' },
  { id: 5, name: 'units/mL' },
  { id: 6, name: 'mg/mL' },
  { id: 7, name: '(%)' },
  { id: 8, name: 'g' }
];

export const QUANTITES = [
  { id: 1, name: 'Tablet(s)' },
  { id: 2, name: 'Capsule(s)' },
  { id: 3, name: 'Drop(s)' },
  { id: 4, name: 'Ounce(s) - oz' },
  { id: 5, name: 'Patch(es)' },
  { id: 6, name: 'mL(s)' },
  { id: 7, name: 'Puff(s)' },
  { id: 8, name: 'Spray(s)' },
  { id: 9, name: 'Tablespoon(s)- tbsp' },
  { id: 10, name: 'Teaspoon(s) - tsp' },
  { id: 11, name: 'Unit(s)' },
  { id: 12, name: 'Suppository(ies)' }
];

export const FREQUENCIES = [
  { id: 1, name: 'Daily' },
  { id: 2, name: 'Twice daily' },
  { id: 3, name: '3 times daily' },
  { id: 4, name: '4 times daily' },
  { id: 5, name: 'Every 2 hours' },
  { id: 6, name: 'Every 3 hours' },
  { id: 7, name: 'Every 4 hours' },
  { id: 8, name: 'Every 4-6 hours' },
  { id: 9, name: 'Every 5 hours' },
  { id: 10, name: 'Every 6 hours' },
  { id: 11, name: 'Every 6-8 hours' },
  { id: 12, name: 'Every 12 hours' },
  { id: 13, name: 'Every other day' },
  { id: 14, name: 'Weekly' },
  { id: 15, name: 'Twice weekly' },
  { id: 16, name: '3 times a week' },
  { id: 17, name: '4 times a week' },
  { id: 18, name: 'Monthly' },
  { id: 19, name: 'Twice a month' },
  { id: 20, name: '3 times a month' }
];

export const PROVIDERS = [
  { id: 1, name: 'John Doe' },
  { id: 2, name: 'Luke Smith' },
  { id: 3, name: 'N/A' }
];

export const PHARMACISTS = [
  { id: 1, name: 'St. George Central' },
  { id: 2, name: 'Bristol Health' },
  { id: 3, name: 'N/A' }
];

export const ASSESSMENT_OPTIONS = [
  { id: 1, value: 'Yes' },
  { id: 2, value: 'No' }
];

export const MEDICATIONS_ROWS = [
  {
    id: 1,
    name: 'Tylenol 8 Hr',
    route: 'Oral',
    dosage: '40',
    dosageUnit: 'mg',
    quantity: '2',
    frequency: 'Daily',
    directions: 'In the Mornings',
    dispenseDate: '08/10/2021',
    firstUseDate: '09/10/2021',
    provider: 'Jane Smith, MD',
    pharmacist: 'John Smtih',
    notes: []
  },
  {
    id: 2,
    name: '',
    route: 'Oral',
    dosage: '40',
    dosageUnit: 'mg',
    quantity: '2',
    frequency: 'Daily',
    directions: 'In the Mornings',
    dispenseDate: '08/10/2021',
    firstUseDate: '09/10/2021',
    provider: 'Provider 1',
    pharmacist: 'Pharmacist 1',
    notes: []
  }
];

export const MEDICATIONS_COLUMNS = [
  { field: 'id', headerName: 'ID', hide: true },
  {
    field: 'name',
    headerName: 'Name',
    sortable: false,
    flex: 1,
    valueGetter: (params: GridValueGetterParams) => params.value || 'N/A'
  },
  { field: 'route', headerName: 'Route', sortable: false, flex: 0.8 },
  {
    field: 'dosage',
    headerName: 'Dosage',
    sortable: false,
    flex: 0.8,
    valueGetter: (params: GridValueGetterParams) =>
      `${params.value || ''} ${
        params.getValue(params.id, 'dosageMeasurement') || ''
      }`
  },
  {
    field: 'quantity',
    headerName: 'Quantity',
    sortable: false,
    flex: 1,
    valueGetter: (params: GridValueGetterParams) =>
      `${params.value || ''} ${
        params.getValue(params.id, 'quantityMeasurement') || ''
      }`
  },
  { field: 'frequency', headerName: 'Frequency', sortable: false, flex: 1 },
  { field: 'direction', headerName: 'Directions', sortable: false, flex: 1 },
  {
    field: 'dispenseDate',
    headerName: 'Dispense Date',
    sortable: false,
    flex: 1
  },
  {
    field: 'firstUseDate',
    headerName: 'First Use Date',
    sortable: false,
    flex: 1
  },
  { field: 'providerName', headerName: 'Provider', sortable: false, flex: 1 },
  { field: 'pharmacyName', headerName: 'Pharmacy', sortable: false, flex: 1 },
  { field: 'notes', headerName: 'Notes', sortable: false, flex: 1 }
];
