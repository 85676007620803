import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { customThemeModel } from '../../models';

export const styles = makeStyles((theme: Theme & customThemeModel) =>
  createStyles({
    tab: {
      padding: '6px 16px',
      color: theme.colors.text,
      borderRadius: 8,
      fontSize: 14,
      fontWeight: 600,
      fontStyle: 'normal',
      fontFamily: 'Open Sans',
      lineHeight: '20px',
      opacity: 0.5,
      cursor: 'pointer'
    },
    sidebar: {
      opacity: 1
    },
    active: {
      color: theme.colors.primary,
      backgroundColor: theme.colors.hover,
      opacity: 1
    }
  })
);
