import { gql } from '@apollo/client';

export const GET_PARENT_SITES = gql`
  query getAllParentSites {
    getParentSites {
      id
      title
    }
  }
`;
