import axios from 'axios';
import { Auth } from 'aws-amplify';

const instance = axios.create({
  timeout: 100000,
  baseURL: process.env.REACT_APP_APIS_URL,
  headers: { 'Content-Type': 'application/json' }
});

instance.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token');

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    } else {
      delete instance.defaults.headers.common.Authorization;
    }

    return config;
  },

  error => Promise.reject(error)
);

instance.interceptors.response.use(
  response => response,
  error => {
    const originalRequest = error.config;

    if (originalRequest && error.response && error.response.status === 401) {
      originalRequest._retry = true;
      Auth.currentSession().then(res => {
        const newToken = res.getIdToken().getJwtToken();
        localStorage.setItem('token', newToken);
      });

      const token = localStorage.getItem('token');

      originalRequest.headers.Authorization = `Bearer ${token}`;
      return axios.request(originalRequest);
    }

    return Promise.reject(error);
  }
);

export default instance;
