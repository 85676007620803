import { gql } from '@apollo/client';

export const GET_LOCAL_STATES = gql`
  query getLocalStates {
    localStates @client {
      sites
      userRole
      isLoggedIn
      userPermissions
    }
  }
`;
