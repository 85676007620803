import { CircularProgress } from '@material-ui/core';

import { styles } from './styles';

function Loader() {
  const classes = styles();

  return (
    <div className={classes.loader}>
      <CircularProgress />
    </div>
  );
}

export default Loader;
