export const mapDashboardEntriesToTable = unMappedData =>
  unMappedData.map(patient => ({
    ...patient,
    id: patient?.patientId,
    phoneNumber: patient?.phonenumber,
    alertStatus: patient?.alertsCount,
    staff: patient.staffName,
    name: patient.patientName,
    pharmacist: patient.primaryPharmacistName,
    secondaryPharmacist: patient.secondaryPharmacistName
  })) || [];
