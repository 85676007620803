import html2canvas from 'html2canvas';
import { jsPDF as JsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import header_logo from '../../assets/images/header_logo.png';

export const handleDownloadPdf = (ref: any, fileName: string) => {
  if (ref.current) {
    html2canvas(ref.current, { scale: 3 }).then(canvas => {
      const componentWidth = ref.current.offsetWidth;
      const componentHeight = ref.current.offsetHeight;
      const orientation = componentWidth >= componentHeight ? 'l' : 'p';
      const imgData = canvas.toDataURL('image/png');
      const pdf = new JsPDF({ orientation, unit: 'px' });
      pdf.internal.pageSize.width = componentWidth;
      pdf.internal.pageSize.height = componentHeight;
      pdf.addImage(imgData, 'PNG', 0, 0, componentWidth, componentHeight);
      pdf.save(fileName);
    });
  }
};

export const handleDownloadPdfV2 = (docDefinition: any, fileName: string) => {
  const doc = new JsPDF();
  docDefinition.forEach(item => {
    autoTable(doc, { ...item, didDrawPage: data => didDrawPage(data, doc) });
  });
  doc.save(fileName);
};

const didDrawPage = (data: any, doc: any) => {
  const logoImg = new Image();
  logoImg.src = header_logo;
  const x = doc.internal.pageSize.getWidth() - data.settings.margin.right - 35;
  const y = 5;
  doc.addImage(logoImg, 'PNG', x, y, 35, 10);
};
