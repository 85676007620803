import { gql } from '@apollo/client';

export const GET_MAPPING = gql`
  query getMapping($id: String!) {
    getMapping(id: $id) {
      ... on Operation {
        type
        operator
      }
      ... on Formula {
        type
        comparator
        field
        fieldValue
      }
      ... on Conditions {
        type
        value
        includes
      }
      ... on Allergies {
        type
        value
        includes
      }
      ... on DrugClass {
        type
        drugValue {
          name
          id
        }
        includes
      }
      ... on DrugName {
        type
        drugValue {
          name
          id
        }
        includes
      }
      ... on GPI {
        type
        drugValue {
          name
          id
        }
        includes
      }
    }
  }
`;
