import * as yup from 'yup';

export const criteriaValidationSchema = yup.object().shape({
  title: yup.string()
  .required('Field is required')
  .test('title', 'Please enter a valid title', value =>
  /^([a-zA-Z0-9.'(){}=!@#$%^&~`_;,'+-.]+\s)*[a-zA-Z0-9.'(){}=!@#$%^&~`_;,'+-.]+$|^$/.test(value as string)),
  alias: yup.string()
  .required('Field is required')
  .test('alias', 'Please enter a valid alert name', value =>
  /^([a-zA-Z0-9.'(){}=!@#$%^&~`_;,'+-.]+\s)*[a-zA-Z0-9.'(){}=!@#$%^&~`_;,'+-.]+$|^$/.test(value as string)),
  disabled: yup.string().required('Field is required'),
  description: yup.string().required('Field is required')
});

export const recommendationValidationSchema = yup.object().shape({
  title: yup.string().required('Field is required'),
  disabled: yup.string().required('Field is required'),
  alias: yup.string().required('Field is required'),
  description: yup.string().required('Field is required'),
  details: yup.string().required('Field is required')
});
