import { Route as PublicRoute } from 'react-router-dom';

import { PATH_NAME } from '../pathNames';
import { DuplicateDrugs } from '../lazyLoaded';

const DuplicateDrugsRoute = () => (
  <PublicRoute
    exact
    path={PATH_NAME.DUPLICATE_DRUGS}
    component={(props: JSX.IntrinsicAttributes) => (
      <DuplicateDrugs {...props} />
    )}
  />
);
export default DuplicateDrugsRoute;
