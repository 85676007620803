import { gql } from '@apollo/client';

export const GET_ALL_BULLETIN = gql`
  query getAllBulletin {
    getAllBulletin {
      data {
        id
        name
        status
        description
        createdAt
        updatedAt
      }
      count
    }
  }
`;
