import { useCallback } from 'react';

import { makeVar, ReactiveVar } from '@apollo/client';

import { ROLES, USER_PERMISSIONS } from '../../utils/constants';
import { roleModel, sitesModel, localStatesModel } from '../../models';

const localRole = localStorage.getItem('role') as roleModel;
const localIsLoggedIn = Boolean(localStorage.getItem('token'));
const localSites = JSON.parse(
  localStorage.getItem('sites') as string
) as sitesModel;

const roleCheck = Object.values(ROLES).includes(localRole);

const localStatesInitialValue: localStatesModel = {
  sites: localSites || [],
  isLoggedIn: localIsLoggedIn,
  userRole: roleCheck ? localRole : ROLES.GUEST,
  userPermissions: USER_PERMISSIONS[roleCheck ? localRole : ROLES.GUEST]
};

export const localStates = makeVar<localStatesModel>(localStatesInitialValue);

export const useLocalStates = (localState: ReactiveVar<localStatesModel>) => {
  const editUserRole = useCallback(
    (newUserRole: roleModel) => {
      const oldLocalState = localState();
      const newlocalState = {
        ...oldLocalState,
        userRole: newUserRole,
        userPermissions: USER_PERMISSIONS[newUserRole]
      };
      localState(newlocalState);
    },
    [localState]
  );

  const editIsLoggedIn = useCallback(
    (newLoggedIn: boolean) => {
      const oldLocalState = localState();
      const newlocalState = { ...oldLocalState, isLoggedIn: newLoggedIn };
      localState(newlocalState);
    },
    [localState]
  );

  const editSites = useCallback(
    (newSites: sitesModel) => {
      const oldLocalState = localState();
      const newlocalState = { ...oldLocalState, sites: newSites };
      localState(newlocalState);
    },
    [localState]
  );

  return {
    editSites,
    editUserRole,
    editIsLoggedIn
  };
};
