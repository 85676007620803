import { generateHexString } from '../../helpers';

const dataObject = [
  { name: 'Duplicate Therapies' },
  { name: 'Duplicate Ingredients' }
];

export const mapAlertsResolvedChartInputs = (startDate, endDate) => ({
  createdFrom: startDate,
  createdTo: endDate
});

export const mapAlertsResolvedTableInputs = (
  createdFrom,
  createdTo,
  alertName,
  chartData,
  sortModel
) => {
  const alert = chartData?.find(entry => entry?.name === alertName);
  let searchFields = {
    createdTo,
    createdFrom,
    type: alert?.isDuplicate ? alert?.type : '',
    criterionId: !alert?.isDuplicate ? alert?.id : ''
  };

  if (sortModel.length > 0) {
    const sortModelMapper: { [key: string]: string } = {
      patientName: 'PATIENT_NAME'
    };

    const sortState = {
      order: sortModel[0]?.sort?.toUpperCase() || '',
      orderBy: sortModelMapper[sortModel[0]?.field] || ''
    };

    searchFields = { ...searchFields, ...sortState };
  }

  const mappedData = Object.fromEntries(
    Object.entries(searchFields).filter(([_, v]) => v != null && v !== '')
  );

  return mappedData;
};

export const mapAlertsResolvedOutputsToChartData = (siteId, results?) => {
  let medispanChartData = dataObject.map(({ name }) => ({
    name,
    alertsCount: 0
  }));
  let criterionChartData = [
    {
      name: 'Criterion Alert',
      alertsCount: 0
    }
  ];

  if (results) {
    medispanChartData = results?.medispanAlerts?.map(
      ({ drugs, type, count }) => ({
        name: `${drugs?.map(drug => drug?.name)?.join('-')} Alert`,
        color: `#${generateHexString(6)}`,
        alertsCount: count || 0,
        isDuplicate: true,
        type
      })
    );
    criterionChartData = results?.criterionAlerts?.map(
      ({ id, count, title ,alias}) => ({
        name:  alias === null ? title:alias,
        color: `#${generateHexString(6)}`,
        alertsCount: count || 0,
        id,
        isDuplicate: false
      })
    );
  }

  return [...criterionChartData, ...medispanChartData];
};

export const mapAlertsResolvedOutputsToTable = patients =>
  patients.map(patient => ({
    ...patient,
    id: patient?.patientId
  }));

export const mapAlertsResolvedOutputsToExportedTable = data => {
  const totalAlerts =
    +data.medispanAlerts.reduce((acc, curr) => +acc + +curr?.count, 0) +
    +data.criterionAlerts.reduce((acc, curr) => +acc + +curr?.count, 0);

  return [
    ...data?.medispanAlerts?.map(({ drugs, count }, index) => ({
      id: `${count}-${index}`,
      alertType: `${drugs?.map(drug => drug?.name)?.join('-')} Alert`,
      numberOfPatients: count,
      percentage: `${((count / totalAlerts) * 100).toFixed(1)}%`,
      totalPatients: data?.totalPatients
    })),
    ...data?.criterionAlerts?.map(({ id, count, title,alias }) => ({
      id,
      alertType: alias === null ? title:alias,
      numberOfPatients: count,
      percentage: `${((count / totalAlerts) * 100).toFixed(1)}%`,
      totalPatients: data?.totalPatients
    }))
  ];
};
