import { gql } from '@apollo/client';

export const GET_GENDER_DISTRIBUTION_REPORT = gql`
  query getGenderDistributionReport($data: StandardReportInput!) {
    getGenderDistributionReport(data: $data) {
      totalPatients
      totalMales
      totalFemales
      totalNonBinary
      totalNotDisclose
      totalAverageAge
      averageMaleAge
      averageFemaleAge
      averageNonBinaryAge
      averageNotDiscloseAge
      maleAgeFrom0To29
      femaleAgeFrom0To29
      nonBinaryAgeFrom0To29
      notDiscloseAgeFrom0To29
      maleAgeFrom30To39
      femaleAgeFrom30To39
      nonBinaryAgeFrom30To39
      notDiscloseAgeFrom30To39
      maleAgeFrom40To49
      femaleAgeFrom40To49
      nonBinaryAgeFrom40To49
      notDiscloseAgeFrom40To49
      maleAgeFrom50To59
      femaleAgeFrom50To59
      nonBinaryAgeFrom50To59
      notDiscloseAgeFrom50To59
      maleAgeFrom60To69
      femaleAgeFrom60To69
      nonBinaryAgeFrom60To69
      notDiscloseAgeFrom60To69
      maleAgeFrom70To79
      femaleAgeFrom70To79
      nonBinaryAgeFrom70To79
      notDiscloseAgeFrom70To79
      maleAgeFrom80To89
      femaleAgeFrom80To89
      nonBinaryAgeFrom80To89
      notDiscloseAgeFrom80To89
      maleAgeFrom90
      femaleAgeFrom90
      nonBinaryAgeFrom90
      notDiscloseAgeFrom90
      totalPatientsFrom0To29
      totalPatientsFrom30To39
      totalPatientsFrom40To49
      totalPatientsFrom50To59
      totalPatientsFrom60To69
      totalPatientsFrom70To79
      totalPatientsFrom80To89
      totalPatientsFrom90
    }
  }
`;
