import { GridSortModel } from '@material-ui/data-grid';

export const handleLocalSortModel = (
  table: string,
  sortModel: GridSortModel
) => {
  if (localStorage.getItem('localSortModel')) {
    const localSortModel = JSON.parse(
      localStorage.getItem('localSortModel') as string
    );

    if (sortModel?.length > 0)
      Object.assign(localSortModel, { [table]: sortModel });
    else delete localSortModel[table];

    localStorage.setItem('localSortModel', JSON.stringify(localSortModel));
  } else {
    localStorage.setItem(
      'localSortModel',
      JSON.stringify({ [table]: sortModel })
    );
  }
};
