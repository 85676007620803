import { gql } from '@apollo/client';

export const GET_CONDITIONS_REPORT = gql`
  query getConditionsReport($data: StandardReportInput!) {
    getConditionsReport(data: $data) {
      totalPatients
      conditions {
        conditionName
        conditionCount
      }
    }
  }
`;
