import { createStyles, makeStyles } from '@material-ui/core';

export const styles = makeStyles(() =>
  createStyles({
    loader: {
      position: 'absolute',
      top: '40%',
      width: '100%',
      textAlign: 'center'
    }
  })
);
