import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { customThemeModel } from '../../models';

export const styles = makeStyles((theme: Theme & customThemeModel) =>
  createStyles({
    customButton: {
      height: theme.lengths.elementsHeight,
      padding: '10px 16px',
      color: theme.colors.white,
      backgroundColor: `${theme.colors.primary} !important`,
      borderRadius: 8,
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 14,
      lineHeight: '20px',
      textTransform: 'none'
    },
    secondaryButton: {
      color: theme.colors.primary,
      backgroundColor: `${theme.colors.white} !important`,
      border: `1px solid ${theme.colors.primary}`
    },
    disabledButton: {
      color: `${theme.colors.text} !important`,
      backgroundColor: `${theme.colors.lines} !important`
    },
    greyButton: {
      color: `${theme.colors.greyText} !important`,
      border: `1px solid ${theme.colors.greyText}`
    },
    disabledSecondaryButton: {
      color: `${theme.colors.primary} !important`
    }
  })
);
