import { GridSortModel } from '@material-ui/data-grid';

export const prepareUsersSearch = (searchValues, sortModel: GridSortModel) => {
  let newSearchValues = {
    ...searchValues,
    optionalSiteId: searchValues.optionalPatientSiteId || '',
    active: searchValues.active === 'true'
  };

  if (sortModel.length > 0) {
    const sortState = {
      order: sortModel[0]?.sort?.toUpperCase(),
      orderBy: ''
    };

    switch (sortModel[0]?.field) {
      case 'name':
        sortState.orderBy = 'LAST_NAME';
        break;
      case 'active':
      case 'email':
        sortState.orderBy = sortModel[0]?.field?.toUpperCase();
        break;
      default:
        sortState.order = '';
        sortState.orderBy = '';
    }

    newSearchValues = { ...newSearchValues, ...sortState };
  }

  if (searchValues.active === 'all') delete newSearchValues.active;
  if (searchValues.role === 'PATIENT') {
    delete newSearchValues.role;
    delete newSearchValues.optionalPatientSiteId;
  }

  const mappedData = Object.fromEntries(
    Object.entries(newSearchValues).filter(([_, v]) => v != null && v !== '')
  );

  return mappedData;
};

export const prepareGlobalNavigationSearch = (
  searchString: string,
  sortModel: GridSortModel
) => {
  let mappedSortModel = { searchString };

  if (sortModel.length > 0) {
    const sortState = {
      order: sortModel[0]?.sort?.toUpperCase(),
      orderBy: ''
    };

    switch (sortModel[0]?.field) {
      case 'name':
        sortState.orderBy = 'LAST_NAME';
        break;
      case 'active':
      case 'email':
        sortState.orderBy = sortModel[0]?.field?.toUpperCase();
        break;
      default:
        sortState.order = '';
        sortState.orderBy = '';
    }

    mappedSortModel = { ...mappedSortModel, ...sortState };
  }

  const mappedData = Object.fromEntries(
    Object.entries(mappedSortModel).filter(([_, v]) => v != null && v !== '')
  );

  return mappedData;
};

export const prepareUsersSearchData = usersData => {
  const mappedData = usersData.map(user => {
    const mappedUser = {
      ...user,
      siteTitle: user?.siteTitles?.join(', ')
    };
    return mappedUser;
  });

  return mappedData;
};
