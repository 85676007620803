import { Route as PublicRoute } from 'react-router-dom';

import { PATH_NAME } from '../pathNames';
import { Assessment } from '../lazyLoaded';

const AssessmentRoute = () => (
  <PublicRoute
    exact
    path={PATH_NAME.ASSESSMENT}
    component={(props: JSX.IntrinsicAttributes) => <Assessment {...props} />}
  />
);
export default AssessmentRoute;
