import { gql } from '@apollo/client';

export const GET_RACE_REPORT = gql`
  query getRaceReport($data: StandardReportInput!) {
    getRaceReport(data: $data) {
      totalPatients
      totalAmericanOrAlaska
      totalAsian
      totalBlackOrAfrican
      totalWhite
      totalHawaiianOrPacific
      totalTwoOrMore
      totalPreferNotToDisclose
    }
  }
`;
