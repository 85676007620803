export const validatePhoneNumber = (phoneNumber: string): string => {
  let result = phoneNumber;

  // Accepted formats: 555-555-5555, (555) 555-5555
  const matched = /^(\d{3}|\(\d{3}\))[\s\-]\d{3}-\d{4}$/.test(phoneNumber);
  if (matched) {
    const cleaned = phoneNumber.replace(/\D/g, '');
    result = `${cleaned.substring(0, 3)}-${cleaned.substring(
      3,
      6
    )}-${cleaned.substring(6)}`;
  }

  return result;
};
