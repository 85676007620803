import { securityQuestionModel } from '../../models';

export const SECURITY_QUESTIONS: securityQuestionModel[] = [
  {
    id: 'iyu12it3123',
    question: 'Who was your childhood bestfriend?',
    answer: 'Amr'
  },
  {
    id: 'gjhg234hgjf',
    question: 'What is your favorite sports club?',
    answer: 'FC Barcelona'
  }
];

export const ACCOUNT_SECURITY_QUESTIONS = [
  {
    id: 'f775bb5d-f58a-46bd-911e-f2435138a74f',
    question: 'What was your childhood nickname?'
  },
  {
    id: '57fd1e44-03bc-4b02-85d4-d95ffe85a8bd',
    question: 'In what city did you meet your spouse/significant other?'
  },
  {
    id: '9eed5ac9-c6cc-4939-9afb-0ee0224289d0',
    question: 'What is the name of your favorite childhood friend?'
  },
  {
    id: 'a311b2f6-052b-4e18-9ee9-619b0903e965',
    question: 'What street did you live on in third grade?'
  },
  {
    id: 'a128797b-047d-4f3e-a73a-0eb8ed0473d8',
    question:
      'What is your oldest sibling’s birthday month and year? (e.g., January 1900)'
  },
  {
    id: 'a9be5775-1ec9-4334-9135-29e1635306bb',
    question: 'What is the middle name of your youngest child?'
  },
  {
    id: '0a6f1355-8374-4184-942c-d3fb12b82642',
    question: "What is your oldest sibling's middle name?"
  },
  {
    id: 'd343f026-eb83-4348-a87d-89c133502293',
    question: 'What school did you attend for sixth grade?'
  },
  {
    id: '44e6dc08-4d0b-4f8b-bcc1-c9f6d6456fd2',
    question:
      'What was your childhood phone number including area code? (e.g., 000-000-0000)'
  },
  {
    id: 'b2329e16-3220-4836-9396-579e70e7b5fd',
    question: "What is your oldest cousin's first and last name?"
  },
  {
    id: '8674ad97-9b4b-4e86-acc3-0e11bf28d9ce',
    question: 'What was the name of your first stuffed animal?'
  },
  {
    id: '94709beb-b06b-4b0a-aebc-04bfbe9ec95d',
    question: 'In what city or town did your mother and father meet?'
  },
  {
    id: '196006db-886d-4b98-9e7c-fb2195b092af',
    question: 'Where were you when you had your first kiss?'
  },
  {
    id: '09c2c50c-89bf-4e03-9b60-48814f208836',
    question: 'What is the first name of the boy or girl that you first kissed?'
  },
  {
    id: '5a54a76b-c9ac-4df0-aa8c-671f48cca3e3',
    question: 'What was the last name of your third grade teacher?'
  },
  {
    id: '779a62e1-9012-4dbc-a1cd-c3b8b29fa56b',
    question: 'In what city does your nearest sibling live?'
  },
  {
    id: '341fd81d-48cd-45f9-9d44-1516285c2d1f',
    question:
      'What is your youngest brother’s birthday month and year? (e.g., January 1900)'
  },
  {
    id: '85149a78-54fd-41aa-9421-e570035df0d0',
    question: "What is your maternal grandmother's maiden name?"
  },
  {
    id: '4a3300a3-826d-4974-8c2a-8773398371c3',
    question: 'In what city or town was your first job?'
  },
  {
    id: '1cfb4576-c654-4104-acab-0a8cea348172',
    question: 'What is the name of the place your wedding reception was held?'
  },
  {
    id: '055fa1f3-a48f-4915-8f0d-c170394526f6',
    question: "What is the name of a college you applied to but didn't attend?"
  }
];
