import AdminRoute from '../adminRoute';
import { PATH_NAME } from '../pathNames';
import { Configure } from '../lazyLoaded';
import { ALL_PERMISSIONS } from '../../utils/constants';

const ConfigureRoute = () => (
  <AdminRoute
    exact
    path={PATH_NAME.CONFIGURE}
    permission={ALL_PERMISSIONS.NAVIGATE_ADMIN_ROUTES}
    component={(props: JSX.IntrinsicAttributes) => <Configure {...props} />}
  />
);
export default ConfigureRoute;
