import React from 'react';
import { Auth } from 'aws-amplify';
import { useIdleTimer } from 'react-idle-timer';
import { localStates, useLocalStates } from '../../graphQL/states/localStates';
import { inActiveTimerOptions } from '../../utils/constants';

const { timeout, syncTimers, throttle } = inActiveTimerOptions;

const LogoutTimer = () => {
  const { editIsLoggedIn } = useLocalStates(localStates);

  const handleLogout = async () => {
    await Auth.signOut()
      .then(() => {
        editIsLoggedIn(false);
        localStorage.clear();
      })
      .catch(() => {});
  };

  const onIdle = () => {
    handleLogout();
  };
  const { getRemainingTime } = useIdleTimer({
    onIdle,
    events: [
      'mousemove',
      'keydown',
      'wheel',
      'DOMMouseScroll',
      'mousewheel',
      'mousedown',
      'touchstart',
      'touchmove',
      'MSPointerDown',
      'MSPointerMove',
      'visibilitychange',
      'focus'
    ],
    startOnMount: true,
    stopOnIdle: true,
    crossTab: true,
    leaderElection: true,
    timeout,
    syncTimers,
    throttle
  });

  return null;
};

export default LogoutTimer;
