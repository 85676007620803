import { generateHexString } from '../../helpers';

const getGender = gender => {
  switch (gender) {
    case 'malesCount':
      return 'MALE';
    case 'femalesCount':
      return 'FEMALE';
    case 'nonBinariesCount':
      return 'NON_BINARY';
    case 'notDisclosedCount':
      return 'PREFER_NOT_TO_SAY';
    default:
      return '';
  }
};

const getAgeRange = gender => {
  switch (gender) {
    case 'Age 0-29':
      return 'AGE_0_29';
    case 'Age 30-39':
      return 'AGE_30_39';
    case 'Age 40-49':
      return 'AGE_40_49';
    case 'Age 50-59':
      return 'AGE_50_59';
    case 'Age 60-69':
      return 'AGE_60_69';
    case 'Age 70-79':
      return 'AGE_70_79';
    case 'Age 80-89':
      return 'AGE_80_89';
    case 'Age 90+':
      return 'AGE_90_199';
    default:
      return '';
  }
};

const dataObject = [
  { name: 'Age 0-29', range: '0To29' },
  { name: 'Age 30-39', range: '30To39' },
  { name: 'Age 40-49', range: '40To49' },
  { name: 'Age 50-59', range: '50To59' },
  { name: 'Age 60-69', range: '60To69' },
  { name: 'Age 70-79', range: '70To79' },
  { name: 'Age 80-89', range: '80To89' },
  { name: 'Age 90+', range: '90' }
];

export const mapGenderChartInputs = (startDate, endDate) => ({
  createdFrom: startDate,
  createdTo: endDate
});

export const mapGenderTableInputs = (
  createdFrom,
  createdTo,
  ageRange,
  gender,
  sortModel
) => {
  let searchFields = {
    createdTo,
    createdFrom,
    gender: getGender(gender),
    age: getAgeRange(ageRange)
  };

  if (sortModel.length > 0) {
    const sortModelMapper: { [key: string]: string } = {
      name: 'PATIENT_NAME',
      age: 'AGE',
      siteName: 'SITE_NAME',
      assignedStaff: 'PRIMARY_STAFF_NAME'
    };

    const sortState = {
      order: sortModel[0]?.sort?.toUpperCase() || '',
      orderBy: sortModelMapper[sortModel[0]?.field] || ''
    };

    searchFields = { ...searchFields, ...sortState };
  }

  const mappedData = Object.fromEntries(
    Object.entries(searchFields).filter(([_, v]) => v != null && v !== '')
  );

  return mappedData;
};

export const mapGenderOutputsToChartData = (siteId, results?) => {
  const malesColor = `#${generateHexString(6)}`;
  const femalesColor = `#${generateHexString(6)}`;
  const nonBinariesColor = `#${generateHexString(6)}`;
  const notDisclosedColor = `#${generateHexString(6)}`;

  let totals = {
    all: 0,
    males: 0,
    females: 0,
    nonBinaries: 0,
    notDisclosed: 0
  };
  let averages = {
    all: 0,
    males: 0,
    females: 0,
    nonBinaries: 0,
    notDisclosed: 0
  };

  let chartData = dataObject.map(({ name }) => ({
    name,
    malesCount: 0,
    femalesCount: 0,
    nonBinariesCount: 0,
    notDisclosedCount: 0,
    malesColor,
    femalesColor,
    nonBinariesColor,
    notDisclosedColor
  }));

  if (siteId !== 'none') {
    totals = {
      all: results?.getGenderDistributionReport?.totalPatients || 0,
      males: results?.getGenderDistributionReport?.totalMales || 0,
      females: results?.getGenderDistributionReport?.totalFemales || 0,
      nonBinaries: results?.getGenderDistributionReport?.totalNonBinary || 0,
      notDisclosed: results?.getGenderDistributionReport?.totalNotDisclose || 0
    };

    averages = {
      all:
        Math.round(
          results?.getGenderDistributionReport?.totalAverageAge * 100
        ) / 100 || 0,
      males:
        Math.round(results?.getGenderDistributionReport?.averageMaleAge * 100) /
          100 || 0,
      females:
        Math.round(
          results?.getGenderDistributionReport?.averageFemaleAge * 100
        ) / 100 || 0,
      nonBinaries:
        Math.round(
          results?.getGenderDistributionReport?.averageNonBinaryAge * 100
        ) / 100 || 0,
      notDisclosed:
        Math.round(
          results?.getGenderDistributionReport?.averageNotDiscloseAge * 100
        ) / 100 || 0
    };

    chartData = dataObject.map(({ name, range }) => ({
      name,
      malesCount:
        results?.getGenderDistributionReport?.[`maleAgeFrom${range}`] || 0,
      femalesCount:
        results?.getGenderDistributionReport?.[`femaleAgeFrom${range}`] || 0,
      nonBinariesCount:
        results?.getGenderDistributionReport?.[`nonBinaryAgeFrom${range}`] || 0,
      notDisclosedCount:
        results?.getGenderDistributionReport?.[`notDiscloseAgeFrom${range}`] ||
        0,
      malesColor,
      femalesColor,
      nonBinariesColor,
      notDisclosedColor
    }));
  }

  return { totals, averages, chartData };
};

export const mapGenderOutputsToTable = patients =>
  patients.map(patient => ({
    id: patient?.patientId,
    name: patient?.patientName,
    age: patient?.age < 1 ? 'Less than a year' : patient?.age,
    siteName: patient?.siteName,
    assignedStaff: patient?.primaryStaffName
  }));

export const mapGenderOutputsToExportedTable = data =>
  dataObject.map(({ name, range }, index) => ({
    id: index,
    age: name,
    total: data?.[`totalPatientsFrom${range}`] || 0,
    males: data?.[`maleAgeFrom${range}`] || 0,
    females: data?.[`femaleAgeFrom${range}`] || 0,
    nonBinaries: data?.[`nonBinaryAgeFrom${range}`] || 0,
    notDisclosed: data?.[`notDiscloseAgeFrom${range}`] || 0
  }));
