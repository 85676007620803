import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { customThemeModel } from '../../../../models';

export const styles = makeStyles((theme: Theme & customThemeModel) =>
  createStyles({
    inputsContainer: {
      display: 'grid',
      gridGap: 24,
      gridTemplateColumns: 'repeat(4, 1fr)'
    },
    datePickerContainer: {
      display: 'flex',
      flexDirection: 'column'
    },
    label: {
      marginBottom: 6,
      color: theme.colors.text,
      fontSize: 15,
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontFamily: 'Open Sans',
      lineHeight: '16px'
    },
    customLabel: {
      display: 'flex',
      alignItems: 'center',
      '& > :last-child': {
        marginLeft: 4
      }
    },
    customInput: {
      minWidth: '260px !important'
    },
    checkmarkIcon: { marginLeft: 'auto' }
  })
);
