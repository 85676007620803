import { generateHexString } from '../../helpers';

const getIncident = incident => {
  switch (incident) {
    case 'Fall':
      return 'FALL';
    case 'Dizziness':
      return 'DIZZY';
    case 'Confusion':
      return 'CONFUSION';
    case 'Drinks/Day':
      return 'DRINKS_PER_DAY';
    case 'Drink 2+ Per occasion':
      return 'DRINKS_PER_WEEK';
    case 'Hospital / Er Visit':
      return 'HOSPITALIZED';
    default:
      return '';
  }
};

export const mapIncidentsChartInputs = (startDate, endDate) => ({
  createdFrom: startDate,
  createdTo: endDate
});

export const mapIncidentsTableInputs = (
  createdFrom,
  createdTo,
  incident,
  sortModel
) => {
  let searchFields = {
    limit: 10,
    createdTo,
    createdFrom,
    incidentsSymptoms: getIncident(incident)
  };

  if (sortModel.length > 0) {
    const sortModelMapper: { [key: string]: string } = {
      patientName: 'PATIENT_NAME',
      primaryStaffName: 'PRIMARY_STAFF_NAME',
      reportedFall: 'FALL',
      reportedDizziness: 'DIZZY',
      reportedConfusion: 'CONFUSION',
      drinksPerDay: 'DRINKS_PER_DAY',
      drinksPerWeek: 'DRINKS_PER_WEEK',
      hospitalizedEr: 'HOSPITALIZED_ER'
    };

    const sortState = {
      order: sortModel[0]?.sort?.toUpperCase() || '',
      orderBy: sortModelMapper[sortModel[0]?.field] || ''
    };

    searchFields = { ...searchFields, ...sortState };
  }

  const mappedData = Object.fromEntries(
    Object.entries(searchFields).filter(([_, v]) => v != null && v !== '')
  );

  return mappedData;
};

export const mapIncidentsOutputsToChartData = (results?) => {
  const fallColor = `#${generateHexString(6)}`;
  const dizzinessColor = `#${generateHexString(6)}`;
  const confusionColor = `#${generateHexString(6)}`;
  const drinksPerDayColor = `#${generateHexString(6)}`;
  const drinksPerWeekColor = `#${generateHexString(6)}`;
  const hospitalizedColor = `#${generateHexString(6)}`;

  let chartData = [
    {
      name: 'Fall',
      patientsCount: 0,
      color: fallColor
    },
    { name: 'Dizziness', patientsCount: 0, color: dizzinessColor },
    { name: 'Confusion', patientsCount: 0, color: confusionColor },
    { name: 'Drinks/Day', patientsCount: 0, color: drinksPerDayColor },
    {
      name: 'Drink 2+ Per occasion',
      patientsCount: 0,
      color: drinksPerWeekColor
    },
    { name: 'Hospital / Er Visit', patientsCount: 0, color: hospitalizedColor }
  ];

  if (results) {
    chartData = [
      {
        name: 'Fall',
        patientsCount: results?.reportedFall || 0,
        color: fallColor
      },
      {
        name: 'Dizziness',
        patientsCount: results?.reportedDizziness || 0,
        color: dizzinessColor
      },
      {
        name: 'Confusion',
        patientsCount: results?.reportedConfusion || 0,
        color: confusionColor
      },
      {
        name: 'Drinks/Day',
        patientsCount: results?.drinksPerDay || 0,
        color: drinksPerDayColor
      },
      {
        name: 'Drink 2+ Per occasion',
        patientsCount: results?.drinksPerWeek || 0,
        color: drinksPerWeekColor
      },
      {
        name: 'Hospital / Er Visit',
        patientsCount: results?.hospitalizedEr || 0,
        color: hospitalizedColor
      }
    ];
  }

  return chartData;
};

export const mapIncidentsOutputsToTable = patients =>
  patients.map(patient => ({
    ...patient,
    id: patient?.patientId
  }));

export const mapIncidentsExportedRowsToCSV = exportedRows =>
  exportedRows?.map(row => ({
    ...row,
    reportedFall: row?.reportedFall ? 'Yes' : 'No',
    reportedDizziness: row?.reportedDizziness ? 'Yes' : 'No',
    reportedConfusion: row?.reportedConfusion ? 'Yes' : 'No',
    hospitalizedEr: row?.hospitalizedEr ? 'Yes' : 'No'
  }));
