import classNames from 'classnames';

import { styles } from './styles';

interface IHeading {
  name: String;
  text?: String;
  addButton?: String;
  subHeading?: boolean;
  thickBorder?: boolean;
  handleAddButton?: any;
}

const Heading = ({
  name,
  text,
  addButton,
  subHeading,
  thickBorder,
  handleAddButton
}: IHeading) => {
  const classes = styles();

  return (
    <h1
      className={classNames(
        classes.heading,
        { [classes.subHeading]: subHeading },
        { [classes.thickBorder]: thickBorder }
      )}
    >
      {name}
      {addButton && (
        <span className={classes.addButton} onClick={handleAddButton}>
          + Add {addButton}
        </span>
      )}
      {text && (
        <span className={classNames(classes.addButton, classes.text)}>
          {text}
        </span>
      )}
    </h1>
  );
};
export default Heading;
