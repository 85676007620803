import { generateHexString } from '../../helpers';

export const mapConditionsChartInputs = (startDate, endDate) => ({
  createdFrom: startDate,
  createdTo: endDate
});

export const mapConditionsTableInputs = (
  createdFrom,
  createdTo,
  condition,
  sortModel
) => {
  let searchFields = {
    limit: 10,
    createdTo,
    createdFrom,
    condition
  };

  if (sortModel.length > 0) {
    const sortModelMapper: { [key: string]: string } = {
      patientName: 'PATIENT_NAME'
    };

    const sortState = {
      order: sortModel[0]?.sort?.toUpperCase() || '',
      orderBy: sortModelMapper[sortModel[0]?.field] || ''
    };

    searchFields = { ...searchFields, ...sortState };
  }

  const mappedData = Object.fromEntries(
    Object.entries(searchFields).filter(([_, v]) => v != null && v !== '')
  );

  return mappedData;
};

export const mapConditionsOutputsToChartData = (siteId, results?) =>
  results?.conditions?.map(condition => ({
    name: condition?.conditionName ?? '',
    conditionsCount: condition?.conditionCount ?? 0,
    color: `#${generateHexString(6)}`
  }));

export const mapConditionsOutputsToTable = patients =>
  patients.map(patient => ({
    ...patient,
    id: patient?.patientId,
    ...patient?.conditions?.reduce(
      (accumulated, current) => ({
        ...accumulated,
        [`has${current?.replaceAll(' ', '')}`]: true
      }),
      {}
    )
  }));

export const mapConditionsExportedRows = chartData =>
  chartData?.conditions.map((condition, index) => ({
    id: `${index}-${condition?.conditionCount}`,
    totalPatients: chartData?.totalPatients,
    conditionName: condition?.conditionName,
    count: condition?.conditionCount
  }));

export const mapConditionsColumnsToTable = (chartData, originalColumns) => [
  ...originalColumns,
  ...chartData?.conditions?.map((condition, index) => ({
    id: `${index}-${condition?.conditionCount}`,
    field: `has${condition?.conditionName?.replaceAll(' ', '')}`,
    headerName: `Has ${condition?.conditionName}`,
    valueGetter: params => (params?.value ? 'Yes' : 'No'),
    flex: 0.5,
    sortable: false
  }))
];
