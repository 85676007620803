import { gql } from '@apollo/client';

export const GET_TEN_MEDS_OR_MORE_TABLE = gql`
  query getTenMedsOrMoreTable($data: TenMedsOrMoreTableInput!) {
    getTenMedsOrMoreTable(data: $data) {
      data {
        patientId
        patientName
        age
        siteName
        primaryStaffName
        fall
        dizzy
        hospitalized
      }
      count
    }
  }
`;
