import { gql } from '@apollo/client';

export const GET_DUPLICATE_DRUGS_TABLE = gql`
  query getDuplicateDrugsTable($data: DuplicateDrugsTableInput!) {
    getDuplicateDrugsTable(data: $data) {
      data {
        patientId
        patientName
        age
      }
      count
    }
  }
`;
