import { gql } from '@apollo/client';

export const SEARCH_DASHBOARD_STAFF = gql`
  query searchDashboardStaff($data: PatientDashboardInput!) {
    patientDashboard(data: $data) {
      data {
        patientId
        patientName
        email
        phonenumber
        alertsCount
        assessmentStatus
        primaryPharmacistName
        assessmentId
        processingAssessmentId
        publishedAt
        staffEmail
        staffPhonenumber
        pharmacistPhonenumber
        pharmacistEmail
      }
      count
      patientsWithAlertsUnresolved
      assessmentsIncomplete
    }
  }
`;
