import { Auth } from 'aws-amplify';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import { setContext } from '@apollo/client/link/context';
import {
  from,
  HttpLink,
  Observable,
  ApolloClient,
  InMemoryCache
} from '@apollo/client';

import { localStates } from '../../graphQL/states';

const getUrl = () => {
  const defaultSiteId = localStorage.getItem('defaultSiteId');
  const reportingTempSiteId = sessionStorage.getItem('reportingTempSiteId');

  let siteId = '';

  if (reportingTempSiteId && reportingTempSiteId !== 'all_sites')
    siteId = reportingTempSiteId;
  else if (reportingTempSiteId === 'all_sites') siteId = '';
  else if (defaultSiteId && defaultSiteId !== 'all_sites')
    siteId = defaultSiteId;

  if (siteId) return `${process.env.REACT_APP_GRAPHQL_URL}?siteId=${siteId}`;

  return process.env.REACT_APP_GRAPHQL_URL;
};

const errorLink = onError(
  ({ forward, operation, graphQLErrors, networkError }) => {
    if (networkError) {
      const oldHeaders = operation.getContext().headers;

      return new Observable(observer => {
        Auth.currentSession()
          .then(res => {
            const newToken = res.getIdToken().getJwtToken();
            localStorage.setItem('token', newToken);

            operation.setContext(() => ({
              ...operation.getContext(),
              headers: {
                ...oldHeaders,
                authorization: `Bearer ${newToken}` || ''
              }
            }));
          })
          .then(() => {
            const subscriber = {
              next: observer.next.bind(observer),
              error: observer.error.bind(observer),
              complete: observer.complete.bind(observer)
            };

            forward(operation).subscribe(subscriber);
          })
          .catch(error => {
            observer.error(error);
          });
      });
    }

    if (graphQLErrors) {
      return new Observable(observer => {
        operation.setContext(() => ({
          uri: getUrl()
        }));
        const subscriber = {
          next: observer.next.bind(observer),
          error: observer.error.bind(observer),
          complete: observer.complete.bind(observer)
        };

        forward(operation).subscribe(subscriber);
      });
    }

    return undefined;
  }
);

const authLink = setContext((_, { headers, ...contexts }) => {
  const token = localStorage.getItem('token');

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ''
    },
    ...contexts
  };
});

const retryLink = new RetryLink({
  delay: {
    initial: 500,
    max: Infinity,
    jitter: true
  },
  attempts: {
    max: 3,
    retryIf: (error, _operation) => !!error
  }
});

const dynamicUrlFetch = (_, options) => fetch(getUrl() as string, options);

const httpLink = from([
  errorLink,
  retryLink,
  new HttpLink({ fetch: dynamicUrlFetch })
]);

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        localStates: {
          read() {
            return localStates();
          }
        }
      }
    }
  }
});

export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache
});
