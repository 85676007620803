import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { customThemeModel } from '../../../../models';

export const styles = makeStyles((theme: Theme & customThemeModel) =>
  createStyles({
    inputsContainer: {
      display: 'flex',
      justifyContent: 'space-between'
    },
    customInput: {
      minWidth: '314px !important'
    }
  })
);
