import html2canvas from 'html2canvas';
import domtoimage from 'dom-to-image';

export const handleDownloadPng = (
  ref: any,
  fileName: string,
  isComplexExport?: boolean
) => {
  if (isComplexExport && ref.current)
    domtoimage.toPng(ref.current).then(dataUrl => saveAs(dataUrl, fileName));
  else if (ref.current)
    html2canvas(ref.current, { scale: 2 }).then(canvas => {
      saveAs(canvas.toDataURL(), fileName);
    });
};

function saveAs(uri, filename) {
  const link = document.createElement('a');
  if (typeof link.download === 'string') {
    link.href = uri;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } else window.open(uri);
}
