import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import LogoutTimer from '../../sharedComponents/LogoutTimer';
import HomeRepresentation from './HomeRepresentation';
import { PATH_NAME } from '../../routes/pathNames';

const Home = () => {
  const history = useHistory();

  const getPreviousPageUrl = () => sessionStorage.getItem('previousPageUrl');
  const removePreviousPageUrl = () =>
    sessionStorage.removeItem('previousPageUrl');

  useEffect(() => {
    setTimeout(() => {
      const previousPageUrl = getPreviousPageUrl();
      if (previousPageUrl && previousPageUrl !== PATH_NAME.DASHBOARD) {
        history.push(previousPageUrl);
      }
    }, 10);
    setTimeout(() => removePreviousPageUrl(), 2000);
  }, []);

  return (
    <>
      <HomeRepresentation />
      <LogoutTimer />
    </>
  );
};

export default Home;
