import { gql } from '@apollo/client';

export const GET_TEN_MEDS_OR_MORE_REPORT = gql`
  query getTenMedsOrMoreReport($data: StandardReportInput!) {
    getTenMedsOrMoreReport(data: $data) {
      totalPatients
      tenMedsOrMore
      lessThanTen
    }
  }
`;
