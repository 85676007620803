import { gql } from '@apollo/client';

export const GET_AGE_DISTRIBUTION_TABLE = gql`
  query getAgeDistributionTable($data: AgeDistributionTableInput!) {
    getAgeDistributionTable(data: $data) {
      data {
        patientId
        patientName
        age
        siteName
        primaryStaffName
      }
      count
    }
  }
`;
