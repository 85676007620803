import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { customThemeModel } from '../../models';

export const styles = makeStyles((theme: Theme & customThemeModel) =>
  createStyles({
    confirmationMessage: {
      width: '100%',
      minHeight: '51px',
      fontWeight: 600,
      fontSize: '14px',
      minWidth: '150px',
      lineHeight: '20px',
      textAlign: 'center',
      borderRadius: '8px',
      justifyContent: 'center',
      color: theme.colors.white,
      '& .MuiAlert-action': { display: 'none' }
    },
    infoMessage: { background: theme.colors.primary },
    errorMessage: { background: theme.colors.error }
  })
);
