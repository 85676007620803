import { Route as PublicRoute } from 'react-router-dom';

import { Bulletin } from '../lazyLoaded';
import { PATH_NAME } from '../pathNames';

const BulletinRoute = () => (
  <PublicRoute
    exact
    path={PATH_NAME.BULLETIN}
    component={(props: JSX.IntrinsicAttributes) => <Bulletin {...props} />}
  />
);
export default BulletinRoute;
