import { makeStyles, createStyles, Theme } from '@material-ui/core';
import { customThemeModel } from '../../../../models';

export const styles = makeStyles((theme: Theme & customThemeModel) =>
  createStyles({
    customDropdownSite: {
      width: 220,
      marginLeft: 25,
      paddingRight: 20,
      color: '#FFF !important',
      backgroundColor: 'rgba(255,255,255,0.02) !important',
      border: '1px solid rgba(255, 255, 255, 0.2) !important',
      fontWeight: 600
    },
    customMenuSite: {
      marginTop: '34px !important',
      '& .MuiPaper-root': {
        width: 220,
        marginTop: '0px !important'
      }
    },
    checkmarkIcon: {
      marginLeft: 19
    }
  })
);
