import { makeStyles, createStyles, Theme } from '@material-ui/core';
import { customThemeModel } from '../../../../models';

export const styles = makeStyles((theme: Theme & customThemeModel) =>
  createStyles({
    searchIcon: {
      marginRight: 14,
      cursor: 'pointer'
    },
    searchPopper: {
      minWidth: 256,
      marginTop: 7
    },
    searchEndAdornment: {
      marginRight: -16
    },
    searchFilter: {
      width: 193,
      height: 32,
      marginRight: 24,
      marginBottom: 13,
      padding: 0,
      backgroundColor: `${theme.colors.header} !important`,
      borderBottom: '1px solid rgba(255, 255, 255, 0.5)'
    },
    optionsPaper: {
      marginTop: 5,
      borderRadius: 8,
      '& .MuiAutocomplete-listbox': {
        maxHeight: 310,
        padding: '2px 0',
        border: `1px solid ${theme.colors.primary}`,
        borderRadius: '8px'
      }
    },
    seeMoreOptionsPaper: {
      '& .MuiAutocomplete-listbox': {
        '& > :last-child': {
          '&:hover': {
            backgroundColor: 'unset !important',
            cursor: 'initial'
          }
        }
      }
    },
    loadingPaper: {
      height: 130,
      backgroundColor: theme.colors.white,
      border: `1px solid ${theme.colors.primary}`,
      borderRadius: 8
    },
    searchOptions: {
      width: 256,
      padding: '6px 4px',
      color: theme.colors.black,
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontFamily: 'Open Sans',
      lineHeight: '20px'
    },
    searchText: {
      '& .MuiInputBase-input': {
        color: '#FFF',
        fontSize: '14px',
        transform: 'translate(-3px, 6px) !important'
      },
      '& .MuiFilledInput-root': {
        padding: 0
      }
    },
    clearIcon: {
      marginTop: '10px',
      marginRight: '6px'
    },
    seeMoreOption: {
      width: 'auto',
      marginLeft: '35%',
      marginBottom: 4,
      padding: 0,
      lineheight: 'unset',
      color: theme.colors.primary,
      cursor: 'pointer'
    },
    newlineassessment: {
      display: 'block',
      marginTop: 4
    }
  })
);
