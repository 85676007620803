import { gql } from '@apollo/client';

export const GLOBAL_NAVIGATION_PATIENTS_SEARCH = gql`
  query globalNavigationPatientsSearch(
    $data: GlobalNavigationSearchPatientsInput!
  ) {
    globalNavigationPatientsSearch(data: $data) {
      data {
        id
        firstName
        lastName
        active
        email
        assessmentId
        status
      }
      count
    }
  }
`;
