import { GridCellParams, GridColDef } from '@material-ui/data-grid';

export const ACTIVE_STATUS = [
  { id: '1', name: 'All Accounts' },
  { id: '2', name: 'Active Accounts' },
  { id: '3', name: 'Inactive Accounts' }
];

export const ENTITY_TYPE = [
  { id: 1, name: 'All' },
  { id: 2, name: 'Partners in Care' }
];

export const STATES = [
  { id: 1, name: 'AL' },
  { id: 2, name: 'AK' },
  { id: 3, name: 'AS' },
  { id: 4, name: 'AZ' },
  { id: 5, name: 'AR' },
  { id: 6, name: 'CA' },
  { id: 7, name: 'CO' },
  { id: 8, name: 'CT' },
  { id: 9, name: 'DE' },
  { id: 10, name: 'DC' },
  { id: 11, name: 'FM' },
  { id: 12, name: 'FL' },
  { id: 13, name: 'GA' },
  { id: 14, name: 'GU' },
  { id: 15, name: 'HI' },
  { id: 16, name: 'ID' },
  { id: 17, name: 'IL' },
  { id: 18, name: 'IN' },
  { id: 19, name: 'IA' },
  { id: 20, name: 'KS' },
  { id: 21, name: 'KY' },
  { id: 22, name: 'LA' },
  { id: 23, name: 'ME' },
  { id: 24, name: 'MH' },
  { id: 25, name: 'MD' },
  { id: 26, name: 'MA' },
  { id: 27, name: 'MI' },
  { id: 28, name: 'MN' },
  { id: 29, name: 'MS' },
  { id: 30, name: 'MO' },
  { id: 31, name: 'MT' },
  { id: 32, name: 'NE' },
  { id: 33, name: 'NV' },
  { id: 34, name: 'NH' },
  { id: 35, name: 'NJ' },
  { id: 36, name: 'NM' },
  { id: 37, name: 'NY' },
  { id: 38, name: 'NC' },
  { id: 39, name: 'ND' },
  { id: 40, name: 'MP' },
  { id: 41, name: 'OH' },
  { id: 42, name: 'OK' },
  { id: 43, name: 'OR' },
  { id: 44, name: 'PW' },
  { id: 45, name: 'PA' },
  { id: 46, name: 'PR' },
  { id: 47, name: 'RI' },
  { id: 48, name: 'SC' },
  { id: 49, name: 'SD' },
  { id: 50, name: 'TN' },
  { id: 51, name: 'TX' },
  { id: 52, name: 'UT' },
  { id: 53, name: 'VT' },
  { id: 54, name: 'VI' },
  { id: 55, name: 'VA' },
  { id: 56, name: 'WA' },
  { id: 57, name: 'WV' },
  { id: 58, name: 'WI' },
  { id: 59, name: 'WY' }
];

export const PARENT_SITES = [
  { id: 1, name: 'TBD 1' },
  { id: 2, name: 'TBD 2' },
  { id: 3, name: 'TBD 3' },
  { id: 4, name: 'TBD 4' },
  { id: 5, name: 'TBD 5' }
];

export const SITES_COLUMNS_ADVANCED = (
  getStatusCell: (params: GridCellParams) => JSX.Element
): GridColDef[] => [
  { field: 'id', hide: true, flex: 1 },
  { field: 'title', headerName: 'Site Name', flex: 1.1 },
  {
    field: 'address',
    headerName: 'Address',
    cellClassName: 'address',
    flex: 1
  },
  { field: 'city', headerName: 'City', flex: 1.1 },
  { field: 'state', headerName: 'State', flex: 1.1 },
  { field: 'zip', headerName: 'Zip Code', flex: 1.2 },
  { field: 'primaryPhone', headerName: 'Phone', flex: 1.3 },
  { field: 'fax', headerName: 'Fax', flex: 1.3 },
  {
    field: 'parentSite.title',
    headerName: 'Parent Site',
    valueGetter: params => params.row?.parentSite?.title,
    flex: 1.2
  },
  {
    field: 'active',
    headerName: 'Status',
    flex: 1,
    renderCell: (params: GridCellParams) => getStatusCell(params)
  }
];

export const SITES_COLUMNS = (
  getStatusCell: (params: GridCellParams) => JSX.Element
): GridColDef[] => [
  { field: 'id', hide: true, flex: 1 },
  { field: 'title', headerName: 'Site Name', flex: 1 },
  {
    field: 'address',
    headerName: 'Address',
    cellClassName: 'address',
    flex: 2
  },
  { field: 'city', headerName: 'City', flex: 1 },
  {
    field: 'active',
    headerName: 'Status',
    flex: 0.5,
    renderCell: params => getStatusCell(params)
  }
];
