import * as yup from 'yup';

export const assessmentsValidationSchema = yup.object().shape({
  fall: yup.string().required('Field is required'),
  dizzy: yup.string().required('Field is required'),
  confused: yup.string().required('Field is required'),
  hospital: yup.string().required('Field is required'),
  fallTimes: yup.string().when('fall', val => {
    if (val === 'yes')
      return yup
        .number()
        .transform((value, orgVal) => (orgVal === '' ? undefined : value))
        .typeError('Only digits accepted')
        .required('Field is required');
    return yup.string().notRequired();
  }),
  fallWay: yup.string().when('fall', val => {
    if (val === 'yes') return yup.string().required('Field is required');
    return yup.string().notRequired();
  }),
  fallInjuries: yup.string().when('fall', val => {
    if (val === 'yes') return yup.string().required('Field is required');
    return yup.string().notRequired();
  }),
  dizzinessDetails: yup.string().when('dizzy', val => {
    if (val === 'yes') return yup.string().required('Field is required');
    return yup.string().notRequired();
  }),
  nursingWay: yup.string().when('hospital', val => {
    if (val === 'yes') return yup.string().required('Field is required');
    return yup.string().notRequired();
  }),
  nursingDuration: yup.string().when('hospital', val => {
    if (val === 'yes') return yup.string().required('Field is required');
    return yup.string().notRequired();
  }),
  pain: yup.string().required('Field is required'),
  drinkDays: yup
    .number()
    .transform((value, orgVal) => (orgVal === '' ? undefined : value))
    .typeError('Only digits accepted')
    .required('Field is required'),
  drinksPerDay: yup.number().when('drinkDays', val => {
    if (val !== 0)
      return yup
        .number()
        .transform((value, orgVal) => (orgVal === '' ? undefined : value))
        .typeError('Only digits accepted')
        .required('Field is required');
    return yup
      .number()
      .transform((value, orgVal) => (orgVal === '' ? undefined : value))
      .typeError('Only digits accepted')
      .notRequired();
  }),
  bpLyingDownMin: yup
    .number()
    .transform((value, orgVal) => (orgVal === '' ? undefined : value))
    .typeError('Only digits accepted'),
  bpLyingDownMax: yup
    .number()
    .transform((value, orgVal) => (orgVal === '' ? undefined : value))
    .typeError('Only digits accepted'),
  bpLyingDownPulse: yup
    .number()
    .transform((value, orgVal) => (orgVal === '' ? undefined : value))
    .typeError('Only digits accepted'),
  bpLyingDownDateTime: yup.date().typeError('Only dates accepted').nullable(),
  bpSittingMin: yup
    .number()
    .transform((value, orgVal) => (orgVal === '' ? undefined : value))
    .typeError('Only digits accepted'),
  bpSittingMax: yup
    .number()
    .transform((value, orgVal) => (orgVal === '' ? undefined : value))
    .typeError('Only digits accepted'),
  bpSittingPulse: yup
    .number()
    .transform((value, orgVal) => (orgVal === '' ? undefined : value))
    .typeError('Only digits accepted'),
  bpSittingDateTime: yup.date().typeError('Only dates accepted').nullable(),
  bpStandingMin: yup
    .number()
    .transform((value, orgVal) => (orgVal === '' ? undefined : value))
    .typeError('Only digits accepted'),
  bpStandingMax: yup
    .number()
    .transform((value, orgVal) => (orgVal === '' ? undefined : value))
    .typeError('Only digits accepted'),
  bpStandingPulse: yup
    .number()
    .transform((value, orgVal) => (orgVal === '' ? undefined : value))
    .typeError('Only digits accepted'),
  bpStandingDateTime: yup.date().typeError('Only dates accepted').nullable(),
  drugs: yup.array().notRequired(),
  otherMedications: yup.string().notRequired(),
  conditions: yup.array().notRequired(),
  otherConditions: yup.string().notRequired(),
  allergies: yup.array().notRequired(),
  otherAllergies: yup.string().notRequired(),
  attachment: yup.array().notRequired()
});
